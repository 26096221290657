import {useState, useContext, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import HeaderBackground from '../components/headerbackground.js'
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import HeaderOverlay from '../components/headeroverlay.js';
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";


export default function Requests() {

    const [agreement, setAgreement] = useState();
    const [requests, setRequests] = useState();
    const [loading, isLoading] = useState(true)
    const [requestModal, setRequestModal] = useState(false);
    const [requestTypeID, setRequestTypeID] = useState();
    const [reload, setReload] = useState(false);
    const [requesttypes, setRequestTypes] = useState([]);
    const {theme, updateTheme, themeCSS} = useContext(GlobalContext)

    async function GetAPIData() {
        const AgreementDetail = await getAPI('agreements/'+localStorage.getItem('agreement'));
        const AccountsDetail = await getAPI('accounts');
        let RequestTypeResponse = await getAPI('tenant/requesttype');
        setRequestTypes(RequestTypeResponse.data);
        
        var selectedAccount = AccountsDetail.data.filter(account => {
            return account.agreementreference === localStorage.getItem('agreement');
        })

        setAgreement(AgreementDetail.data);

        const RequestDetail = await getAPI('tenant/requests');
        setRequests(RequestDetail.data);
        isLoading(false);
    }

    async function createRequest() {
        isLoading(true)
        const body = {'requesttypeid': requestTypeID}
        const RequestResponse = await postAPI('tenant/requests', body);

        if(RequestResponse.response.ok){
            setRequestModal(false)
            toast.success('Request Created Successfully.');
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }
        isLoading(false)
        setReload(!reload)
    }

    function handleCaseTypeChange(event) {
        setRequestTypeID(event.target.value);
    }

    useEffect(() => {
        GetAPIData();
      }, [reload]);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
        
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
                <Nav />
                <HeaderBackground />
                <HeaderOverlay agreement={agreement} type={"Requests"}/>
                <div className={"min-h-screen " + (themeCSS.divCSS)}>  
                    <div className="mt-12 flex justify-center font-sans overflow-hidden">
                        <div className={"border rounded my-6 lg:w-8/12 md:w-11/12 xs:w-full " + (themeCSS.divCSS)}>
                            <table className="table-auto overflow-auto w-full">
                                <thead>
                                    <tr className={"uppercase text-sm leading-normal" + (themeCSS.tableHeaderCSS)}>
                                        <th className="py-3 px-6 text-left">Description</th>
                                        <th className="py-3 px-6 text-left">Type</th>
                                        <th className="py-3 px-6 text-left hidden lg:block">Date</th>
                                        <th className="py-3 px-6 text-left">Status</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 text-sm font-light">
                                {requests && (
                                    requests.map(function(tenentrequest, index) {
                                    return (
                                        <tr className={"border-b border-gray-200 hover:bg-gray-100" + (themeCSS.divCSS)}>
                                            <td className="py-3 px-6 text-left whitespace-wrap w-6/12">
                                                <div className="flex items-center">
                                                    <span className="font-medium">{tenentrequest.description}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-left whitespace-wrap">
                                                <div className="flex items-center">
                                                    <span>{tenentrequest.type}</span>
                                                </div>
                                            </td>
                                            <td className="py-3 px-6 text-left whitespace-wrap hidden lg:block">
                                                <div className="flex items-center">
                                                    <span>{tenentrequest.date}</span>
                                                </div>
                                            </td>   
                                            <td className="py-3 px-6 text-left whitespace-wrap">
                                                <span className={"font-medium rounded-full p-2 text-white" + (themeCSS.divCSS)}>{tenentrequest.status}</span>
                                            </td>   
                                        </tr>
                                        )}
                                    ))}
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className='fixed bottom-0 w-full mb-10'>
                    <button onClick={() => {setRequestModal(true)}}className="btn btn-natural sticky float-right bottom-5 right-5 p-2 rounded-lg text-white text-md grid grid-cols-12 w-40 capitalize">
                        <svg className="col-span-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16 22H4C2.89543 22 2 21.1046 2 20V8H4V20H16V22ZM20 18H8C6.89543 18 6 17.1046 6 16V4C6 2.89543 6.89543 2 8 2H20C21.1046 2 22 2.89543 22 4V16C22 17.1046 21.1046 18 20 18ZM8 4V16H20V4H8ZM15 14H13V11H10V9H13V6H15V9H18V11H15V14Z" fill="#fff"></path>
                        </svg>
                        <p className="col-span-10">Create Request</p>
                    </button>
                </div>
            </div>
            <Modal
            isOpen={requestModal}
            //onAfterOpen={afterOpenModal}
            onRequestClose={() => {setRequestModal(false)}}
            className="my-20 mx-2 pb-12 md:mx-40 overflow-y-auto overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center h-modal h-full"
            contentLabel="Profile">
                <div>
                    <div className={"rounded-2xl border overflow-auto p-10 " + (themeCSS.inputCSS)}> 
                        <div className="flex flex-col items-center space-y-2">
                            <h1 className="font-bold text-2xl w-4/6 text-center">Create a new request</h1>
                            <div className="grid grid-cols-1 w-full md:space-x-4 space-y-2 md:space-y-0">
                                <div>
                                    <select required className={"border-2 border-gray-400 rounded-lg w-full h-12 px-4 " + (themeCSS.inputCSS)} onChange={handleCaseTypeChange} name="type" id="type">
                                        <option value="">Please select a request type</option>
                                        {requesttypes && (
                                            requesttypes.map(function(requesttype, index){
                                                return (
                                                    <option value={requesttype.id}>{requesttype.title}</option>
                                                )
                                            })
                                        )}
                                    </select>
                                </div>
                            </div>
                            
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 md:space-x-4 space-y-2 md:space-y-0">
                            <div className="flex flex-col items-center space-y-4">
                                <button className={"btn btn-natural border-0 rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.lightButtonCSS)} onClick={() => {setRequestModal(false)}}>Cancel</button>
                            </div>
                            <div className="flex flex-col items-center space-y-4">
                                <button className={"btn btn-natural rounded-md font-semibold px-4 py-3 w-full mt-4 " + (themeCSS.buttonCSS)} onClick={() => {createRequest()}}>Create Request</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
        </Modal>
        </>
        
    );
    
}

