import {useState, useEffect, useContext} from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Loader from '../components/loader.js';

export default function NewDefinitionForm({setVisible, refresh, refreshValue}) {

    const [reload, setReload] = useState(false);
    const [categories, setCategories] = useState();
    const [definitionModal, setDefinitionModal] = useState(false);
    const [sorTables, setSORTables] = useState();
    const [sors, setSors] = useState();
    const [repairTypes, setRepairTypes] = useState();
    const [priorities, setPriorities] = useState();
    const [contracts, setContracts] = useState();
    const [sortableloading, setSorTableLoading] = useState();
    const [finalfields, setFinalFields] = useState(false);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    const [repairDefinitionDetail, setRepairDefinitionDetail] = useState({
        sorTableName: "",
        contractid: "",
        contractorname: "",
        description: "",
        categoryid: "",
        sorcode: "",
        sorid: "",
        sordescription: "",
        repairtypeid: "",
        repairtypedescription: "",
        priorityid: "",
        priorityname: ""
        }
    );

    function handleDescriptionChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'description': event.target.value});
    }

    function handleSafetyNoticeChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'safetynotice': event.target.value});
    }

    function handleCategoryChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'categoryid': event.value});
    }

    function handleSORTableChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'sorTableName': event.value});
        setSorTableLoading(0);
        getSORs(event.value);
    }

    function handleSORChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'sordescription': event.label, 'sorid': event.sorid, 'sorcode': event.value});
        setFinalFields(true);
    }

    function handleRepairTypeChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'repairtypeid': event.value, 'repairtypedescription': event.label});
    }

    function handlePriotityChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'priorityid': event.value, 'priorityname': event.label});
    }

    function handleContractChange(event){
        setRepairDefinitionDetail({...repairDefinitionDetail, 'contractid': event.value, 'contractorname': event.label});
    }

    async function getDefinitionAPIOptions() {
        if (!categories && !sorTables && !repairTypes && !priorities && !contracts) {
            const [CategoryResponse, RepairSORTables, RepairTypeResponse, PriorityResponse, ContractResponse] = await Promise.all([
                getAPI('category?active=1'),
                getAPI('sortables'),
                getAPI('repairtypes'),
                getAPI('repairpriorities'),
                getAPI('contracts')
            ]);

            let categoryData = CategoryResponse.data;
            let sortableData = RepairSORTables.data;
            let repairTypeData = RepairTypeResponse.data;
            let priorityData = PriorityResponse.data;
            let contractData = ContractResponse.data;

            let categories = categoryData.map(category => ({ value: category.id, label: category.name, id: category.id}));
            let sorTables = sortableData.map(table => ({ value: table.description, label: table.description, id: table.id }));
            let repairTypes = repairTypeData.map(type => ({ value: type.id, label: type.description }));
            let priorities = priorityData.map(priority => ({ value: priority.priorityid, label: priority.description }));
            let contracts = contractData.map(contract => ({ value: contract.id, label: contract.name }));

            setCategories(categories);
            setSORTables(sorTables);
            setRepairTypes(repairTypes);
            setPriorities(priorities);
            setContracts(contracts);
        }

        setVisible(true);
        setIsLoaded(true);
    }

    async function getSORs(sortablename){
        const body = {tablename: sortablename}
        const sorsresponse = await postAPI('sors', body);
        let sors = [];
        sorsresponse.data.forEach(element => {
            sors.push({'value': element.sorcode, 'label': element.sorcode+": "+element.description, 'sorid': element.sorid})
        });
        setSors(sors);
        setSorTableLoading(1);
    }

    async function createDefinition() {
        setIsLoaded(false);
        const body = {
                    description: repairDefinitionDetail.description, 
                    category: repairDefinitionDetail.categoryid,
                    sorcode: repairDefinitionDetail.sorcode,
                    sorid: repairDefinitionDetail.sorid,
                    priority: repairDefinitionDetail.priorityid,
                    contractid: repairDefinitionDetail.contractid,
                    contractorname: repairDefinitionDetail.contractorname,
                    repairtype: repairDefinitionDetail.repairtypeid,
                    safetynotice: repairDefinitionDetail.safetynotice
                }
        const DefinitionResponse = await postAPI('repairdefinitions', body);

        if(DefinitionResponse.response.ok){
          
            setVisible(false)  
            refresh(refreshValue+1)
            toast.success('Definition Created Successfully.');
        }else{
            toast.error('Error occurred. Try again later.');
        }
    }

    useEffect(() => {
        getDefinitionAPIOptions();
    }, []);

    return (
        loading ? (
            <Loader style='form' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
            <form>
                <div className="space-y-12">
                    <div>
                        <h2 className="text-base font-semibold leading-7 text-gray-900">Create new repair definition</h2>
                        <p className="mt-1 text-sm leading-6 text-gray-600">Repair definitions are choices provided to the customer when requesting a repair.</p>
                    </div>

                    <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                        <div className="sm:col-span-1">
                            <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                                Name
                            </label>
                            <div className="mt-2">
                                <input
                                required
                                type="text"
                                onChange={handleDescriptionChange}
                                name="description"
                                id="description"
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>

                        <div className="sm:col-span-1">
                            <label htmlFor="safetynotice" className="block text-sm font-medium leading-6 text-gray-900">
                                Safety Notice (Optional)
                            </label>
                            <div className="mt-2">
                                <textarea
                                required
                                type="textarea"
                                onChange={handleSafetyNoticeChange}
                                name="safetynotice"
                                id="safetynotice"
                                className="p-3 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                />
                            </div>
                        </div>
                        
                        <div>
                            <label htmlFor="category" className="block text-sm font-medium leading-6 text-gray-900">
                                Category
                            </label>
                            <Select
                                onChange={handleCategoryChange}
                                options={categories}
                                placeholder="Select category"
                                className="dark:text-black pt-2"
                                name="category"
                            />
                        </div>

                        <div>
                            <label htmlFor="sortable" className="block text-sm font-medium leading-6 text-gray-900">
                                SOR Table
                            </label>
                            <Select
                                defaultValue={repairDefinitionDetail.sorTableName}
                                onChange={handleSORTableChange}
                                options={sorTables}
                                placeholder="Select SOR Table"
                                className="dark:text-black pt-2"
                                name="sortable"
                            />
                        </div>

                        <div>
                            {sortableloading == 0 && (
                                <svg aria-hidden="true" className="mt-2 ml-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                            )}
                            {sortableloading == 1 && (
                                <>
                                    <label htmlFor="sor" className="block text-sm font-medium leading-6 text-gray-900">
                                        SOR Code
                                    </label>
                                    <Select
                                        onChange={handleSORChange}
                                        options={sors}
                                        placeholder="Select SOR"
                                        className="dark:text-black pt-2"
                                        name='name'
                                    />
                                </>
                            )}
                        </div>

                        {finalfields && (
                            <>
                                <div>
                                    <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repair Type
                                    </label>
                                    <Select
                                        defaultValue={repairDefinitionDetail.repairtypedescription}
                                        onChange={handleRepairTypeChange}
                                        options={repairTypes}
                                        placeholder="Select Repair Type"
                                        className="dark:text-black pt-2"
                                        name='type'
                                    />
                                </div>

                                <div>
                                    <label htmlFor="priority" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repair Priority
                                    </label>
                                    <Select
                                        defaultValue={repairDefinitionDetail.priorityname}
                                        onChange={handlePriotityChange}
                                        options={priorities}
                                        placeholder="Select Priority"
                                        className="dark:text-black pt-2"
                                        name='priority'
                                    />
                                </div>

                                <div>
                                    <label htmlFor="contractor" className="block text-sm font-medium leading-6 text-gray-900">
                                        Contractor
                                    </label>
                                    <Select
                                        defaultValue={repairDefinitionDetail.contractorname}
                                        onChange={handleContractChange}
                                        options={contracts}
                                        placeholder="Select Contract"
                                        className="dark:text-black pt-2"
                                        name='contractor'
                                    />
                                </div>
                            </>
                        )}       
                    </div>
                </div>

                <div className="mt-6 flex items-center justify-start gap-x-6">
                    
                    <button
                    type="button"
                    onClick={()=>{createDefinition()}}
                    className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                    >
                    Create
                    </button>
                    <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
                    Cancel
                    </button>
                </div>
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </form>
        )
    )
}
