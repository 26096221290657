import {react, useState, useEffect} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../components/loader.js';

export default function Settings() {
    const [settings, setSettings] = useState({});
    const [updatedSettings, setUpdatedSettings] = useState();

    const [savebutton, setSaveButton] = useState(false);
    const [transactionsEnabled, setTransactionsEnabled] = useState();  
    const [paymentsEnabled, setPaymentsEnabled] = useState();  
    const [directDebitEnabled, setDirectDebitEnabled] = useState();  
    const [repairsEnabled, setRepairsEnabled] = useState();   
    const [requestsEnabled, setRequestsEnabled] = useState();  
    const [logo, setLogo] = useState();
    const [logoObject, setLogoObject] = useState("");
    const [logoDark, setLogoDark] = useState();
    const [logoDarkObject, setLogoDarkObject] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);


    async function getAllSettings() {
        const SettingsResponse = await getAPI('settings');
        setSettings(SettingsResponse.data);

        setTransactionsEnabled(!!SettingsResponse.data.transactions_enabled);
        setPaymentsEnabled(!!SettingsResponse.data.payments_enabled);
        setDirectDebitEnabled(!!SettingsResponse.data.directdebit_enabled);
        setRepairsEnabled(!!SettingsResponse.data.repairs_enabled);
        setRequestsEnabled(!!SettingsResponse.data.requests_enabled)
        setLogo(SettingsResponse.data.logo_url)
        setLogoDark(SettingsResponse.data.dark_mode_logo_url)
        setIsLoaded(true);
    }

    function handleChange(event) {
        setSaveButton(true);
        let statename = event.target.name;
        let value = event.target.value;
        setUpdatedSettings({...updatedSettings, [statename]: value});
        setSettings({...settings, [statename]: value})
    }

    function HandleLogoChange (event) {
        if (event.target.files && event.target.files) {
            setLogo(URL.createObjectURL(event.target.files[0]));
            setLogoObject(event.target.files[0]);
        }
        setSaveButton(true);
    }

    function HandleLogoDarkChange (event) {
        if (event.target.files && event.target.files) {
            setLogoDark(URL.createObjectURL(event.target.files[0]));
            setLogoDarkObject(event.target.files[0]);
        }
        setSaveButton(true);
    }

    function checkChanged(event) {
        if(event.target.id === 'transactions'){
            setTransactionsEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'transactions_enabled': event.target.checked});
        }
        if(event.target.id === 'payments'){
            setPaymentsEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'payments_enabled': event.target.checked});
        }
        if(event.target.id === 'directdebit'){
            setDirectDebitEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'directdebit_enabled': event.target.checked});
        }
        if(event.target.id === 'repairs'){
            setRepairsEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'repairs_enabled': event.target.checked});
        }
        if(event.target.id === 'requests'){
            setRequestsEnabled(event.target.checked);
            setUpdatedSettings({...updatedSettings, 'requests_enabled': event.target.checked});
        }
        setSaveButton(true);
        
    }

    async function UpdateSettngs() {  


        //TODO: Settings API Needs re-writing
        if(logoObject){
            const URL = process.env.REACT_APP_API_URL+'/api/'+process.env.REACT_APP_API_VERSION+'/settings';
            const APITOKEN = localStorage.getItem("apiToken");
            
            const formData = new FormData();
            formData.append("logo", logoObject);

            const response = await 
                fetch( URL, { 
                    method: 'POST', 
                    headers: {
                        'Accept': 'multipart/form-data',
                        
                        Authorization: 'Bearer '+ APITOKEN
                        },
                        body: formData
                });
            if(response.ok){
                toast.success('Logo updated successfully.');
                setSaveButton(false);
            }else{
                toast.error('Sorry an error occurred. Try again later.');
            }
        }
        if(logoDarkObject){
            const URL = process.env.REACT_APP_API_URL+'/api/'+process.env.REACT_APP_API_VERSION+'/settings';
            const APITOKEN = localStorage.getItem("apiToken");
            
            const formData = new FormData();
            formData.append("logo_dark_mode", logoDarkObject);

            const response = await 
                fetch( URL, { 
                    method: 'POST', 
                    headers: {
                        'Accept': 'multipart/form-data',
                        
                        Authorization: 'Bearer '+ APITOKEN
                        },
                        body: formData
                });
            if(response.ok){
                toast.success('Dark mode logo updated successfully.');
                setSaveButton(false);
            }else{
                toast.error('Sorry an error occurred. Try again later.');
            }
        }
        if (updatedSettings) {
            const UpdateSettingsResponse = await postAPI('settings', updatedSettings);
            if(UpdateSettingsResponse.response.ok){
                toast.success('Settings saved successfully.');
                setSaveButton(false);
            }else{
                toast.error('Sorry an error occurred. Try again later.');
            }
        }  
    }

    useEffect(() => {
        getAllSettings();
    }, []);
    
    return (
    <AdminSidebar>
        {loading ? (
            <Loader style='form' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
        settings && (
            <>
                <div className="sm:flex-auto mb-8 border-b border-gray-900/10 pb-8">
                    <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Settings</h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Changes to these settings will be reflected on both web and mobile
                    </p>
                </div>
                
                <form className="dark:bg-white h-full">
                    <div className="space-y-6 mb-10">
                        <div className="">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Branding</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">These settings enable you to brand your portal to your requirements</p>

                            
                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-4">
                                    <label htmlFor="company_name" className="block text-sm font-medium leading-6 text-gray-900">
                                        Company Name
                                    </label>
                                    <div className="mt-2">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-700 sm:max-w-md">
                                        <input
                                            type="text"
                                            color="purple"
                                            outline={true}
                                            placeholder={settings.company_name}
                                            value={settings.company_name}
                                            name="company_name"
                                            onChange={handleChange}
                                            className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-2 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="primary_hex_colour" className="block text-sm font-medium leading-6 text-gray-900">
                                        Primary Colour (HEX Code)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        id="primary_hex_colour"
                                        className="block w-full h-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        type="color"
                                        placeholder="Primary Colour (HEX Code)"
                                        value={settings.primary_hex_colour}
                                        name="primary_hex_colour"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="secondary_hex_colour" className="block text-sm font-medium leading-6 text-gray-900">
                                        Secondary Colour (HEX Code)
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        id="secondary_hex_colour"
                                        className="block w-full h-10 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        type="color"
                                        placeholder="Secondary Colour (HEX Code)"
                                        value={settings.secondary_hex_colour}
                                        name="secondary_hex_colour"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-8">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label htmlFor="logo" className="block text-sm font-medium leading-6 text-gray-900">
                                        Logo
                                    </label>
                                    <div className="mt-2 flex items-center gap-x-3">
                                        {logo && (
                                            <div className="border-2 p-4 w-1/2 rounded-lg">
                                            <img 
                                                src={logo}
                                                alt="logo"
                                                width="200"
                                                className="mx-auto"
                                            />
                                            </div>
                                        )}
                                        {!logo && (
                                            <div className={'w-1/2'}>
                                            No Logo Uploaded
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            accept=".png, .svg"
                                            onChange={HandleLogoChange}
                                            name="logo"
                                            placeholder="Upload Logo"
                                            className="rounded-md w-1/2 bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        />
                                    </div>

                                    <label htmlFor="darklogo" className=" mt-2 block text-sm font-medium leading-6 text-gray-900">
                                        Dark Mode Logo
                                    </label>
                                    <div className="mt-2 flex items-center gap-x-3">
                                        {logoDark && (
                                            <div className="border-2 p-4 w-1/2 rounded-lg">
                                            <img 
                                                src={logoDark}
                                                alt="logoDark"
                                                width="200"
                                                className="mx-auto"
                                            />
                                            </div>
                                        )}
                                        {!logoDark && (
                                            <div className={'w-1/2'}>
                                            No Logo Uploaded
                                            </div>
                                        )}
                                        <input
                                            type="file"
                                            accept=".png, .svg"
                                            onChange={HandleLogoDarkChange}
                                            placeholder="Upload Logo"
                                            name='darklogo'
                                            className="rounded-md bg-white w-1/2 px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b border-gray-900/10 pb-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Website links</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">These links point customers to help and contact information.</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="company_website_url" className="block text-sm font-medium leading-6 text-gray-900">
                                    Company Website URL
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        placeholder="https://yourwebsite.com"
                                        value={settings.company_website_url}
                                        name="company_website_url"
                                        onChange={handleChange}
                                        type="text"
                                        id="company_website_url"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="user_help_link" className="block text-sm font-medium leading-6 text-gray-900">
                                        Help & Support URL
                                    </label>
                                    <div className="mt-2">
                                        <input
                                        placeholder="https://yourwebsite.com/help-page"
                                        value={settings.user_help_link}
                                        name="user_help_link"
                                        onChange={handleChange}
                                        type="text"
                                        id="user_help_link"
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-14 space-y-10 border-b  border-gray-900/10 pb-8">
                            <fieldset>
                                <h2 className="text-base font-semibold leading-7 text-gray-900">Feature availability</h2>
                                <p className="mt-1 text-sm leading-6 text-gray-600">Using the toggles below you can enable and disable specific features</p>
                                <div className="mt-6 space-y-6">

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id="transactions"
                                            name="transactions"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            onChange={checkChanged}
                                            checked={transactionsEnabled}
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="transactions" className="font-medium text-gray-900">
                                            Transactions
                                            </label>
                                            <p className="text-gray-500">Allow customers to view their account transactions</p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id="payments"
                                            onChange={checkChanged}
                                            checked={paymentsEnabled}
                                            name="payments"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="payments" className="font-medium text-gray-900">
                                            Payments
                                            </label>
                                            <p className="text-gray-500">Allow customers to make payments (Configuration required)</p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id="directdebit"
                                            onChange={checkChanged}
                                            checked={directDebitEnabled}
                                            name="directdebit"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="directdebit" className="font-medium text-gray-900">
                                            Direct Debit
                                            </label>
                                            <p className="text-gray-500">Allow customers to set up direct debits (Configuration required)</p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id="repairs"
                                            onChange={checkChanged}
                                            checked={repairsEnabled}
                                            name="repairs"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="repairs" className="font-medium text-gray-900">
                                            Repairs
                                            </label>
                                            <p className="text-gray-500">Allow customers to view and raise repairs.</p>
                                        </div>
                                    </div>

                                    <div className="relative flex gap-x-3">
                                        <div className="flex h-6 items-center">
                                            <input
                                            id="requests"
                                            onChange={checkChanged}
                                            checked={requestsEnabled}
                                            name="requests"
                                            type="checkbox"
                                            className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                            />
                                        </div>
                                        <div className="text-sm leading-6">
                                            <label htmlFor="requests" className="font-medium text-gray-900">
                                            Requests
                                            </label>
                                            <p className="text-gray-500">Allow customers to make requests</p>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </div>

                        <div className="border-b  border-gray-900/10 pb-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Account Transactions Homepage settings</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Control the wording of the account transactions card on the homepage</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="account_transaction_header" className="block text-sm font-medium leading-6 text-gray-900">
                                        Account Transactions Heading
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="account_transaction_header"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Account Transactions"
                                            value={settings.account_transaction_header}
                                            name="account_transaction_header"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="account_transaction_button" className="block text-sm font-medium leading-6 text-gray-900">
                                        Account Transactions Button Text
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="account_transaction_button"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="View Transactions"
                                            value={settings.account_transaction_button}
                                            name="account_transaction_button"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="account_transaction_body" className="block text-sm font-medium leading-6 text-gray-900">
                                        Account Transactions Body
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="account_transaction_body"
                                        rows={3}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        placeholder="You can view you previous payments and charges along with downloading your rent statements here."
                                        value={settings.account_transaction_body}
                                        name="account_transaction_body"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b  border-gray-900/10 pb-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Payment Homepage settings</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Control the wording of the payment card on the homepage</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="payment_header" className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Heading
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="payment_header"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Pay My Rent"
                                            value={settings.payment_header}
                                            name="payment_header"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="payment_button" className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Button Text
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="payment_button"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Make a Payment"
                                            value={settings.payment_button}
                                            name="payment_button"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="payment_body" className="block text-sm font-medium leading-6 text-gray-900">
                                        Payment Body
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="payment_body"
                                        rows={3}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        placeholder="You can pay your rent with ease using our online payment system. Click the button below to make a payment."
                                        value={settings.payment_body}
                                        name="payment_body"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="border-b  border-gray-900/10 pb-8">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Repairs Homepage settings</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Control the wording of the repairs card on the homepage</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="repairs_header" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Heading
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="repairs_header"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Your Repairs"
                                            value={settings.repairs_header}
                                            name="repairs_header"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="repairs_button" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Button Text
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="repairs_button"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Manage Repairs"
                                            value={settings.repairs_button}
                                            name="repairs_button"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="repairs_body" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Body
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="repairs_body"
                                        rows={3}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        placeholder="It is really easy to manage exsisting repairs and raise new ones. Access your repairs via the button below."
                                        value={settings.repairs_body}
                                        name="repairs_body"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="repair_emergency_number" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Requests Emergency Number
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="repair_emergency_number"
                                            className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            color="purple"
                                            outline={true}
                                            placeholder="Emergency Repair Number"
                                            value={settings.repair_emergency_number}
                                            name="repair_emergency_number"
                                            onChange={handleChange}
                                            maxLength='12'
                                        />
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="">
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Requests Homepage settings</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">Control the wording of the requests card on the homepage</p>

                            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6">
                                <div className="sm:col-span-3">
                                    <label htmlFor="request_header" className="block text-sm font-medium leading-6 text-gray-900">
                                        Requests Heading
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="request_header"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Requests"
                                            value={settings.request_header}
                                            name="request_header"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="request_button" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Button Text
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="request_button"
                                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            type="text"
                                            placeholder="Manage Requests"
                                            value={settings.request_button}
                                            name="request_button"
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="request_body" className="block text-sm font-medium leading-6 text-gray-900">
                                        Repairs Body
                                    </label>
                                    <div className="mt-2">
                                        <textarea
                                        id="request_body"
                                        rows={3}
                                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        placeholder="Report Anti-Social Behaviour, provide feedback or raise a complaint."
                                        value={settings.request_body}
                                        name="request_body"
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {savebutton && (
                <div className="fixed bottom-5 right-5">
                    <button onClick={() => {UpdateSettngs()}} className="mb-4 rounded-md bg-blue-700 px-3 py-2 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">Save Changes</button>
                </div>
                )}
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </>
        )
    )}      
    </AdminSidebar>


    );
}

