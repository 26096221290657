import {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import RepairDefinitionsTable from '../components/RepairDefinitionsTable.js';
import 'react-toastify/dist/ReactToastify.css';
import NewDefinitionForm from '../forms/newDefinition.js'
import SlideOverRight from '../components/slideOverRight.js';


export default function RepairDefinitions() {
    
    const [newDefinitionFormState, setNewDefinitionFormState] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(0);

    return (
        <>
            <AdminSidebar>
                <RepairDefinitionsTable setForm={setNewDefinitionFormState} refresh={shouldRefresh}/>
                <SlideOverRight visible={newDefinitionFormState} setVisible={setNewDefinitionFormState}>
                    <NewDefinitionForm setVisible={setNewDefinitionFormState} refresh={setShouldRefresh} refreshValue={shouldRefresh} />
                </SlideOverRight>
            </AdminSidebar>
        </>
    );
    
}

