import AdminSidebar from '../components/admin/AdminSidebar.js';
import RepairGroupDefinition from '../components/RepairGroupsTable.js';


export default function RepairGroups() {
    return (
        <>
            <AdminSidebar>
                <RepairGroupDefinition />
            </AdminSidebar>
        </>
    );
}

