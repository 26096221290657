import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import postAPI from '../functions/API/postAPI.js'
import { toast } from 'react-toastify';

export default function DeleteModal({open, setOpen, IDToDelete, refresh, deleteAction, title, message}) {

  function deleteFunction(){
    if(deleteAction == 'deleteAdminUser'){
      deleteAdminUser();
    }
    if(deleteAction == 'deleteContactMethod'){
      deleteContactMethod();
    }
    if(deleteAction == 'deleteRepairGroup'){
      deleteRuleGroup();
    }
    if(deleteAction == 'deleteClientCode'){
      deleteClientCode();
    }
    if(deleteAction == 'deleteCustomer'){
      deleteCustomer();
    }
  }

  async function deleteAdminUser(){
    const body = {userid: IDToDelete}
    let DeleteResponse = await postAPI('admin/users/delete', body);
    let response = DeleteResponse.response;

    if(response.ok){
      refresh();
      toast.success('User deleted successfully.')
    }else{
      toast.error('Cannot delete admin.')
    }
    setOpen(false);
  }

  async function deleteContactMethod(){
    const body = {id: IDToDelete}
    let DeleteResponse = await postAPI('deletecontactmethodmapping', body);
    let response = DeleteResponse.response;

    if(response.ok){
      refresh();
      toast.success('Method deleted successfully.')
    }else{
      toast.error('Cannot delete method.')
    }
    setOpen(false);
  }

  async function deleteRuleGroup(){
    const body = {id: IDToDelete}
    let DeleteResponse = await postAPI('repair/groups/delete', body);
    let response = DeleteResponse.response;

    if(response.ok){
        refresh();
        toast.success('Group successfully deleted.')
    }else{
        toast.error('Cannot delete group.')
    }
    setOpen(false);
  }

  async function deleteClientCode(){
    const body = {id: IDToDelete}
    let DeleteResponse = await postAPI('deleteallpayclientcode', body);
    let response = DeleteResponse.response;

    if(response.ok){
        refresh();
        toast.success('Client Code successfully deleted.')
    }else{
        toast.error('Cannot delete Client Code.')
    }
    setOpen(false);
  } 

  async function deleteCustomer(){
    const body = {userid: IDToDelete}
    let DeleteResponse = await postAPI('admin/users/delete', body);
    let response = DeleteResponse.response;

    if(response.ok){
        refresh();
        toast.success('Customer successfully deleted.')
    }else{
        toast.error('Cannot delete customer.')
    }
    setOpen(false);
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-100"
          enterFrom="opacity-0"
          enterTo="opacity-90"
          leave="ease-in duration-100"
          leaveFrom="opacity-90"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-start justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-100"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="text-left sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      {title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {message}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {deleteFunction()}}
                    tabIndex="-1"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    tabIndex="-1"
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}