import apiCall from "./apiCall";
export default async function getAPI(URLEND, PUBLIC) {
    let URL = ""
    if (PUBLIC) {
        URL = process.env.REACT_APP_API_URL+'/api/'+URLEND;
    } else {
        URL = process.env.REACT_APP_API_URL+'/api/'+process.env.REACT_APP_API_VERSION+'/'+URLEND;

    }
    const BODY = {}
    const data = await apiCall('GET', URL, BODY);
    return data;
};