
import {useEffect, useState} from 'react';
import postAPI from '../functions/API/postAPI.js'
import { toast } from 'react-toastify';
import getAPI from '../functions/API/getAPI.js'
import Select from 'react-select';

export default function NewBulkMessage({setVisible, refresh, refreshValue}) {


    const [newBulkMessage, setNewBulkMessage] = useState({
        subject: "",
        message: "",
    })

    const [operators, setOperators] = useState();
    const [columns, setColumns] = useState();
    const [newRules, setNewRules] = useState([])
    const [currentNewRule, setCurrentNewRule] = useState()
    const [ruleType, setRuleType] = useState();
    const [groupRuleOptions, setGroupRuleOptions] = useState([{'value': 'all', 'label': 'All Rules Must Match'}, {'value': 'one', 'label': 'One Rules Must Match'}]);

    function handleChange (event) {
        let statechange = event.target.name;
        let value = event.target.value;
        setNewBulkMessage({...newBulkMessage, [statechange]: value})
    }

    function handleCurrentColumn(event){
        setCurrentNewRule({...currentNewRule, 'column_name': event.value, 'table_name': event.id});
    }

    function handleCurrentOperator(event){
        setCurrentNewRule({...currentNewRule, 'operator': event.value});
    }

    function handleCurrentValueToCompare(event){
        setCurrentNewRule({...currentNewRule, 'value_to_compare': event.target.value});
    }
    
    function handleRuleMatching(event){
        setRuleType(event.value)
    }

    function removeRule(id){
        let amended = newRules.filter(function(rule, index) { 
            return index !== id 
        });
        setNewRules(amended);
    }

    function addRule(){
        if (currentNewRule.operator && currentNewRule.column_name && currentNewRule.value_to_compare) {
            setNewRules(newRules => [...newRules, currentNewRule]);  
        } else {
            toast.error('Please complete the rule fields')
        }
    }

    async function createBulkMessage(){
        let body = {'subject': newBulkMessage.subject, 'message': newBulkMessage.message, 'rule_match_type': ruleType, 'rules': newRules}
        if(newBulkMessage.subject && newBulkMessage.message && ruleType && newRules[0]){
            let BulkMessageResponse = await postAPI('admin/messages/create/bulk', body);
            if(BulkMessageResponse.response.status == 200){
                setVisible(false);
                toast.success('Bulk message ready to send')
                setNewRules();
                refresh(refreshValue+1);
                setNewRules([])
            }else{
                toast.error(BulkMessageResponse.data.message)
            }
        } else {
            toast.error('Please ensure all fields are populated')
        }
    }

    async function GetAPIData() {

        let OperatorsResponse = await getAPI('rules/operators');
        if (OperatorsResponse.response.ok) {
            let operators = [];
            OperatorsResponse.data.forEach(element => {
                operators.push({'value': element.operator, 'label': element.name})
            });
            setOperators(operators)
        }

        let ColumnsResponse = await getAPI('rules/columns');
        if (ColumnsResponse.response.ok) {
            let columns = [];
            ColumnsResponse.data.forEach(element => {
                columns.push({'value': element.column_name, 'label': element.column_name + " ("+element.table_name+")", 'id': element.table_name})
            });
            setColumns(columns)
        }
    }

    useEffect(() => {
        GetAPIData();
    }, []);

    return (
        <div>
            <div className="space-y-4">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Create a new bulk message</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Bulk messages are groups of messages sent to tenants when their agreement or asset matches the rules you define</p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-6 md:col-span-2">
                    <div className="sm:col-span-12">
                        <label htmlFor="subject" className="block text-sm font-medium leading-6 text-gray-900">
                            Subject
                        </label>
                        <div className="">
                            <input
                            required
                            type="text"
                            onChange={handleChange}
                            name="subject"
                            id="subject"
                            className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-12">
                        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                            Message
                        </label>
                        <div className="">
                            <textarea
                                required
                                rows={3}
                                className="mt-2 pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                name="message"
                                id="message"
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-12">
                        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                            Rule Matching
                        </label>
                        <div className="">
                            <Select
                                defaultValue=""
                                onChange={handleRuleMatching}
                                options={groupRuleOptions}
                                placeholder="Select the rule type"
                                className="dark:text-black pt-2"
                            />
                        </div>
                    </div>

                    <div className="sm:col-span-12">
                        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                            Add rules
                        </label>
                        <div className="">
                            <Select
                                defaultValue=""
                                onChange={handleCurrentColumn}
                                options={columns}
                                placeholder="Select Column"
                                className="dark:text-black pt-2 w-full"
                            />
                            <Select
                                defaultValue=""
                                onChange={handleCurrentOperator}
                                options={operators}
                                placeholder="Select condition"
                                className="dark:text-black pt-2 w-full"
                            />
                            <input required placeholder="Value to match" type="text" name="name" onChange={handleCurrentValueToCompare} className="placeholder-gray-500 mt-2 border-gray-300 rounded-md pt-2 w-full h-[38px] justify-center text-gray-800"/>
                            <div className="flex items-center col-span-10 md:col-span-1">
                                <button
                                    type="button"
                                    onClick={()=>{addRule()}}
                                    className="mt-1 w-full rounded-md text-slate-700 border border-gray-300 px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                >
                                    + Add Rule
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="sm:col-span-12">
                        <label htmlFor="message" className="block text-sm font-medium leading-6 text-gray-900">
                            Current Rules
                        </label>
                        <div className="">
                            <div className="flex flex-col items-start">
                                {newRules && (
                                    newRules.map(function(rule, index){
                                        return (
                                            <div className="grid grid-cols-12 gap-2" key={index}>
                                                {rule.operator == "==" && (
                                                    <p className="col-span-10">The <strong>{rule.column_name}</strong> must be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "!=" && (
                                                    <p className="col-span-10">The <strong>{rule.column_name}</strong> must not be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "contains" && (
                                                    <p className="col-span-10">The <strong>{rule.column_name}</strong> must contain <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                <svg className="col-span-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {removeRule(index)}}>
                                                    <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#2E3A59"></path>
                                                </svg>
                                            </div>
                                            )}
                                        )
                                    )}
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div className="mt-6 mb-4 flex items-center justify-start gap-x-6">
                
                <button
                type="button"
                onClick={()=>{createBulkMessage()}}
                className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                >
                Create
                </button>
                <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
                </button>
            </div>
        </div>
    )
}
