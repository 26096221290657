export default function Table({tableData}){
    // let tableData = {
    //     title: 'Customers',
    //     subheading: 'This table shows all registered',
    //     buttonText: 'Add Customer',
    //     buttonFunction: "function Name",
    //     tableHeadings: [
    //         {value: 'Firstname'},
    //         {value: 'Lastname'},
    //         {value: 'Agrement Reference'},
    //         {value: 'Email Address'},
    //     ],
    //     rows: [
    //         {
    //             data: [
    //                 {
    //                     "type": "text",
    //                     "value": "Matt",
                        
    //                 },
    //                 {
    //                     "type": "text",
    //                     "value": "Batchelor"
    //                 },
    //                 {
    //                     "type": "text",
    //                     "value": "AAA1234"
    //                 },
    //                 {
    //                     "type": "text",
    //                     "value": "mrb@outlook.com",
    //                     "tag": 
    //                         {
    //                             "value": "Verified",
    //                             "colour": "green" //red or green
    //                         }
    //                 },
    //                 {
    //                     "type": "link",
    //                     "value": "edit",
    //                     "action": "href",
    //                     "link": "https://thing.com"
    //                 },
    //                 {
    //                     "type": "button",
    //                     "value": "Delete",
    //                     "action": "function",
    //                     "buttontype": "warning", //warning = red & info = slate
    //                     "function": showDeleteModal
    //                 }
    //             ]
    //         },
    //     ]
    // }
  return(
    <>
    {tableData && (
        <div className="">
            <div className="sm:flex sm:items-center">
                {tableData && (
                <div className="sm:flex-auto">
                    <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">{tableData.title}</h1>
                    <p className="mt-2 text-sm text-gray-700">
                    {tableData.subheading}
                    </p>
                </div>
                )}
            {tableData.buttonText && tableData.buttonFunction && (
                <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                    <button onClick={tableData.buttonFunction} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                        {tableData.buttonText}
                    </button>
                </div>
            )}
            
            </div>
            <div className="mt-8 flow-root">
                <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2 align-middle  lg:pr-2">
                        <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                            {tableData && (
                                tableData.tableHeadings.map((heading) => (
                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                {heading.value}
                                </th>
                                ))
                            )}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {tableData && (
                            tableData.rows.map((row) => (
                                <tr key={row.id}>
                                {row.data.map((data) => (
                                    <>
                                        {data.type == 'text' && (
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">
                                                {data.tag && (
                                                    data.tag.colour == 'green' ? (
                                                    <span className="mr-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                    {data.tag.value}
                                                    </span>
                                                    ):(
                                                    <span className="mr-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                                                    {data.tag.value}
                                                    </span>
                                                    )
                                                )}                                                
                                                {data.value}
                                            </div>
                                            {data.lowerText && (
                                            <div className="mt-1 text-gray-500">{data.lowerText}</div>
                                            )}
                                        </td>
                                        )}
                                        {data.type == 'link' && (
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500 ">
                                            {data.action == 'function' && (
                                            <div onClick={()=>{data.function(data.functionparam)}} className="text-blue-700 hover:text-blue-900">
                                                {data.value}
                                            </div>
                                            )}
                                            {data.action == 'href' && (
                                            <a href={data.link} className="text-blue-700 hover:text-blue-900">
                                                {data.value}
                                            </a>
                                            )}
                                        </td>
                                        )}
                                        {data.type == 'button' && (
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            {data.buttontype == 'warning' && (
                                            <button onClick={()=>{data.function(data.functionparam)}} type="button" className="rounded bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                {data.value}
                                            </button>
                                            )}
                                            {data.buttontype == 'info' && (
                                            <button onClick={()=>{data.function(data.functionparam)}} type="button" className="rounded bg-slate-700 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                {data.value}
                                            </button>
                                            )}
                                        </td>
                                        )}
                                        {data.type == 'tag' && (
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            {data && (
                                                data.colour == 'green' ? (
                                                <span className="mr-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                {data.value}
                                                </span>
                                                ):(
                                                <span className="mr-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-green-600/20">
                                                {data.value}
                                                </span>
                                                )
                                            )}  
                                        </td>
                                        )}
                                    </>
                                ))}
                                </tr>
                            ))
                            )}
                        </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>
            )}
        </>
    );
}