import {useState, useEffect, Fragment, useRef } from 'react';
import Modal from 'react-modal';
import getAPI from '../functions/API/getAPI.js'
import moment from 'moment';
import { Dialog, Transition } from '@headlessui/react'
import { ToastContainer, toast } from 'react-toastify';
import postAPI from '../functions/API/postAPI.js'
import Loader from './loader.js';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'


export default function MessageCenterTable({setForm, refresh}) {
    
    const [securityCheckModal, setSecurityCheckModal] = useState(false);
    const [bulkMessages, setBulkMessages] = useState();
    const [bulkMessageModal, setBulkMessageModal] = useState(false)
    const [operators, setOperators] = useState();
    const [columns, setColumns] = useState();
    const [idToDelete, setIDToDelete] = useState();
    const [password, setPassword] = useState();
    const [numberToSend, setNumberToSend] = useState();
    const [tableData, setTableData] = useState();
    const [loadingSpinner, setLoadingSpinner] = useState(false);

    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    const cancelButtonRef = useRef(null)

    async function GetAPIData() {
        const BulkMessages = await getAPI('admin/messages/get/bulk');
        
        if (BulkMessages.response.ok) {
            let workingtableData = {
                title: 'Message Center',
                subheading: 'This table shows all bulk messages. Note that one message can be sent to more than one customer.',
                tableHeadings: [
                    {value: 'Subject'},
                    {value: 'Message'},
                    {value: 'Sender'},
                    {value: '# users'},
                    {value: 'Date'},
                    {value: 'Status'},
                    {value: ''}
                ],
                rows: []
            }
        
            BulkMessages.data['bulk messages'].forEach(element => {
                workingtableData.rows.push ({data:[
                    {type: 'text', value: element.subject},
                    {type: 'text', value: element.message},
                    {type: 'text', value: element.sender_id},
                    {type: 'text', value: element.users_messaged},
                    {type: 'text', value: element.created_at},
                    {type: 'text', value: element.status ? "Sent" : "Pending"},
                    {
                        "type": "link",
                        "value": "Detail",
                        "action": "function",
                        "function": "showAssetDetailModal",
                        'functionparam': element.id
                    },
                    {
                        "type": "button",
                        "value": "Delete",
                        "action": "function",
                        "buttontype": "warning",
                        "function": "showDeleteModal",
                        'functionparam': element.id
                    }
                ]})
            });

            setTableData(workingtableData)
            setBulkMessages(BulkMessages.data['bulk messages']);
            setIsLoaded(true);
        }
    }

    function openSecurityCheck(id, numbertosend){
        setIDToDelete(id);
        setNumberToSend(numbertosend);
        setSecurityCheckModal(true);
    }

    async function sendBulkMessage(){
        setIsLoaded(false)
        setLoadingSpinner(true)
        let body = {'id': idToDelete, 'password': password}
        let SendBulkMessage = await postAPI('admin/messages/send/bulk', body);
        if(SendBulkMessage.response.status == 200){
            toast.success('Bulk Message sent 🎉')
            setSecurityCheckModal(false);
            GetAPIData();
        }else{
            toast.error('Unable to send messages')
        }
        setIsLoaded(true)
        setLoadingSpinner(false)
    }

    async function deleteBulkMessage(id){
        let body = {'id': id}
        let DeleteBulkMesssage = await postAPI('admin/messages/delete/bulk', body);
        if(DeleteBulkMesssage.response.status == 200){
            toast.success('Bulk message deleted')
            GetAPIData();
        }else{
            toast.error('Unable to delete message')
        }
    }
    
    useEffect(() => {
        GetAPIData();
    }, [refresh]);
    
    return (
        loading ? (
            <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
            <div>
            <div className="">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Message Center</h1>
                        <p className="mt-2 text-sm text-gray-700">
                            This table shows all bulk messages. Note that one message can be sent to more than one customer.
                        </p>
                    </div>
                    <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                        <button onClick={()=>{setForm(true)}} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                            New Message
                        </button>
                    </div>
                
                </div>
                <div className="mt-4 flow-root">
                    <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2 align-middle lg:px-0">
                            <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Subject
                                    </th>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Message
                                    </th>
                                    <th scope="col" className="hidden lg:block py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Sender
                                    </th>
                                    <th scope="col" className="py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        #users
                                    </th>
                                    <th scope="col" className="hidden lg:block py-3.5 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Date
                                    </th>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        Status
                                    </th>
                                    <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">

                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {bulkMessages && bulkMessages.map((message, messageIndex) => (
                                    <tr key={messageIndex}>
                                        <td className="whitespace-wrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {message.subject}
                                            </div>
                                        </td>
                                        <td className="whitespace-wrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {message.message}
                                            </div>
                                        </td>
                                        <td className="hidden lg:block whitespace-wrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {message.sender_id}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {message.users_messaged}
                                            </div>
                                        </td>
                                        <td className="hidden lg:block whitespace-nowrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {moment(message.created_at).format('DD-MM-YYYY HH:mm')}
                                            </div>
                                        </td>
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            <div className="text-gray-900">                                           
                                                {message.status ? (
                                                    "Sent"
                                                ):(
                                                    "Pending"
                                                )}
                                            </div>
                                        </td>
                                        {!message.status && (
                                        <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                            <div className='flex gap-x-1'>
                                                <button onClick={()=>{openSecurityCheck(message.id, message.users_messaged)}} type="button" className="rounded bg-gray-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-gray-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                    Send
                                                </button>
                                                <button onClick={()=>{deleteBulkMessage(message.id)}} type="button" className="rounded bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                    Delete
                                                </button>
                                            </div>
                                        </td>
                                        )}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    </div>
                </div>
            </div>

            <div
                isOpen={securityCheckModal}
                //onAfterOpen={afterOpenModal}
                onRequestClose={() => {setSecurityCheckModal(false)}}
                contentLabel="SecurityCheck">
                <Transition.Root show={securityCheckModal} as={Fragment}>
                    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setSecurityCheckModal}>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-90"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-90"
                        leaveTo="opacity-0"
                        >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity- y" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex  items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt- text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div>
                                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                                </div>
                                <div className="mt-3 text-center sm:mt-5">
                                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                                    Security Check
                                    </Dialog.Title>
                                    <div className="mt-2">
                                    <p className="text-sm text-gray-700">
                                        To ensure that messages are not sent by mistake, please confirm your account password below.
                                    </p>
                                    <p className="text-sm text-gray-700">
                                        <br></br>
                                        This message will be sent to the following number of users:
                                        <br></br>
                                        <span className='font-bold text-2xl'>{numberToSend}</span>
                                    </p>
                                    <p className="text-sm text-gray-700">
                                        <br></br> <strong>NOTE: </strong>Once you press send, your message will be delivered immediately to all users accounts that match the rules you set when you created the bulk message. 
                                    </p>
                                    </div>
                                    <input
                                        type="password"
                                        name="password"
                                        id="password"
                                        onChange={(e)=>{setPassword(e.target.value)}}
                                        className="block w-full rounded-md border-0 py-2 my-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                        placeholder="Enter your account password"
                                    />
                                </div>
                                </div>
                                <div className="sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                                    onClick={() => setSecurityCheckModal(false)}
                                    ref={cancelButtonRef}
                                >
                                    Cancel
                                </button>
                                {loadingSpinner ? (
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                                        onClick={() => sendBulkMessage()}
                                        ref={cancelButtonRef}
                                    >
                                        <svg aria-hidden="true" className="mr-2 h-[20px] text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                        </svg>
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-2 sm:mt-0"
                                        onClick={() => sendBulkMessage()}
                                        ref={cancelButtonRef}
                                    >
                                        Send
                                    </button>
                                )}
                                
                                </div>
                            </Dialog.Panel>
                            </Transition.Child>
                        </div>
                        </div>
                    </Dialog>
                </Transition.Root>    
            </div>

            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
        ) 
    );
}

