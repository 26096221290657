import {useState, useContext, useEffect, useRef} from 'react';
import Loader from '../components/loader.js'
import HeaderBackground from '../components/headerbackground.js'
import GlobalContext from "../context/GlobalContext.js";
import Nav from '../components/Nav.js';
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import getAPI from '../functions/API/getAPI.js';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Modal from 'react-modal';
import postAPI from '../functions/API/postAPI.js'

export default function Messages() {
    const [loading, isLoading] = useState(false)
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)
    const [messages, setMessages] = useState();
    const [messageModal, setMessageModal] = useState(false);
    const [selectedMessageData, setSelectedMessageData] = useState({});

    async function getMessages(){
        let MessagesResponse = await getAPI('messages');
        let response = MessagesResponse.response;

        if(response.ok){
            setMessages(MessagesResponse.data.reverse())
        }else{
            toast.error('Sorry, we where unable to refresh your messages.')
        }
    }

    function setSelectedMessage(message){
        setSelectedMessageData({...selectedMessageData, "id": message.id, "subject": message.subject, "message": message.message, "date": message.date})
        UpdateMessageStatus(message.id, 1)
        setMessageModal(true)
    }

    async function UpdateMessageStatus(id, status) {
        let body = {id, status}
        const UpdateMessageResponse = await postAPI('messages/updatestatus', body);
    }

    function DeleteMessage(id){
        async function DeleteMessageFunction(id) {
            let body = {id}
            const DeleteMessageResponse = await postAPI('messages/delete', body);
            if(DeleteMessageResponse.response.ok){
                setMessageModal(false);
                toast.success('Message Deleted')
            }
        }
        DeleteMessageFunction(id).then(() => {getMessages()});
    }

    function messageUnread(id){
        UpdateMessageStatus(id, 0).then(()=>{getMessages(); setMessageModal(false)})
    }

    function HeaderOverlay() {
        return (
            <>
                <div className="w-11/12 lg:w-8/12 rounded-md border stats absolute top-46 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className={"py-4 grid grid-cols-1 lg:grid-cols-12 stat place-items-center place-content-ceter " + (themeCSS.divCSS)}>
                        <div className="col-span-12 lg:col-span-12 place-items-center place-content-center">
                            <div className="text-2xl">
                                <strong>Messages</strong>
                            </div> 
                        </div> 
                    </div>
                </div>
            </>
        )
    }

    useEffect(() => {
        getMessages()
    }, []);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}

            <Nav />
            <HeaderBackground/>
            <HeaderOverlay /> 
                
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
                <div className={"flex justify-center " + (themeCSS.divCSS)}>
                    <div className='w-full md:w-11/12 mt-12 mb-8 lg:w-8/12 grid grid-cols-1 first-letter'>
                        
                        <ul role="list"
                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl border-white border-2">
                            {messages && messages.length <= 0 && (
                                <p className='text-center py-4 h-full'>You have no messages</p>
                            )}
                            {messages && messages.map((message) => (
                                <li key={message.id} className={"relative flex justify-between gap-x-6 px-4 py-5 sm:px-6 " + (themeCSS.messageItem)} onClick={()=>{setSelectedMessage(message)}}>
                                    <div className="flex gap-x-4 rounded">
                                        <div>
                                            {!message.status ? (
                                                <span className="inline-flex mt-1.5 h-4 w-4 items-center justify-center rounded-full bg-blue-500"></span>
                                            ):(
                                                <span className="inline-flex ml-4"></span>
                                            )}
                                        </div>
                                        <div className="min-w-0 flex-auto">
                                            <p className={"text-lg font-semibold leading-6 text-gray-900 text-left " + (themeCSS.text)}>
                                            {message.subject}
                                            </p>
                                            <p className={"text-left text-gray-700 text-sm " + (themeCSS.text)}>{moment(message.date).format('dddd Do MMMM YYYY')} @ {message.time}</p>
                                            <p className={"flex text-sm leading-5 text-gray-500 text-left " + (themeCSS.text)}>
                                            {message.message}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-x-0">
                                        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={messageModal}
                //onAfterOpen={afterOpenModal}
                onRequestClose={() => {setMessageModal(false)}}
                className="overflow-y-hidden overflow-x-hidden fixed right-0 left-0 z-50 justify-center items-center"
                contentLabel="safetyModal">
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-30 overflow-y-auto h-full w-full">
                        <div className={"rounded-lg lg:w-2/4 relative top-20 mx-auto border " + (themeCSS.divCSS)}>
                            <div className="rounded-lg flex px-6">
                                <div className="w-full pt-6 pr-4">
                                    <h3 className={"font-bold text-xl text-slate-700 " + (themeCSS.text)}>{selectedMessageData.subject}</h3>
                                        <p className={"text-left text-gray-700 pt-1 text-sm " + (themeCSS.text)}>{moment(selectedMessageData.date).format('dddd Do MMMM YYYY')}</p>
                                        <p className={"pt-4 text-slate-500 " + (themeCSS.text)}>{selectedMessageData.message}</p>
                                </div>
                            </div>
                            <div className="p-4 flex space-x-4">
                            <a onClick={() => {DeleteMessage(selectedMessageData.id)}} className="w-1/2 px-4 py-3 text-center bg-red-500 text-white hover:bg-red-600 hover:text-white font-bold rounded-lg text-sm">Delete</a>
                            <a onClick={() => {messageUnread(selectedMessageData.id)}} className="w-1/2 px-4 py-3 text-center bg-gray-100 text-gray-700 hover:bg-gray-200 hover:text-black font-bold rounded-lg text-sm">Mark as unread</a>
                            <a onClick={() => {getMessages(); setMessageModal(false)}} className="w-1/2 px-4 py-3 text-center text-white bg-slate-600 rounded-lg hover:bg-slate-700 hover:text-white font-bold text-sm">Close</a>
                            </div>
                        </div>
                    </div>
            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
        
    );
}