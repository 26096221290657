import {useState, useEffect} from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MagnifyingGlassIcon, ArrowDownTrayIcon } from '@heroicons/react/20/solid'
import Loader from './loader.js';
import AssetDetail from '../components/assetDetail.js';


export default function CustomersTable({deleteFunction, shouldRefresh}) {
    
    const [reload, setReload] = useState(false);
    const [tableData, setTableData] = useState();
    const [initialTableData, setInitialTableData] = useState();
    const [paginationData, setPaginationData] = useState({
        from: "",
        to: "",
        totalRecords: "",
        currentPage: "",
        lastPage: "",
        }
    );
    const [searchTerm, setSearchTerm] = useState();
    
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    const [showAssetDetail, setShowAssetDetail] = useState(false);
    const [assetDetailData, setAssetDetailData] = useState();
    const [currentSearchString, setCurrentSearchString] = useState('');

    async function GetAPIData(page, search) {

        let workingTableData = {
            title: 'Customers',
            subheading: 'This table shows all registered customers and their status',
            tableHeadings: [
                {value: 'Firstname'},
                {value: 'Lastname'},
                {value: 'Agreement Reference'},
                {value: 'Status'},
                {value: 'Email Address'},

            ],
            rows: []
        }

        let CustomerResponse;
        if(search){ 
            setSearchTerm(search);
            if(page){
                CustomerResponse = await getAPI('admin/customers?search='+search+'&page='+page);
            } else {
                CustomerResponse = await getAPI('admin/customers?search='+search);
            }
        } else {
            if(page){
                CustomerResponse = await getAPI('admin/customers?page='+page);
            } else {
                CustomerResponse = await getAPI('admin/customers');
            }
        }
        

        setPaginationData({...paginationData, 
            currentPage: CustomerResponse.data.current_page,
            from: CustomerResponse.data.from,
            to: CustomerResponse.data.to,
            totalRecords: CustomerResponse.data.total,
            lastPage: CustomerResponse.data.last_page
        });

        CustomerResponse.data.data.forEach(element => {
            workingTableData.rows.push ({data:[
                {type: 'text', value: element.firstname},
                {type: 'text', value: element.lastname},
                {type: 'text', value: element.agreementreference},
                {type: 'tag', value: element.extended_verification ? 'Verified' : "Unverified", "colour": !element.extended_verification ? 'red' : "green"},
                {type: 'text', value: element.email
                },
                {
                    "type": "link",
                    "value": "Detail",
                    "action": "function",
                    "function": showAssetDetailModal,
                    'functionparam': element.id
                },
                {
                    "type": "button",
                    "value": "Delete",
                    "action": "function",
                    "buttontype": "warning",
                    "function": deleteFunction,
                    'functionparam': element.id
                    
                }
            ]})
        });
        setTableData(workingTableData)
        setInitialTableData(workingTableData)
        setIsLoaded(true);
    }

    function nextPage(){
        if(paginationData.currentPage < paginationData.lastPage){
            GetAPIData(paginationData.currentPage + 1, searchTerm)
        }
    }

    function previousPage(){
        if(paginationData.currentPage > 1){
            GetAPIData(paginationData.currentPage - 1, searchTerm)
        }
    }

    function firstPage(){
        GetAPIData(1, searchTerm)
    }

    function lastPage(){
        GetAPIData(paginationData.lastPage, searchTerm)
    }

    async function showAssetDetailModal(userid){
        const body = {customerid: userid}
        let AssetDetailsResponse = await postAPI('admin/assetdetailsbycustomer', body);
        if(AssetDetailsResponse.response.ok){
            setAssetDetailData(AssetDetailsResponse.data)
            setShowAssetDetail(true)
        }else{
            toast.error('Error getting asset details.')
        }
    }

    const handleSearch = (event) => {
        GetAPIData(1, event.target.value)
    };
    
    const downloadCSV = async () => {
        let workingTableData = []
        let CustomerResponse;
        if(searchTerm){
            CustomerResponse = await getAPI('admin/customers?search='+searchTerm+'&all=true');
        } else {
            CustomerResponse = await getAPI('admin/customers?all=true');
        }
        CustomerResponse.data.forEach(element => {
            workingTableData.push ([
                {   
                    firstname: element.firstname, 
                    lastname: element.lastname,
                    agreementreference: element.agreementreference,
                    extended_verification: element.extended_verification,
                    email: element.email,
                    signup_date: element.signup_date
                }
            ])
        });

        const csvData = convertToCSV(workingTableData);
        const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'customers.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    

    const convertToCSV = (data) => {
        const headers = ['firstname', 'lastname', 'agreementreference', 'extended_verification', 'email', 'signup_date'];
        if (data.length === 0) return '';
        const rows = data.map(obj => {
            return headers.map(header => {
            const value = obj[0][header];
            return typeof value === 'object' ? JSON.stringify(value) : value;
            }).join(',');
        }).join('\n');
        return `${headers.join(',')}\n${rows}`;
    };

    useEffect(() => {
        GetAPIData();
    }, [reload, shouldRefresh]);
    
    return (
        loading ? (
            <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
        ) : (
            showAssetDetail ? (
                <AssetDetail selectedAssetDetail={assetDetailData} visible={setShowAssetDetail}/>
            ) : (
                <div>
                {tableData && (
                    <div className="">
                        <div className="sm:flex sm:items-center">
                            {tableData && (
                            <div className="sm:flex-auto">
                                <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">{tableData.title}</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                {tableData.subheading}
                                </p>
                            </div>
                            )}
                        {tableData.buttonText && tableData.buttonFunction && (
                            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                                <button onClick={tableData.buttonFunction} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                    {tableData.buttonText}
                                </button>
                            </div>
                        )}
                        
                        <div className='flex flex-row mt-2 md:mt-0'>
                            <ArrowDownTrayIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
                            <button onClick={downloadCSV} className='text-gray-400 pl-1 text-sm'>Download</button>
                        </div>

                        </div>
                        <div className="relative">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </div>
                            <input
                            id="search"
                            name="search"
                            className="mt-6 block w-full rounded-md border-0 bg-white py-1.5 pl-10 pr-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                            placeholder="Search"
                            type="search"
                            value={currentSearchString}
                            onKeyDown={(e) => {
                                    if (e.key === "Enter"){
                                        handleSearch(e);
                                    } else if (e.key === "Backspace" && e.target.value == ""){
                                        setSearchTerm('')
                                        GetAPIData()
                                    }
                                }
                            }
                            onChange={(e) => {
                                setCurrentSearchString(e.target.value)
                                    if (e.target.value == ""){
                                        setSearchTerm('')
                                        GetAPIData()
                                    }
                                }
                            }
                            />
                        </div>
                        {!!paginationData && (
                            <nav className="flex items-center justify-between border-t border-gray-200 bg-white py-3" aria-label="Pagination">
                                <div className="sm:block">
                                    <p className="text-sm text-gray-700">
                                    Showing <span className="font-medium">{paginationData.from}</span> to <span className="font-medium">{paginationData.to}</span> of 
                                    <span className="font-medium"> {paginationData.totalRecords}</span> records
                                    </p>
                                </div>
                                <div className="flex flex-1 justify-end sm:justify-end">
                                    <button onClick={()=>{firstPage()}} className="mr-1 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                                    First
                                    </button>
                                    <button onClick={()=>{previousPage()}} className="mr-1 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
                                    Prev
                                    </button>
                                    <button onClick={()=>{nextPage()}} className="mr-1 relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0">
                                    Next
                                    </button>
                                    <button onClick={()=>{lastPage()}} className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900">
                                    Last
                                    </button>
                                </div>
                            </nav>
                        )}
                        <div className="flow-root">
                            <div className="overflow-x-auto">
                                <div className="inline-block min-w-full py-2 align-middle">
                                    <table className="min-w-full divide-y divide-gray-300">
                                    <thead>
                                        <tr>
                                        {tableData && (
                                            tableData.tableHeadings.map((heading) => (
                                            <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                            {heading.value}
                                            </th>
                                            ))
                                        )}
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-200 bg-white">
                                        {tableData && (
                                        tableData.rows.map((row) => (
                                            <tr key={row.id}>
                                            {row.data.map((data) => (
                                                <>
                                                    {data.type == 'text' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        <div className="text-gray-900">
                                                            {data.tag && (
                                                                data.tag.colour == 'green' ? (
                                                                <span className="mr-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                {data.tag.value}
                                                                </span>
                                                                ):(
                                                                <span className="mr-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                                {data.tag.value}
                                                                </span>
                                                                )
                                                            )}                                                
                                                            {data.value}
                                                        </div>
                                                        {data.lowerText && (
                                                        <div className="mt-1 text-gray-500">{data.lowerText}</div>
                                                        )}
                                                    </td>
                                                    )}
                                                    {data.type == 'link' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500 ">
                                                        {data.action == 'function' && (
                                                        <div onClick={()=>{data.function(data.functionparam)}} className="cursor-pointer text-blue-700 hover:text-blue-900">
                                                            {data.value}
                                                        </div>
                                                        )}
                                                        {data.action == 'href' && (
                                                        <a href={data.link} className="cursor-pointer text-blue-700 hover:text-blue-900">
                                                            {data.value}
                                                        </a>
                                                        )}
                                                    </td>
                                                    )}
                                                    {data.type == 'button' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        {data.buttontype == 'warning' && (
                                                        <button onClick={()=>{data.function(data.functionparam)}} type="button" className="cursor-pointer rounded bg-red-600 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                            {data.value}
                                                        </button>
                                                        )}
                                                        {data.buttontype == 'info' && (
                                                        <button onClick={()=>{data.function(data.functionparam)}} type="button" className="cursor-pointer rounded bg-slate-700 px-3 py-1 text-xs font-semibold text-white shadow-sm hover:bg-slate-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                            {data.value}
                                                        </button>
                                                        )}
                                                    </td>
                                                    )}
                                                    {data.type == 'tag' && (
                                                    <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                        {data && (
                                                            data.colour == 'green' ? (
                                                            <span className="mr-2 inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                            {data.value}
                                                            </span>
                                                            ):(
                                                            <span className="mr-2 inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/20">
                                                            {data.value}
                                                            </span>
                                                            )
                                                        )}  
                                                    </td>
                                                    )}
                                                </>
                                            ))}
                                            </tr>
                                        ))
                                        )}
                                    </tbody>
                                    </table>
                                </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    />
                </div> 
            )
        )
    );
    
}

