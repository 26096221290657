import {useState} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomersTable from '../components/customersTable.js';
import DeleteModal from '../modals/delete.js';

export default function Customers() {
    
    const [deleteModal, setDeleteModal] = useState(false);
    const [IDToDelete, setIDToDelete] = useState();
    const [shouldRefresh, setShouldRefresh] = useState(false);

    function showDeleteModal(id){
        setIDToDelete(id);
        setDeleteModal(true);
    } 

    function refresh(){
        setShouldRefresh(!shouldRefresh);
    }

    return (
        <div>
            <AdminSidebar>
                <CustomersTable deleteFunction={showDeleteModal} shouldRefresh={shouldRefresh}/>
            </AdminSidebar>
            <DeleteModal open={deleteModal} setOpen={setDeleteModal} IDToDelete={IDToDelete} refresh={refresh} deleteAction="deleteCustomer" title="Delete customer" message="Are you sure you want to delete this customer? This action cannot be undone." />
            <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            />
        </div> 
    );
    
}

