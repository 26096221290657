import { useContext } from 'react';
import GlobalContext from "../context/GlobalContext.js";

export default function HomePageCard({ title, body, buttontext, url, badgecount }) {
    const {themeCSS} = useContext(GlobalContext)
    return (
        <div className="h-full rounded-md text-center border flex flex-col">
            <div className="flex-grow p-6">
                <h2 className="text-xl font-bold mb-2">{title}</h2>
                <p className="">{body}</p>
            </div>
            <div className="p-4 mt-auto">
                <span className="relative inline-block">
                    <button 
                        className={"btn btn-natural capitalize " + (themeCSS.buttonCSS)}
                        onClick={() => { window.location.href = url }}>
                        {buttontext}
                    </button>
                    {badgecount ? (
                        <span className="absolute w-8 h-8 top-[-12px] right-[-10px] inline-flex items-center justify-center px-2 py-2 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full">{badgecount}</span>
                    ) : null}
                </span>
            </div>
        </div>
    );
}