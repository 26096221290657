import {useContext} from 'react';
import GlobalContext from "../context/GlobalContext.js";

export default function HeaderOverlay({agreement, type, accountreference}) {
    const {theme, updateTheme, themeCSS} = useContext(GlobalContext)
    return (
        <>
        {agreement && ( 
                <div className="w-11/12 lg:w-8/12 border rounded-md stats absolute top-46 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className={"py-2 grid grid-cols-1 lg:grid-cols-12 stat place-items-center place-content-center " + (themeCSS.divCSS)}>
                        <div className="col-span-12 lg:col-span-6 place-items-center place-content-center">
                            <div className="text-xl">
                                    <strong>{type}</strong>
                            </div> 
                        </div> 
                        <div className={`${type ? 'col-span-12 lg:col-span-6' : 'col-span-12'}`}>
                            {accountreference ? (
                                <div className="stat-title text-center">Account Reference: {accountreference}</div>
                            ) : (
                                <div className="stat-title text-center">Agreement Reference: {agreement.reference}</div>
                            )}
                            <div className="stat-value text-sm whitespace-normal">{agreement.address}</div>    
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}