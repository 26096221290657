import { ExclamationTriangleIcon, XMarkIcon } from '@heroicons/react/24/outline'
export default function AssetDetail({selectedAssetDetail, visible}) {

    return (
    <div>
        <div className="flex">
            <div className="px-4 sm:px-0">
                <h3 className="text-base font-semibold leading-7 text-gray-900">Asset information</h3>
                <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">You can use this information to verify data between your systems</p>
            </div>
            <div>
                <div className="absolute right-0 mr-8">
                    <button type="button" onClick={() => visible(false)} className="rounded-md bg-white text-gray-400 hover:text-gray-500">
                        <span className="sr-only">Close</span>
                        <XMarkIcon aria-hidden="true" className="h-6 w-6" />
                    </button>
                </div>
            </div>
        </div>
        <div className="mt-6">
        {selectedAssetDetail && (
            selectedAssetDetail.map(function(asset, index){
                return (
                    <>
                        <dl className="grid grid-cols-1 sm:grid-cols-4">
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Asset Reference</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.asset_reference}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">House Name</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.house_name}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">House Number</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.house_number}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Street</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.street}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Town/City</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.town_city}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">County</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.county}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Postcode</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.postcode}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Bedrooms</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.bedrooms}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.asset_status}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Type</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.asset_type}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Category</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.category}</dd>
                            </div>
                            <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                <dt className="text-sm font-medium leading-6 text-gray-900">Classification</dt>
                                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{asset.classification}</dd>
                            </div>
                        </dl>
                        
                        <h3 className="px-4 text-base font-semibold leading-7 text-gray-900">Attribute information</h3>
                        <dl className="grid grid-cols-1 sm:grid-cols-4">
                            {asset.attributes && asset.attributes.length > 0 ? (
                                asset.attributes.map(function(attribute, index){
                                    return (
                                        <div className="border-t border-gray-100 px-4 py-4 sm:col-span-1 sm:px-0">
                                            <dt className="text-sm font-medium leading-6 text-gray-900">{attribute.characteristic}</dt>
                                            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{attribute.value}</dd>
                                        </div>
                                    )
                                })
                            ) : (
                                <div className="px-4 py-4 sm:col-span-1 sm:px-0">
                                    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">No attributes available</dd>
                                </div>
                            )}
                        </dl>
                        
                        <div className="relative mx-4">
                            <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                <div className="w-full border-t border-gray-300" />
                            </div>
                            <div className="relative flex justify-center">
                                <span className="bg-white px-4 text-sm text-gray-500 mb-6">End of Asset</span>
                            </div>
                        </div>
                    </>
                    )
                })
            )
        }
        </div>
    </div>
    )
}
