import {react, useState, useEffect} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import getAPI from '../functions/API/getAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import Table from '../components/Table.js';
import NewAdminForm from '../forms/newAdministrator.js';
import DeleteModal from '../modals/delete.js';
import SlideOverRight from '../components/slideOverRight.js';
import Loader from '../components/loader.js';

export default function AdminUsers() {
    
    const [users, setUsers] = useState();
    const [deleteModal, setDeleteModal] = useState(false);
    const [IDToDelete, setIDToDelete] = useState();
    const [newAdminForm, setNewAdminForm] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(0);

    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData() {
        const UsersResponse = await getAPI('admin/users');

        let tableData = {
            title: 'Administrators',
            subheading: 'Administrators have full control over the system and its configuration',
            buttonText: 'Add Administrator',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Firstname'},
                {value: 'Lastname'},
                {value: 'Email'},
                {value: ''},
            ],
            rows: []
        }

        UsersResponse.data.forEach(element => {
            tableData.rows.push ({data:[
                {type: 'text', value: element.firstname},
                {type: 'text', value: element.lastname},
                {type: 'text', value: element.email},
                {
                    "type": "button",
                    "value": "Delete",
                    "action": "function",
                    "buttontype": "warning",
                    "function": showDeleteModal,
                    'functionparam': element.id           
                }
            ]})
        });
        setUsers(tableData);
        setIsLoaded(true);
    }

    function showDeleteModal(USERID){
        setIDToDelete(USERID);
        setDeleteModal(true);
    }

    function setFormState(){
        setNewAdminForm(true);
    }

    useEffect(() => {
        GetAPIData();
    }, [shouldRefresh]);
    
    return (
        <>
            <>
            <AdminSidebar>
                {loading ? (    
                    <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded} /> 
                ) : (
                    <>
                        <Table tableData={users} /><SlideOverRight visible={newAdminForm} setVisible={setNewAdminForm}>
                            <NewAdminForm setVisible={setNewAdminForm} refresh={setShouldRefresh} refreshValue={shouldRefresh} />
                        </SlideOverRight>
                    </>
                )}
            </AdminSidebar> 
            <DeleteModal open={deleteModal} setOpen={setDeleteModal} IDToDelete={IDToDelete} refresh={GetAPIData} deleteAction="deleteAdminUser" title="Delete admin" message="Are you sure you want to delete this admin user? This action cannot be undone." />
            </>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
    
}

