
import {useState} from 'react';
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewCategoryForm({setVisible, refresh, refreshValue}) {

    const [newCategory, setNewCategory] = useState({
        name: "",
        friendlyname: "",
        active: 1,
    })

    async function createNewCategory(){
        const body = {name: newCategory.name, friendlyname: newCategory.friendlyname, active: newCategory.active}
        const NewCategoryResponse = await postAPI('category', body);
        if(NewCategoryResponse.response.ok){
            refresh(refreshValue+1);
            toast.success('Category created successfully.');
            setVisible(false);
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }        
    }

    function handleChange (event) {
        let statechange = event.target.name;
        let value = event.target.value;
        setNewCategory({...newCategory, [statechange]: value})
    }

    return (
        <form>
            <div className="space-y-4">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Create new repair category</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Repair categories help customers select repair defintions</p>
                </div>

                <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                    <div className="sm:col-span-1">
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                            Category name
                        </label>
                        <div className="">
                            <input
                            required
                            type="text"
                            onChange={handleChange}
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-1">
                        <label htmlFor="friendlyname" className="block text-sm font-medium leading-6 text-gray-900">
                            Friendly name
                        </label>
                        <div className="">
                            <input
                            required
                            type="text"
                            onChange={handleChange}
                            name="friendlyname"
                            id="friendlyname"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-6 flex items-center justify-start gap-x-6">
                <button
                type="button"
                onClick={()=>{createNewCategory()}}
                className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                >
                Create
                </button>
                <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
                Cancel
                </button>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </form>
    )
}
