import apiCall from "./apiCall";
export default async function postRegister(FIRSTNAME, LASTNAME, DOB, ACCOUNTREFERENCE, EMAIL, PASSWORD, CONFIRMPASSWORD) {
    const URL = process.env.REACT_APP_API_URL+'/api/register';
    const BODY = {  
                    firstname: FIRSTNAME, 
                    lastname: LASTNAME, 
                    dob: DOB,
                    agreementreference: ACCOUNTREFERENCE,
                    email: EMAIL,
                    password: PASSWORD,
                    password_confirmation: CONFIRMPASSWORD
                }
    const data = await apiCall('POST', URL, BODY);
    return data;
};