import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function SlideOverRight({children, visible, setVisible}) {
  return (
    <Transition.Root show={visible} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setVisible}>
          <div className="fixed inset-0" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                      as={Fragment}
                      enter="transform transition ease-in-out duration-0 sm:duration-0"
                      enterFrom="translate-x-full"
                      enterTo="translate-x-0"
                      leave="transform transition ease-in-out duration-0 sm:duration-0"
                      leaveFrom="translate-x-0"
                      leaveTo="translate-x-full"
                  >
                      <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                        <div className="p-2 py-4 flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                            {children}
                        </div>
                      </Dialog.Panel>
                  </Transition.Child>
                </div>
            </div>
          </div>
      </Dialog>
    </Transition.Root>
  )
}