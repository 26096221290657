import {react, useState, useEffect} from 'react';
import getAPI from '../functions/API/getAPI.js'
import AdminSidebar from '../components/admin/AdminSidebar.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Table from '../components/Table.js';
import NewRequestTypeForm from '../forms/newRequestType.js';
import SlideOverRight from '../components/slideOverRight.js';
import Loader from '../components/loader.js'

export default function RequestTypes() {
    const [newRequestForm, setNewRequestForm] = useState(false);
    const [requesttypes, setRequestTypes] = useState();
    const [shouldRefresh, setShouldRefresh] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    
    async function GetAPIData() {
        let RequestTypeResponse = await getAPI('tenant/requesttype');
        let tableData = {
            title: 'Request Types',
            subheading: 'Create new request types and map them to requests in your integrated system',
            buttonText: 'Add request type',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Request Title'},
                {value: 'Request Description'},
                {value: 'Type'},
                {value: 'Proxy Reference'},
            ],
            rows: []
        }
        RequestTypeResponse.data.forEach(element => {
            tableData.rows.push ({data:[
                {type: 'text', value: element.title},
                {type: 'text', value: element.description},
                {type: 'text', value: element.type},
                {type: 'text', value: element.proxyreference},
            ]})
        });
        setRequestTypes(tableData);
        setIsLoaded(true);
    }

    function setFormState(){
        setNewRequestForm(true);
    }

    useEffect(() => {
        GetAPIData();
    }, [shouldRefresh]);
    
    return (
        <>
            <>
                <AdminSidebar>
                    {loading ? (
                        <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
                    ) : (
                        <Table tableData={requesttypes} />
                    )}
                    <SlideOverRight visible={newRequestForm} setVisible={setNewRequestForm}>
                        <NewRequestTypeForm setVisible={setNewRequestForm} refresh={setShouldRefresh} refreshValue={shouldRefresh}/>
                    </SlideOverRight>
                </AdminSidebar>
            </>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
}

