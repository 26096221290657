import {useState, useEffect, useContext} from 'react';
import Joyride from 'react-joyride';
import postAPI from '../functions/API/postAPI.js'
import GlobalContext from "../context/GlobalContext.js";


export default function HomePageWalkthrough({userDetails}) {

    const [active, setActive] = useState(false);
    const {runWalkthrough, setRunWalkthrough} = useContext(GlobalContext)


    function GetUserDetails() {
        if (runWalkthrough){
            setActive(true);
            return
        }
        if (userDetails) {
            if (!userDetails.homepage_walkthrough_status) {
                setActive(true);
            }
        }
    }

    async function updateWalkthroughStatus(status){
        const body = {"homepage_walkthrough_status": status}
        await postAPI("userdetails", body);
    }

    let handleDemoCallback = data => {
        const { action, index, status, type } = data;
        if (action == 'skip') {
            updateWalkthroughStatus('Skipped')
            setRunWalkthrough(false)
        }
        if (status == 'finished') {
            updateWalkthroughStatus('Complete')
            setRunWalkthrough(false)
        }
    };

    const [steps, setSteps] = useState([
        {
            target: '#homepage-demo',
            content: 'Welcome to your tenant portal. As this is the first time you have logged in, we will take you through a quick walkthrough.',
            disableBeacon: true,     
        },
        {
            target: '#messages-demo',
            content: "From here, you can access messages that we send you.",
            disableBeacon: true,
        },
        {
            target: '#appointments-demo',
            content: 'Here, you will see your next upcoming appointment and you can click "View Appointments" to view upcoming and past appointments.',
            disableBeacon: true,     
        },
        {
            target: '#repairs-demo',
            content: 'You can access your repairs and you can even raise a new repair.',
            disableBeacon: true,
        },
        {
            target: '#requests-demo',
            content: 'You can access your requests here and you can raise new requests.',
            disableBeacon: true,
        },
        {
            target: '#transactions-demo',
            content: 'From here, you can access your account transactions and you can download transactions as a statement.',
            disableBeacon: true,
        },
        {
            target: '#payment-demo',
            content: 'When you need to make a payment on your account, you can do that here.',
            disableBeacon: true,
        },
        {
            target: '#direct-debit-demo',
            content: "When you want to setup a direct debit for your account, you can do that here.",
            disableBeacon: true,
        },
        {
            target: '#agreementswitcher-demo',
            content: 'As you have more than one account, you can switch between then here.',
            disableBeacon: true,
        },
        {
            target: '#nav-demo',
            content: 'You can quickly access any area of your tenant portal using this menu.',
            disableBeacon: true,
        },
        {
            target: '#logo-demo',
            content: "If at any point you need to head back to the home page, you can click here.",
            disableBeacon: true,
        },
        {
            target: '#profile-demo',
            content: "It is super important to keep your contact details up-to-date. You can update your profile from here.",
            disableBeacon: true,
        },
    ])

    useEffect(() => {
        GetUserDetails();
    }, [])

    useEffect(() => {
        if (runWalkthrough) {
            setActive(true)
        }
    }, [runWalkthrough])
    
    return (
        <Joyride
            steps={steps}
            run={active}
            callback={handleDemoCallback}
            continuous={true}
            locale={{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', open: 'Open the dialog', skip: 'Skip Demo' }}
            showSkipButton={true}
        />
    )
}