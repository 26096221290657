import {useState, useContext, useEffect, useRef, Fragment} from 'react';
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import GlobalContext from "../context/GlobalContext.js";
import HeaderBackground from '../components/headerbackground.js'
import getAPI from '../functions/API/getAPI.js'
import HeaderOverlay from '../components/headeroverlay.js';
import moment from 'moment';    
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import { ToastContainer, toast } from 'react-toastify';
import postAPI from '../functions/API/postAPI.js';
import AccountSwitcher from '../components/AccountSwitcher.js';

export default function Transactions() {

    const [agreement, setAgreement] = useState();
    const [account, setAccount] = useState('');
    const [accounts, setAccounts] = useState();
    const [profile, setProfile] = useState();
    const [loading, isLoading] = useState(true)
    const [transactions, setTransactions] = useState();
    const [companyname, setCompanyName] = useState(localStorage.getItem('company_name') );
    const [startDate, setStartDate] = useState(moment().subtract(1, 'month').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [reload, setReload] = useState(false);
    const [loadingspinner, setLoadingSpinner] = useState(false);
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext);
    const [formattedAddress, setFormattedAddress] = useState([]);
    const [accountSelectModal, setAccountSelectModal] = useState(false);

    const pdfRef = useRef(null);

    function handleDownload() {
        const doc = new jsPDF('p', 'pt', 'a4');
        var pageSize = doc.internal.pageSize
        autoTable(doc, { html: '#transactiontable', startY: 250, theme: 'grid', headStyles: {fillColor: settings.primary_hex_colour}})      
        const content = pdfRef.current;
        doc.html(content, {
            callback: function (doc) {
                doc.save('Account Transactions.pdf');
            }, x: 10, y: 10,
            html2canvas: { scale: 0.90 },
        });
    };

    async function GetAPIData() {
        const agreementreference = localStorage.getItem('agreement');
        const AgreementResponse = await getAPI('agreements/'+agreementreference);
        if (AgreementResponse.response.ok) {
            setAccounts(AgreementResponse.data.accounts)
            const ProfileDetailsResponse = await getAPI('userdetails/summary');
            var address = AgreementResponse.data.address;
            var addressArr = address.split(',');
            let finaladdressarray = addressArr.map(function (addresselement) {
                return addresselement.trim();
            });
            setFormattedAddress(finaladdressarray);
            setProfile(ProfileDetailsResponse.data)
            setAgreement(AgreementResponse.data); 

            if(AgreementResponse.data.accounts.length <= 1){
                setAccount(AgreementResponse.data.accounts[0]);
                let TransactionURL = 'transactions/'+AgreementResponse.data.accounts[0].accountid+"?startdate="+startDate+"&enddate="+endDate;
                const TransactionResponse = await getAPI(TransactionURL);       
                setTransactions(TransactionResponse.data); 
            } else {
                setAccountSelectModal(true)
            }
        }
        isLoading(false)
    }

    async function setSelectedAccount(account) {
        setAccountSelectModal(false)
        isLoading(true)
        setAccount(account);
            
            let TransactionURL = 'transactions/'+account.accountid+"?startdate="+startDate+"&enddate="+endDate;
            const AgreementDetail = await getAPI('agreements/'+localStorage.getItem('agreement'));
            const ProfileDetailsResponse = await getAPI('userdetails/summary');
            const TransactionResponse = await getAPI(TransactionURL);
            var address = AgreementDetail.data.address;
            var addressArr = address.split(',');
            let finaladdressarray = addressArr.map(function (addresselement) {
                return addresselement.trim();
            });
            setFormattedAddress(finaladdressarray);
            setTransactions(TransactionResponse.data); 
            setProfile(ProfileDetailsResponse.data)
            setAgreement(AgreementDetail.data); 
            isLoading(false)
    }

    function handleStartDateChange(event){
        setStartDate(event.target.value)
    }

    function handleEndDateChange(event){
        setEndDate(event.target.value)
    }

    async function applyFilter(){
        setLoadingSpinner(true);
        let TransactionURL = 'transactions/'+account.accountid+"?startdate="+startDate+"&enddate="+endDate;
        const TransactionResponse = await getAPI(TransactionURL);
        setTransactions(TransactionResponse.data);
        setLoadingSpinner(false);
    }

    //Function only used if the payment provider is AllPay
    async function makeAllPayPayment(){
        const body = {reference: agreement.reference}
        let PaymentProviderDetail = await postAPI('makepayment', body);
        let response = PaymentProviderDetail.response;

        if(response.ok){
            window.location.href = '/allpaypayment/'+account.accountreference+"/"+PaymentProviderDetail.data.Token+"/"+encodeURIComponent(settings.allpay_gatewayurl)+"/"+PaymentProviderDetail.data.ClientCode+"/web";
        }else{
            toast.error('There was an error connecting to the payment service. Please try again later')
        }
    }
    
    useEffect(() => {
        GetAPIData();
    }, [reload]);
    
    return (
        <>
        {loading && (
            <div className="m-8">
                <Loader width="100%" height="100%"/>
            </div>
        )}
        <div className={(themeCSS.divCSS)}>
            <Nav CompanyName={companyname}/>
            <HeaderBackground />
            
            <HeaderOverlay agreement={agreement} type={"Account Transactions"} accountreference={account.accountreference}/>
            <div className=" flex justify-center font-sans overflow-hidden">
                <div className={"grid grid-cols-1 mx-2 lg:mx-0 lg:grid-cols-12 lg:w-8/12 w-full sm:mx-10 mt-12 " + (themeCSS.divCSS)}>

                    <div className='grid grid-cols-1 lg:col-span-4 gap-4 mb-2'>
                        <div className='hidden sm:block'></div>
                        <button onClick={() => {handleDownload()}} className={"w-1/3 lg:w-3/5 grid grid-cols-12 h-10 pl-2 gap-2 py-2 mt-2 border-gray-500 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 " + (themeCSS.inputCSS)}>
                            <svg className="col-span-2" width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18.9999 20H5.99992C2.87429 20.0018 0.270809 17.6038 0.0162787 14.4886C-0.238251 11.3733 1.94144 8.58453 5.02592 8.07899C6.44563 5.56188 9.11003 4.0035 11.9999 3.99993C13.8019 3.99312 15.5524 4.60129 16.9619 5.72399C18.346 6.82185 19.33 8.34497 19.7619 10.058C22.3458 10.455 24.1877 12.7755 23.9879 15.3821C23.7882 17.9887 21.6141 20.0014 18.9999 20ZM11.9999 5.99998C9.83163 6.00255 7.83259 7.17209 6.76792 9.06099L6.29992 9.89999L5.35091 10.055C3.3012 10.3984 1.85592 12.2543 2.02513 14.3257C2.19433 16.3971 3.92164 17.9938 5.99992 18H18.9999C20.5685 18.0016 21.8735 16.7946 21.9941 15.2307C22.1147 13.6667 21.0102 12.2739 19.4599 12.035L18.1439 11.835L17.8219 10.543C17.1572 7.8698 14.7545 5.99495 11.9999 5.99998ZM11.9999 16L7.99992 12H10.5499V8.99999H13.4499V12H15.9999L11.9999 16Z" fill="#2E3A59"></path>
                            </svg>
                            <p className={"col-span-10 text-center " + (themeCSS.text)}>Download</p> 
                        </button>

                    </div>

                    <div className='grid grid-cols-2 sm:grid-cols-3 col-span-8 gap-1'>
                        <div>
                            <p className='text-xs text-left'>Start Date</p>
                            <input 
                                type="date"
                                id="startdate"
                                name="startdate"
                                value={startDate}
                                onChange={handleStartDateChange}
                                className={"w-full px-4 py-2 mt-2 h-10 text-gray-700 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 " + (themeCSS.inputCSS)}
                            />
                        </div>
                        <div>
                            <p className='text-xs text-left'>End Date</p>
                            <input 
                                type="date"
                                id="enddate"
                                name="enddate"
                                value={endDate}
                                onChange={handleEndDateChange}
                                className={"w-full px-4 py-2 mt-2 h-10 text-gray-700 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 " + (themeCSS.inputCSS)}
                            />
                        </div>
                        <div className='col-span-2 sm:col-span-1'>
                            <p className='text-xs text-left hidden sm:block'><br/></p>
                            <button onClick={() => {applyFilter()}} className={"w-full h-10 py-2 mt-2 border-gray-600 border rounded-md focus:border-blue-400 focus:ring-blue-300 focus:outline-none focus:ring focus:ring-opacity-40 " + (themeCSS.inputCSS)}>
                                
                                {loadingspinner && (
                                    <div className='flex justify-center'>
                                        <svg aria-hidden="true" className="mr-2 w-8 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                        </svg>
                                    </div>
                                )}
                                {!loadingspinner && (
                                    <>
                                    Apply Filter
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"min-h-screen " + (themeCSS.divCSS)}>  
                <div className="flex justify-center font-sans overflow-hidden pb-20">
                    <div className={"border rounded my-6 lg:w-8/12 w-full sm:mx-10 " + (themeCSS.divCSS)}>
                        <table className="table-auto overflow-auto w-full">
                            <thead>
                                <tr className={"uppercase text-sm leading-normal border-r-8 border-r-gray-200 " + (themeCSS.tableHeaderCSS)}>
                                    <th className="hidden lg:block py-3 pl-2 text-left">Date</th>
                                    <th className="py-3 pl-2 text-left">Description</th>
                                    <th className="py-3 pl-2 text-left">Debit</th>
                                    <th className="py-3 pl-2 text-left">Credit</th>
                                    <th className="py-3 pl-2 text-left">Balance</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm font-light">
                                {transactions && (
                                    transactions.map(function(transaction, index){
                                        return (
                                        <tr className={"border-b border-gray-200 hover:bg-gray-100" + (themeCSS.divCSS)}>
                                            <td className="hidden lg:block py-3 pl-2 text-left whitespace-wrap">
                                                <div className="flex items-center">
                                                    <span>{moment(transaction.date).format("DD/MM/YYYY")}</span>
                                                </div>
                                            </td>

                                            <td className="py-3 pl-2 text-left whitespace-wrap w-auto">
                                                <div className="flex items-center">
                                                    <dl className="font-normal lg:hidden">
                                                        <dd className="mt-1 truncate text-gray-700">{moment(transaction.date).format("DD/MM/YYYY")}</dd>
                                                        <dd className="mt-1 truncate text-gray-500 whitespace-normal">{transaction.description}</dd>
                                                    </dl>
                                                    <span className="hidden lg:block text-sm">{transaction.description}</span>
                                                    
                                                </div>
                                            </td>

                                            {transaction.type == "charge" ? (
                                                <>
                                                    <td className="py-3 pl-2 text-left whitespace-wrap">
                                                        <span className="font-medium">£{transaction.value}</span>
                                                    </td>
                                                    <td className="py-3 pl-2 text-left whitespace-wrap">
                                                        <span className="font-medium"></span>
                                                    </td>
                                                </>
                                                ):(
                                                    <>
                                                    <td className="py-3 pl-2 text-left whitespace-wrap">
                                                        <span className="font-medium"></span>
                                                    </td>
                                                    <td className="py-3 pl-2 text-left whitespace-wrap">
                                                        <span className="font-medium">£{transaction.value}</span>
                                                    </td>
                                                    </>
                                                )}

                                        {transaction.runningbalance == 0 ? (
                                        <td className="py-3 pl-2 text-left whitespace-wrap border-r-8 border-green-500">
                                            <span className="font-medium">£{transaction.runningbalance} Credit</span>
                                        </td>    
                                        ):(
                                            transaction.runningbalance.includes("-", 0) ? (
                                            <td className="py-3 pl-2 text-left whitespace-wrap border-r-8 border-green-500">
                                                <span className="font-medium">£{transaction.runningbalance} Credit</span>
                                            </td>  
                                            ):(
                                            <td className="py-3 pl-2 text-left whitespace-wrap border-r-8 border-red-500">
                                                <span className="font-medium">£{transaction.runningbalance} Arrears</span>
                                            </td> 
                                            )
                                        )}                              
                                        </tr>
                                    )})
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            {settings.payments_enabled === 1  && (
                <div className='fixed bottom-0 w-full mb-10'>
                    {settings.paymentprovider == 'url' && (
                        <a href={settings.payment_url} className="btn btn-natural sticky float-right bottom-5 right-5 p-2 rounded-lg text-white text-md w-40 capitalize">
                            <p>Make Payment</p>
                        </a>
                    )}
                    {settings.paymentprovider == 'AllPay' && (
                        <button onClick={() => {makeAllPayPayment()}}className="btn btn-natural sticky float-right bottom-5 right-5 p-2 rounded-lg text-white text-md w-40 capitalize">
                            <p>Make Payment</p>
                        </button>
                    )}
                </div>
            )}
            
            {/* PDF Export Component Below */}
            <div style={{display:'none'}}>
                <div ref={pdfRef} id="downloadheader" className="bg-white w-[640px] p-2">
                    <div className="grid grid-cols-1 pb-2 text-right text-xl">
                        <h1 className="pb-2 text-2xl font-semibold text-right text-black">{settings.company_name}</h1>
                    </div>
                        {agreement && (
                            <div className="grid grid-cols-1 text-left px-4">
                                <div className="pb-4">
                                    <div className='text-xs font-semibold pb-4 text-black'>Customer Reference:  {agreement.reference}</div>
                                    <p className='text-xs text-black'>{profile.firstname} {profile.lastname}</p>
                                    {formattedAddress && (
                                        formattedAddress.map(function(line, index){
                                            return (
                                                <p className='text-xs text-black'>
                                                    {line}
                                                </p>
                                            )
                                        })
                                    )}
                                    <p className='pb-2 text-xs text-black'>{moment().format('Do MMMM YYYY')}</p>
                                    <p className='pb-2 text-xs text-black'>Your current balance is £{account.balance}. Please note that if you are in receipt of housing benefit, this balance may include expected housing benefit - the transactions below do not.</p>

                                </div>
                            </div>  
                        )}
                </div>
            </div>
            
            <table  style={{display:'none'}} id="transactiontable" className="table-auto overflow-auto w-full">
                        <thead>
                            <tr className={" text-[10px] " + (themeCSS.tableHeaderCSS)}>
                                <th className=" pl-2 pb-2 text-left">Date</th>
                                <th className=" pl-2 pb-2 text-left">Description</th>
                                <th className=" pl-2 pb-2 text-left">Debit</th>
                                <th className=" pl-2 pb-2 text-left">Credit</th>
                                <th className=" pl-2 pb-2 text-left">Balance</th>
                            </tr>
                        </thead>
                        <tbody className="text-gray-600 text-sm font-light">
                            {transactions && (
                                transactions.map(function(transaction, index){
                                    return (
                                    <tr className={"border-b border-gray-200 hover:bg-gray-100" + (themeCSS.divCSS)}>
                                        <td className="pb-2 pl-2 text-left whitespace-wrap">
                                            <div className="flex items-center text-[10px]">
                                                <span>{moment(transaction.date).format("DD/MM/YYYY")}</span>
                                            </div>
                                        </td>

                                        <td className="pb-2 pl-2 text-left whitespace-wrap w-auto">
                                            <div className="flex items-center">
                                                <span className="text-[10px]">{transaction.description}</span>
                                            </div>
                                        </td>

                                        {transaction.type == "charge" ? (
                                            <>
                                                <td className="pb-2 pl-2 text-left whitespace-wrap">
                                                    <span className="text-[10px]">£{transaction.value}</span>
                                                </td>
                                                <td className="pb-2 pl-2 text-left whitespace-wrap">
                                                    <span className="text-[10px]"></span>
                                                </td>
                                            </>
                                            ):(
                                                <>
                                                <td className="pb-2 pl-2 text-left whitespace-wrap">
                                                    <span className="text-[10px]"></span>
                                                </td>
                                                <td className="pb-2 pl-2 text-left whitespace-wrap">
                                                    <span className="text-[10px]">£{transaction.value}</span>
                                                </td>
                                                </>
                                            )}

                                        {transaction.runningbalance == 0 ? (
                                        <td className="pb-2 pl-2 text-left whitespace-wrap">
                                            <span className="text-[10px]">£{transaction.runningbalance} Credit</span>
                                        </td>  
                                        ):(
                                            transaction.runningbalance.includes("-", 0) ? (
                                            <td className="pb-2 pl-2 text-left whitespace-wrap">
                                                <span className="text-[10px]">£{transaction.runningbalance} Credit</span>
                                            </td>  
                                            ):(
                                            <td className="pb-2 pl-2 text-left whitespace-wrap ">
                                                <span className="text-[10px]">£{transaction.runningbalance} Arrears</span>
                                            </td> 
                                            ) 
                                        )}                             
                                    </tr>
                                )})
                            )}
                        </tbody>
                    </table>
                {/* END OF PDF Export Component */}
        </div> 
        {accounts && (
            <AccountSwitcher modalState={accountSelectModal} titleText={'Please select an account'}>
            {agreement && agreement.accounts.map((account) => (
                    <div    
                        onClick={() => {setSelectedAccount(account)}}
                        key={account.id}
                        value={account}
                        className='flex cursor-default select-none rounded-xl p-3 hover:bg-gray-100'
                    >
                    <div className="flex-auto">
                    <p className='text-sm font-medium text-gray-900'>
                            {account.type} <span className="">(Account Reference    : {account.accountreference})</span>
                        </p>
                        <p className='text-sm text-gray-700'>
                            {agreement.address}
                        </p>
                    </div>
                    {account.direction == 'CR' ? (
                        <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-600 w-28'>  
                            <p className={'text-white text-lg'}>£{account.balance}</p>
                        </div>
                    ):(
                        <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-red-400 w-28'>  
                            <p className={'text-white text-lg'}>£{account.balance}</p>
                        </div> 
                    )}
                </div>
                ))} 
            </AccountSwitcher>
        )}
        </>
    );
    
}

