import {useContext, useState, useEffect} from 'react';
import GlobalContext from "../context/GlobalContext.js";
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import Footer from '../components/footer.js';
import HeaderBackground from '../components/headerbackground.js'
import HomePageCard from '../components/HomePageCard.js';
import HomePageAppointmentsCard from '../components/HomePageAppointmentsCard.js';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import HomePageWalkthrough from '../components/homepageWalkThrough.js';
import AllPayDirectDebitCard from '../components/AllPayDirectDebitCard.js';
import { ToastContainer, toast } from 'react-toastify';
import AgreementSwitcher from '../components/AgreementSwitcher.js';
import AccountSwitcher from '../components/AccountSwitcher.js';
import { DocumentTextIcon, HomeIcon } from '@heroicons/react/20/solid'


export default function Home() {
    const [agreement, setAgreement] = useState('');
    const [agreements, setAgreements] = useState('');
    const [selectedAgreement, setSelectedAgreement] = useState();
    const [modalIsOpen, setIsOpen] = useState(false);
    const [loading, isLoading] = useState(true)
    const {theme, themeCSS, settings} = useContext(GlobalContext)
    const [userDetails, setUserDetails] = useState();
    const [accountModalIsOpen, setAccountModalIsOpen] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState('');
    const [accountModalFunction, setAccountModalFunction] = useState();
    const [triggerAllPayDD, setTriggerAllPayDD] = useState();

    function switchAccount(agreementreference) {
        isLoading(true);
        setSelectedAgreement(agreementreference);
        localStorage.setItem('agreement', agreementreference);
        setIsOpen(false);
    }

    //Function only used if the payment provider is AllPay
    async function makeAllPayPayment(accountreference){
        isLoading(true);
        const body = {reference: agreement.reference}
        let PaymentProviderDetail = await postAPI('makepayment', body);
        let response = PaymentProviderDetail.response;

        if(response.ok){
            window.location.href = '/allpaypayment/'+accountreference+"/"+PaymentProviderDetail.data.Token+"/"+encodeURIComponent(settings.allpay_gatewayurl)+"/"+PaymentProviderDetail.data.ClientCode+"/web";
        }else{
            toast.error('There was an error connecting to the payment service. Please try again later')
        }
    }

    async function GetAPIData() {
        const AgreementsResponse = await getAPI('agreements');
        if (AgreementsResponse.response.ok) {
            const agreementreference = localStorage.getItem('agreement');

            if(!agreementreference) {
                let array = AgreementsResponse.data;
                let finalAgreements = [];
                array.filter(item => item.type === "Residential").forEach(element => {finalAgreements.push(element);});
                array.filter(item => item.type !== "Residential").forEach(element => {finalAgreements.push(element);});
                setAgreement(finalAgreements[0])
                localStorage.setItem('agreement', finalAgreements[0].reference);
                setSelectedAccount(finalAgreements[0].accounts[0])
            } else {
                const AgreementResponse = await getAPI('agreements/'+agreementreference);
                if (AgreementResponse.response.ok) {
                    setAgreement(AgreementResponse.data);
                    setSelectedAccount(AgreementResponse.data.accounts[0])
                } else{
                    localStorage.removeItem('agreement');
                    GetAPIData();
                }

            }
                let index = AgreementsResponse.data.findIndex(obj => obj.type === 'Residential');
                if (index !== -1) {
                    let removedItem = AgreementsResponse.data.splice(index, 1)[0];
                    AgreementsResponse.data.unshift(removedItem);
                }
            setAgreements(AgreementsResponse.data);
        }   
        isLoading(false)
    }

    async function GetUserDetails() {
        const UserDetailsResponse = await getAPI('userdetails/summary');
        if (UserDetailsResponse.response.ok) {
            setUserDetails(UserDetailsResponse.data);
        }
    }

    const HeaderOverlay = () => {
        return(
            <>
                {agreements&& agreement && ( 
                    <div className={`${agreement.accounts.length > 1 ? 'mb-4 mt-20 md:mt-8' : 'mb-2 mt-16 md:mt-8'}`}>
                        <div className={`w-11/12 lg:w-8/12 border rounded-md absolute left-1/2 -translate-x-1/2 -translate-y-1/2`}>
                            <section aria-labelledby="">
                                <div className="overflow-hidden rounded bg-white">
                                    <div className="">
                                        {/* Your content */}
                                        <div className={"overflow-hidden " + (themeCSS.divCSS)}>
                                            <div className="py-4 pr-4">
                                                <div className="sm:flex sm:items-center sm:justify-between px-4">
                                                    <div className="sm:flex sm:space-x-5">
                                                        <div className="flex-shrink-0">
                                                        </div>
                                                        <div className="text-left sm:mt-0 sm:pt-1 sm:text-left">
                                                            <p className="text-xl font-bold sm:text-2xl">{agreement.address}</p>
                                                            <p className="text-sm text-left font-medium">Agreement Reference: {agreement.reference}</p>
                                                        </div>
                                                    </div>
                                                    {agreements.length > 1 && (
                                                        <div className="mt-5 flex sm:mt-0">
                                                            <a
                                                            href="#"
                                                            className="flex rounded-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300"
                                                            onClick={() => {setIsOpen(true)}}
                                                            >
                                                                Switch Agreement
                                                            </a>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-1 divide-y divide-gray-200 border-t border-gray-200 sm:grid-cols-3 sm:divide-x sm:divide-y-0">
                                                {agreement.accounts.map((account) => (
                                                <div key={account.id} className="px-6 py-2 text-center text-sm font-medium">
                                                    <span className="">{account.type} Balance: </span>
                                                    {account.direction == "CR" && (
                                                        <div className='text-lg'>
                                                            <span className="text-gray-900 text-green-800">£{account.balance}</span> In Credit
                                                        </div>
                                                    )}
                                                    {account.direction == "AR" && (
                                                        <div className='text-lg'>
                                                            <span className="text-gray-900 text-red-800">£{account.balance}</span> In Arrears
                                                        </div>
                                                    )}
                                                </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                )}
            </>
        );
    };

    useEffect(() => {
        GetAPIData();
        GetUserDetails();
    }, [selectedAgreement]);
    
    return (
        <div id="homepage-demo" className="">
            {!loading && userDetails &&(
                <HomePageWalkthrough userDetails={userDetails}/>
            )}
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
            <Nav />
            <HeaderBackground/>
            <HeaderOverlay/> 
            {settings && agreement && (
            <div className={"min-h-screen pt-0 sm:pt-2 pb-8 " + (themeCSS.divCSS)}>  
                <div className={"flex justify-center " + (themeCSS.divCSS)}>
                    <div className={`w-11/12 lg:w-8/12 grid grid-cols-1 first-letter md:grid-cols-2 lg:grid-cols-3 gap-4 ${agreement.accounts.length <= 3 ? 'mt-[140px] sm:mt-20 lg:mt-20' : 'mt-[300px] lg:mt-40'}`}>
                        {userDetails && (
                            <div id="messages-demo">
                                <HomePageCard title="Messages" body="View your messages and keep up-to-date with important information." buttontext="View Messages" url={'/messages'} badgecount={userDetails.unread_messages}/>
                            </div>
                        )}
                        {settings.repairs_enabled === 1  && (
                            <>
                            <div id="appointments-demo">
                                <HomePageAppointmentsCard/>
                            </div>
                            <div id="repairs-demo">
                                <HomePageCard title={settings.repairs_header} body={settings.repairs_body} buttontext={settings.repairs_button} url={'/repairs'}/>
                            </div>
                            </>
                        )}
                        {settings.requests_enabled === 1  && (
                            <div id="requests-demo">
                            <HomePageCard title={settings.request_header} body={settings.request_body} buttontext={settings.request_button} url={'/requests'}/>
                            </div>
                        )}
                        {settings.transactions_enabled === 1  && (
                            <div id="transactions-demo">
                            <HomePageCard title={settings.account_transaction_header} body={settings.account_transaction_body} buttontext={settings.account_transaction_button} url={'/transactions'}/>
                            </div>
                        )}
                        {settings.payments_enabled === 1  && (
                            <>
                                <div id="payment-demo">
                                    {settings.paymentprovider == 'url' && 
                                        <HomePageCard title={settings.payment_header} body={settings.payment_body} buttontext={settings.payment_button} url={settings.payment_url}/>
                                    }  
                                    {settings.paymentprovider == 'AllPay' && 
                                        agreement && 
                                        <div className="h-full rounded-md text-center border flex flex-col">
                                            <div className="flex-grow p-6">
                                                <h2 className="text-xl font-bold mb-2">{settings.payment_header}</h2> 
                                                <p className="">{settings.payment_body}</p> 
                                            </div>
                                            <div className="p-4 mt-auto">
                                                <button 
                                                    className={"btn btn-natural capitalize " + (themeCSS.buttonCSS)}
                                                    onClick={() => { 
                                                        if (agreement.accounts.length > 1) {
                                                            setAccountModalIsOpen(true);
                                                            setAccountModalFunction('Payment');
                                                        } else {
                                                            makeAllPayPayment(agreement.accounts[0].accountreference);
                                                        }
                                                    }}
                                                >
                                                    {settings.payment_button}
                                                </button>
                                            </div>
                                        </div>
                                    }                                
                                </div>
                            </>
                        )}
                        {settings.directdebit_enabled === 1 && (
                            <>
                                <div id="direct-debit-demo">
                                    {agreement && selectedAccount && settings.directdebit_provider == "AllPay" && (
                                        <AllPayDirectDebitCard agreement={agreement} accountreference={selectedAccount.accountreference} modalState={setAccountModalIsOpen} accountModalFunction={setAccountModalFunction} shouldTriggerDDSetup={triggerAllPayDD}/>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            )}
            <Footer />
            <AgreementSwitcher modalState={modalIsOpen} setModalState={setIsOpen} titleText={'Please select an agreement'}>
                {agreements && (
                    agreements.map(function(agreement, index){
                        return (
                            <div
                                onClick={() => {switchAccount(agreement.reference)}}
                                key={agreement.id}
                                value={agreement}
                                className="flex cursor-default select-none rounded-xl p-3"
                            > 
                                {agreement.type == "Residential" ? (
                                    <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-indigo-500'>  
                                        <HomeIcon className='h-6 w-6 flex-none text-white'/>
                                    </div>
                                ):(
                                    <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-teal-500 '>  
                                        <DocumentTextIcon className='h-6 w-6 flex-none text-white'/>
                                    </div>
                                )}
                                
                                <div className="ml-4 flex-auto">
                                    <p className='text-sm font-medium'>
                                        {agreement.address}
                                    </p>
                                        {agreement.type == "Expired" && (
                                            <span className="text-red-500 font-bold">Former Account </span>
                                        )}
                                        Agreement Reference: {agreement.reference}
                                </div>
                            </div>
                        )
                    }) 
                )}
                
            </AgreementSwitcher>
            <AccountSwitcher modalState={accountModalIsOpen} setModalState={setAccountModalIsOpen} titleText={'Please select an account'}>
            {agreement && agreement.accounts.map((account) => (
                    <div    
                        onClick={() => {
                            setSelectedAccount(account);
                            if (settings.paymentprovider == 'AllPay' && accountModalFunction == 'Payment') {
                                makeAllPayPayment(account.accountreference);
                            } else if (settings.paymentprovider == 'AllPay' && accountModalFunction == 'Direct Debit'){
                                setTriggerAllPayDD(true);
                            }
                        }}
                        key={account.id}
                        value={account}
                        className='flex cursor-default select-none rounded-xl p-3 hover:bg-gray-100'
                    >
                    <div className="flex-auto">
                        <p className='text-sm font-medium text-gray-900'>
                            {account.type} <span className="">(Account Reference    : {account.accountreference})</span>
                        </p>
                        <p className='text-sm text-gray-700'>
                            {agreement.address}
                        </p>
                    </div>
                    {account.direction == 'CR' ? (
                        <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-green-600 w-28'>  
                            <div>
                                <p className={'text-white flex text-lg'}>
                                    £{account.balance}  
                                </p>
                            </div>
                        </div>
                    ):(
                        <div className='flex h-10 w-10 flex-none items-center justify-center rounded-lg bg-red-400 w-28'>  
                            <p className={'text-white text-lg'}>£{account.balance}</p>
                        </div> 
                    )}
                </div> 
                ))} 
            </AccountSwitcher>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div> 
    );
    
}

