import {React, useEffect, useState} from 'react'

export default function PasswordStrengthChecker({password, passwordSecure}) {

  const [passwordComplexity, setpasswordComplexity] = useState({length: false, lowercase: false, uppercase: false, specialchar: false, number: false, strength: false});

  function PasswordStrengthChecker(PasswordParameter){
    if(PasswordParameter == undefined) PasswordParameter = ""
    // At least one digit
    // At least one lowercase character
    // At least one uppercase character
    // At least one special character
    // At least 8 characters in length.
    const length = new RegExp('(?=.{8,})')
    const lowercase = new RegExp('(?=.*[a-z])')
    const uppercase = new RegExp('(?=.*[A-Z])')
    const specialchar = new RegExp('(?=.*[^A-Za-z0-9])')
    const number = new RegExp('(?=.*[0-9])')
    const strength = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
    let testedlength = false;
    let testedlowercase = false;
    let testeduppercase = false; 
    let testedspecialcar = false; 
    let testednumber = false; 
    let testedstrength = false;

    if(length.test(PasswordParameter)) {
      testedlength = true
    }else{
      testedlength = false
    }
    
    if(lowercase.test(PasswordParameter)) {
      testedlowercase = true
    }else{
      testedlowercase = false
    }

    if(uppercase.test(PasswordParameter)) {
      testeduppercase = true
    }else{
      testeduppercase = false
    }
    
    if(specialchar.test(PasswordParameter)) {
      testedspecialcar = true
    }else{
      testedspecialcar = false
    }

    if(number.test(PasswordParameter)) {
      testednumber = true
    }else{
      testednumber = false
    }
    
    if(strength.test(PasswordParameter)) {
      testedstrength = true
      passwordSecure(true);
    }else{
      passwordSecure(false)
      testedstrength = false
    }
    setpasswordComplexity({...passwordComplexity, length: testedlength, lowercase: testedlowercase, uppercase: testeduppercase, specialchar: testedspecialcar, number: testednumber, strength: testedstrength});
  } 

  useEffect(() => {
    PasswordStrengthChecker(password)
  }, [password])
  

  return (
    <div>
      <p className="text-left text-xs font-bold text-gray-600 pt-2">Minimum password requirements:</p>

      {passwordComplexity.uppercase == false &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#94a3b8"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-gray-400">Must contain at least 1 uppercase letter</p>
          </div>
        </div>
      }
      {passwordComplexity.uppercase == true &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-green-600">Must contain at least 1 uppercase letter</p>
          </div>
        </div>
      }

      {passwordComplexity.lowercase == false &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#94a3b8"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-gray-400">Must contain at least 1 lowercase letter</p>
          </div>
        </div>
      }
      {passwordComplexity.lowercase == true &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-green-600">Must contain at least 1 lowercase letter</p>
          </div>
        </div>
      }

      {passwordComplexity.number == false &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#94a3b8"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-gray-400">Must contain at least 1 number</p>
          </div>
        </div>
      }
      {passwordComplexity.number == true &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-green-600">Must contain at least 1 number</p>
          </div>
        </div>
      }

      {passwordComplexity.specialchar == false &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#94a3b8"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-gray-400">Must contain at least 1 special character</p>
          </div>
        </div>
      }
      {passwordComplexity.specialchar == true &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-green-600">Must contain at least 1 special character</p>
          </div>
        </div>
      }

      {passwordComplexity.length == false &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#94a3b8"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-gray-400">Must contain at least 8 characters</p>
          </div>
        </div>
      }
      {passwordComplexity.length == true &&
        <div className="grid grid-cols-12">
          <div className="col-span-1 grid content-center">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
            </svg>
          </div>
          <div className="col-span-8 grid content-center">
            <p className="text-left text-xs italic text-green-600">Must contain at least 8 characters</p>
          </div>
        </div>
      }

    </div>
  )
}
