import {useState, useContext, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import HeaderBackground from '../components/headerbackground.js'
import getAPI from '../functions/API/getAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";
import moment from 'moment';
import { Fragment } from 'react'
import { ArrowDownCircleIcon, ArrowPathIcon, ArrowUpCircleIcon } from '@heroicons/react/20/solid'

export default function Appointments() {

    const [loading, isLoading] = useState(true)
    const [reload, setReload] = useState(false);
    const {theme, updateTheme, themeCSS} = useContext(GlobalContext)
    const [pastAppointments, setPastAppointments] = useState([]);
    const [futureAppointments, setFutureAppointments] = useState([]);


    async function GetAppointments() {
        const AppoinmentReponse = await getAPI('repairsappointments')
        if (AppoinmentReponse.response.ok && AppoinmentReponse.data.length > 0) {

            let filteredPastAppointments = AppoinmentReponse.data.filter(appointment => {
                let CurrentDate = moment().format('YYYY-MM-DD');
                return moment(appointment.appointmentdate).format("YYYY-MM-DD") < CurrentDate
            }).sort((a, b) => a.appointmentdate - b.appointmentdate);
    
            let filteredFutureAppointments = AppoinmentReponse.data.filter(appointment => {
                let CurrentDate = moment().format('YYYY-MM-DD');
                return moment(appointment.appointmentdate).format("YYYY-MM-DD") >= CurrentDate && !appointment.is_complete
            }).sort((a, b) => a.appointmentdate - b.appointmentdate).reverse();
            
            setPastAppointments(filteredPastAppointments)
            setFutureAppointments(filteredFutureAppointments);
        }
        isLoading(false);
    }

    function HeaderOverlay() {
        return (
            <>
                <div className="w-11/12 lg:w-8/12 rounded-md border stats absolute top-46 left-1/2 -translate-x-1/2 -translate-y-1/2">
                    <div className={"py-4 grid grid-cols-1 lg:grid-cols-12 stat place-items-center place-content-ceter " + (themeCSS.divCSS)}>
                        <div className="col-span-12 lg:col-span-12 place-items-center place-content-center">
                            <div className="text-xl">
                                <strong>Your appointments</strong>
                            </div> 
                        </div> 
                    </div>
                </div>
            </>
        )
    }

    function AppointmentTable() {
        return (
            <div className='mt-12 p-0 lg:mx-[180px] pb-24'>
                <div className="overflow-hidden border-t border-gray-100 rounded-t-md border rounded-md ">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                        <table className="w-full text-left">
                        <thead className="sr-only">
                            <tr>
                            <th>Amount</th>
                            <th className="hidden sm:table-cell">Client</th>
                            <th>More details</th>
                            </tr>
                        </thead>
                        {futureAppointments.length > 0 ? (
                            <tbody>
                                <tr className="text-sm leading-6">
                                <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                    <div className="text-gray-900">Upcoming Appointments</div>
                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                </th>
                                </tr>
                                {futureAppointments.map((appointment, index) => (
                                    <>
                                        <tr key={index}>
                                                <td className="relative py-5 pr-6 w-5/12">
                                                    <div className="flex gap-x-6">
                                                        <div className="flex-auto max-w-[450px]">
                                                            <div className="flex items-start gap-x-3">
                                                                <div className="text-sm font-medium leading-6">{moment(appointment.appointmentdate).format('dddd Do MMMM')}</div>
                                                            </div>
                                                            <div className="mt-1 text-xs leading-5">{appointment.starttime} - {appointment.endtime}</div>
                                                            <div className="mt-1 text-sm leading-5 text-wrap">{appointment.repairdescription}</div>
                                                        </div>
                                                    </div>
                                                    <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                    <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                                </td>
                                                <td className="py-5 text-right w-5/12 nline-block align-top">
                                                    {appointment.is_complete ? (
                                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ">
                                                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                                            <circle r={3} cx={3} cy={3} />
                                                            </svg>
                                                            {appointment.statusdescription}
                                                        </span>
                                                    ):(
                                                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700">
                                                            <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-orange-500">
                                                            <circle r={3} cx={3} cy={3} />
                                                            </svg>
                                                            {appointment.statusdescription}
                                                        </span>
                                                    )}
                                                    <div className="mt-1 text-xs leading-5">
                                                        Repair Reference <span className="">#{appointment.repairid}</span>
                                                    </div>
                                                </td>
                                        </tr>
                                    </>
                                ))}
                            </tbody>
                        ):(
                            <tbody>
                                <tr className="text-sm leading-6">
                                <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                    <div className="text-gray-900">Upcoming Appointments</div>
                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                </th>
                                </tr>
                                <tr >
                                    <td className="relative py-5 pr-6">
                                        <div className="text-left">You have no upcoming appointments</div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        {pastAppointments.length > 0 ? (
                            <tbody>
                                <tr className="text-sm leading-6">
                                <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                    <div className="text-gray-900">Past Appointments</div>
                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                </th>
                                </tr>
                                {pastAppointments.map((appointment, index) => (
                                <>
                                    <tr key={index}>
                                            <td className="relative py-5 pr-6 w-5/12">
                                                <div className="flex gap-x-6">
                                                    <div className="flex-auto max-w-[450px]">
                                                        <div className="flex items-start gap-x-3">
                                                            <div className="text-sm font-medium leading-6">{moment(appointment.appointmentdate).format('dddd Do MMMM')}</div>
                                                        </div>
                                                        <div className="mt-1 text-xs leading-5">{appointment.starttime} - {appointment.endtime}</div>
                                                        <div className="mt-1 text-sm leading-5 text-wrap">{appointment.repairdescription}</div>
                                                    </div>
                                                </div>
                                                <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                                                <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                                            </td>
                                            <td className="py-5 text-right w-5/12 nline-block align-top">
                                                {appointment.is_complete ? (
                                                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700 ">
                                                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-green-500">
                                                        <circle r={3} cx={3} cy={3} />
                                                        </svg>
                                                        {appointment.statusdescription}
                                                    </span>
                                                ):(
                                                    <span className="inline-flex items-center gap-x-1.5 rounded-md bg-orange-100 px-2 py-1 text-xs font-medium text-orange-700">
                                                        <svg viewBox="0 0 6 6" aria-hidden="true" className="h-1.5 w-1.5 fill-orange-500">
                                                        <circle r={3} cx={3} cy={3} />
                                                        </svg>
                                                        {appointment.statusdescription}
                                                    </span>
                                                )}
                                                <div className="mt-1 text-xs leading-5">
                                                    Repair Reference <span className="">#{appointment.repairid}</span>
                                                </div>
                                            </td>
                                    </tr>
                                </>
                                ))}
                            </tbody>
                        ):(
                            <tbody>
                                <tr className="text-sm leading-6">
                                <th scope="colgroup" colSpan={3} className="relative isolate py-2 font-semibold">
                                    <div >Past Appointments</div>
                                    <div className="absolute inset-y-0 right-full -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                    <div className="absolute inset-y-0 left-0 -z-10 w-screen border-b border-gray-200 bg-gray-50" />
                                </th>
                                </tr>
                                <tr >
                                    <td className="relative py-5 pr-6 w-full">
                                        <div className="text-left">You have no past appointments</div>
                                    </td>
                                </tr>
                            </tbody>
                        )}
                        </table>
                    </div>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {
        GetAppointments();
        }, [reload]);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
        
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
                <Nav />
                <HeaderBackground />
                <HeaderOverlay />
                <AppointmentTable />
            </div> 
        </>
    );
    
}

