import './App.css';
import { Routes, Route, Link, Navigate } from "react-router-dom";
import {react, useState, useEffect} from 'react';
import {light, dark, highContrast} from './functions/theme/themes.js';
import GlobalContext from './context/GlobalContext.js'
import Home from './pages/home.js';
import Login from './pages/login.js';
import R404 from './pages/R404.js';
import Register from './pages/register.js';
import RequestResetPassword from './pages/requestresetpasssword.js';
import ResetPassword from './pages/resetpassword.js';
import Repairs from './pages/repairs.js';
import Transactions from './pages/transactions.js'
import Requests from './pages/requests.js'
import Payment from './pages/payment.js'
import AllPayMobileAppDirectDebit from './pages/AllPayMobileAppDirectDebit';
import AllPayDDResult from './pages/allpayddresult';
import Profile from './pages/profile.js'
import Admin from './pages/admin.js'
import AdminUsers from './pages/adminusers.js'
import Customers from './pages/customers.js'
import Settings from './pages/settings.js'
import AdvancedSettings from './pages/advancedsettings.js'
import Integrations from './pages/Integrations.js'
import PaymentSettings from './pages/paymentsettings'
import Categories from './pages/categories.js'
import RequestTypes from './pages/requesttypes.js'
import RepairDefinition from './pages/repairdefinitions.js'
import RepairGroups from './pages/repairgroups.js'
import ExtendedVerification from './pages/extendedverification';
import Appointments from './pages/appointments';
import Messages from './pages/messages';
import ContactMethodMappings from './pages/contactmethodmappings';
import Reports from './pages/reports.js'
import MessageCenter from './pages/messagecenter';
import TestSystemBanner from './components/testsystembanner';
import getAPI from './functions/API/getAPI';
import Loader from './components/loader.js'

export default function App() {

  const [loading, isLoading] = useState(true);
  const [runWalkthrough, setRunWalkthrough] = useState(false);

  //Set theme state and persist selection between session
  const [theme, setTheme] = useState(() => {
    let SelectedTheme = localStorage.getItem('theme');
    if(SelectedTheme){
      return SelectedTheme
    } else {
      localStorage.setItem('theme', 'light');
      return 'light'
    }
  });

  const [settings, setSettings] = useState(
    async function () {
      if (localStorage.getItem("apiToken")) {
        const PublicSettingsResponse = await getAPI('settings');
        setSettings(PublicSettingsResponse.data)
        document.title = PublicSettingsResponse.data.company_name
      } else {
        const PublicSettingsResponse = await getAPI('settings',true);
        setSettings(PublicSettingsResponse.data)
        document.title = PublicSettingsResponse.data.company_name
      }
      isLoading(false)
    }
  );

  const [publicSettings, setPublicSettings] = useState(
    async function () {
      const PublicSettingsResponse = await getAPI('settings',true);
        setPublicSettings(PublicSettingsResponse.data)
    }
  );

  

  //Set themeCSS state and use theme 
  const [themeCSS, setThemeCSS] = useState(() => {
    if(theme === 'light'){
        return light
    } else if(theme === 'dark'){
        return dark
    } else if(theme === 'highContrast'){
        return highContrast
    }
  })

  function updateTheme(selectedtheme){
    setTheme(selectedtheme) 
    localStorage.setItem('theme', selectedtheme);
    if(selectedtheme === 'light'){
      setThemeCSS(light)
    } else if(selectedtheme === 'dark'){
      setThemeCSS(dark)
    } else if(selectedtheme === 'highContrast'){
      setThemeCSS(highContrast)
    }
  }

  function CheckColourScheme(){
    //Only force dark mode if the OS is in dark mode or browser has Dark mode enabled
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      if(!localStorage.getItem('theme')){
        setTheme(dark)
        setThemeCSS(dark)
      }
    }
  }
  
  function RequireAuth({ children, redirectTo }) {
    let apiToken = localStorage.getItem("apiToken");
    let role = localStorage.getItem("role");
    if(role == "admin") return <Navigate to="/admin" />
    return apiToken ? children : <Navigate to={redirectTo} />;
  }

  function LoggedInRedirect({ children, redirectTo }) {
    let apiToken = localStorage.getItem("apiToken");
    return apiToken ? <Navigate to={redirectTo} /> : children;
  }

  function AdminRoute({ children, redirectTo }) {
    let role = localStorage.getItem("role");
    return role == 'admin' ?  children : <Navigate to={redirectTo} />;
  }

  useEffect(() => {
    CheckColourScheme();
  }, []);

  return (
    <>
    {/* {publicSettings.company_name} */}
      {loading ?
        <div className="App">
            {/* <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/> */}
        </div>
      :
      settings && (
        <div>
          <GlobalContext.Provider value={{theme, updateTheme, themeCSS, settings, publicSettings, runWalkthrough, setRunWalkthrough}} >
            <Routes>

              <Route path="/" element={
                <RequireAuth redirectTo="/login">
                  <Home />
                </RequireAuth>
              }/>
              
              <Route path="/login" element={
                <LoggedInRedirect redirectTo="/home">
                  <Login />
                </LoggedInRedirect>
              }/>

              <Route path="/extendedverification" element={
                <RequireAuth redirectTo="/home">
                  <ExtendedVerification />
                </RequireAuth>
              }/>

              <Route path="/home" element={
                <RequireAuth redirectTo="/login">
                  <Home />
                </RequireAuth>
              }/>
              
              <Route path="/profile" element={
                <RequireAuth redirectTo="/login">
                  <Profile />
                </RequireAuth>
              }/> 

              {settings.repairs_enabled === 1 && (
                  <Route path="/repairs" element={
                    <RequireAuth redirectTo="/login">
                      <Repairs />
                    </RequireAuth>
                  }/>
                )
              }

              {settings.repairs_enabled === 1 && (
                  <Route path="/appointments" element={
                    <RequireAuth redirectTo="/login">
                      <Appointments />
                    </RequireAuth>
                  }/>
                )
              }

              {settings.transactions_enabled === 1 && (
                  <Route path="/transactions" exact element={
                    <RequireAuth redirectTo="/login">
                      <Transactions />
                    </RequireAuth>
                  }/>
                )
              }

              {settings.requests_enabled === 1 && (
                <Route path="/requests" exact element={
                  <RequireAuth redirectTo="/login">
                    <Requests />
                  </RequireAuth>
                }/>
                )
              }

              <Route path="/messages" exact element={
                <RequireAuth redirectTo="/login">
                  <Messages />
                </RequireAuth>
              }/>
              )
              

              {settings.registration_enabled && (
                <Route path="/register" element={
                <LoggedInRedirect redirectTo="/home">
                  <Register />
                </LoggedInRedirect>
              }/>
              )}
              

              <Route path="/request-password-reset" element={
                <LoggedInRedirect redirectTo="/home">
                  <RequestResetPassword />
                </LoggedInRedirect>
              }/>

              <Route path="/reset-password/:token" element={
                <LoggedInRedirect redirectTo="/home">
                  <ResetPassword />
                </LoggedInRedirect>
              }/>

              <Route path="/admin" element={
                <AdminRoute redirectTo="/home">
                  <Admin />
                </AdminRoute>
              }/>

              <Route path="/adminusers" element={
                <AdminRoute redirectTo="/home">
                  <AdminUsers />
                </AdminRoute>
              }/>

              <Route path="/customers" element={
                <AdminRoute redirectTo="/home">
                  <Customers />
                </AdminRoute>
              }/>

              <Route path="/requesttypes" element={
                <AdminRoute redirectTo="/home">
                  <RequestTypes />
                </AdminRoute>
              }/>

              <Route path="/categories" element={
                <AdminRoute redirectTo="/home">
                  <Categories />
                </AdminRoute>
              }/>

              <Route path="/repairdefinitions" element={
                <AdminRoute redirectTo="/home">
                  <RepairDefinition />
                </AdminRoute>
              }/>

              <Route path="/integrations" element={
                <AdminRoute redirectTo="/home">
                  <Integrations />
                </AdminRoute>
              }/>

              <Route path="/paymentsettings" element={
                <AdminRoute redirectTo="/home">
                  <PaymentSettings />
                </AdminRoute>
              }/>

              <Route path="/settings" element={
                <AdminRoute redirectTo="/home">
                  <Settings />
                </AdminRoute>
              }/>

              <Route path="/advancedsettings" element={
                <AdminRoute redirectTo="/home">
                  <AdvancedSettings />
                </AdminRoute>
              }/>

              <Route path="/contactmethods" element={
                <AdminRoute redirectTo="/home">
                  <ContactMethodMappings />
                </AdminRoute>
              }/>

              <Route path="/repair-groups" element={
                <AdminRoute redirectTo="/home">
                  <RepairGroups />
                </AdminRoute>
              }/>

              <Route path="/reports" element={
                <AdminRoute redirectTo="/home">
                  <Reports />
                </AdminRoute>
              }/>

              <Route path="/message-center" element={
                <AdminRoute redirectTo="/home">
                  <MessageCenter />
                </AdminRoute>
              }/>

              <Route path="/allpaypayment/:accountreference/:token/:gatewayurl/:clientcode/:source" element={
                  <Payment />
              }/>

              <Route path="/allpaydirectdebit/create" element={
                  <AllPayMobileAppDirectDebit />
              }/>

              <Route path="/allpayddresult/:result" exact element={    
                  <AllPayDDResult />
              }/>
              
              {!isLoading && <Route path="*" element={<R404 />} />}
              
            </Routes>
          </GlobalContext.Provider >
        </div>
      )}
    
    </>
);

}
