import {useState, useContext, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/loader.js'
import HeaderBackground from '../components/headerbackground.js'
import GlobalContext from "../context/GlobalContext.js";


export default function AllPayDDResult() {
    const [loading, isLoading] = useState(false)
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)
    const { result } = useParams();

    function Logo() {
        if (settings.logo_url) {
            return (
                <a href='/' className="normal-case text-xl">
                    <img 
                    src={settings.logo_url}
                    alt="logo"
                    width="140"
                    className="mx-auto"
                    />
                </a>
            );
        } else {
            return (
                <a href='/' className="normal-case text-xl">{settings.company_name}</a>
            );
        }
    }

    function closeModalForReactNative(){
        window.ReactNativeWebView.postMessage("Close Modal")
    }
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
        
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
            
            {/* <Nav /> */}
                <div className={"navbar bg-base-100 " + (themeCSS.divCSS)}>
                    <div className="navbar-start">
                            <div id="nav-demo" className="dropdown">
                                <ul tabIndex="0" className={"menu text-black menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 " + (themeCSS.divCSS)}>
                                    <li className={(themeCSS.divCSS)}><a href="/">Home</a></li>
                                </ul>
                                <label tabIndex="0" className="btn btn-ghost btn-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                                </label>
                            </div>
                
                    </div>
                    <div id="logo-demo" className={"navbar-center pb-2 text-black" + (themeCSS.divCSS)}>
                        <Logo/>
                    </div>
                    <div className="navbar-end">
                    </div>
                </div>
            <div className={"text-2xl py-2 text-black text-center " + (themeCSS.divCSS)}>Direct Debit</div>

                <>
                {result == 'success' && 
                    <div className="flex flex-col items-center">
                    <div className="text-xl text-green-600 font-bold">Your Direct Debit request has been sent successfully</div>
                        <div className="flex justify-center pt-4">
                        <svg width="150" height="150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
                            </svg>
                        </div>
                        <a href="/" onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                    </div>
                }
                {result == 'existing' && 
                    <div className="flex flex-col items-center">
                        <div className="text-xl text-green-600 font-bold">Your account already has an active Direct Debit</div>
                        <div className="flex justify-center pt-4">
                        <svg width="150" height="150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C21.9939 17.5203 17.5203 21.9939 12 22ZM11.984 20H12C16.4167 19.9956 19.9942 16.4127 19.992 11.996C19.9898 7.57929 16.4087 4 11.992 4C7.57528 4 3.99421 7.57929 3.992 11.996C3.98979 16.4127 7.56729 19.9956 11.984 20ZM10 17L6 13L7.41 11.59L10 14.17L16.59 7.58L18 9L10 17Z" fill="#16a34a"></path>
                            </svg>
                        </div>
                        <a href="/" onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                    </div>
                }
                {result == 'error' && 
                    <div className="flex flex-col items-center">
                        <div className="text-xl text-red-600 font-bold">Sorry, an error occoured, please contact customer services</div>
                    <div className="flex justify-center pt-4">
                        <svg width="150" height="150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#e11d48"></path>
                        </svg>
                        </div>
                        <a href="/" onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                    </div>
                }
                {result == 'cancel' && 
                    <div className="flex flex-col items-center">
                        <div className="text-xl text-red-600 font-bold">Direct Debit setup canceled</div>
                    <div className="flex justify-center pt-4">
                        <svg width="150" height="150" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#e11d48"></path>
                        </svg>
                        </div>
                        <a href="/" onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                    </div>
                }
                </>
            </div>
        </>
        
    );
}