import {useState} from 'react';
import postAPI from '../functions/API/postAPI.js'
import { toast } from 'react-toastify';

export default function NewRequestTypeForm({setVisible, refresh, refreshValue}) {
    const [newRequestType, setNewRequestType] = useState({
        title: "",
        description: "",
        type: "",
        proxyreference: "",
        quickaction: 0
    })

    async function createNewRequestType(){
        const body = {title: newRequestType.title, description: newRequestType.description, type: newRequestType.type, proxyreference: newRequestType.proxyreference, quickaction: 0}
        const NewRequestTypeResponse = await postAPI('tenant/requesttype', body);
        if(NewRequestTypeResponse.response.ok){
            refresh(refreshValue+1);
            toast.success('Request type created successfully.');
            setVisible(false);
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }        
    }

    function handleChange (event) {
        let statechange = event.target.name;
        let value = event.target.value;
        setNewRequestType({...newRequestType, [statechange]: value})
    }

    return (
        <form>
            <div className="space-y-12">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Create new request type</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Create new request types and map them to requests in your integrated system</p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 md:col-span-1">
                    <div className="sm:col-span-3">
                    <label htmlFor="title" className="block text-sm font-medium leading-6 text-gray-900">
                        Title
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="title"
                        id="title"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-3">
                    <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                        Description
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="description"
                        id="description"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="type" className="block text-sm font-medium leading-6 text-gray-900">
                        Type
                    </label>
                    <div className="mt-2">
                        <select
                        required
                        onChange={handleChange}
                        name="type"
                        id="type"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        >
                        <option value="">Please select a request type</option>
                        <option value="case">Case</option>
                        </select>
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="proxyreference" className="block text-sm font-medium leading-6 text-gray-900">
                        Proxy Reference
                    </label>
                    <div className="mt-2">
                    <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="proxyreference"
                        id="proxyreference"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6">
            
            <button
            type="button"
            onClick={()=>{createNewRequestType()}}
            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
            >
            Create
            </button>
            <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
            </button>
        </div>
        </form>
    );
}
