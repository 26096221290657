import {useState, useContext, useEffect, useRef} from 'react';
import GlobalContext from "../context/GlobalContext.js";
import postAPI from '../functions/API/postAPI.js';
import moment from 'moment';

export default function AllPayDirectDebitCard({agreement, accountreference, modalState, accountModalFunction, shouldTriggerDDSetup}) {
    const {themeCSS, settings} = useContext(GlobalContext)
    const [allPayDDInfo, setAllPayDDInfo] = useState();
    const [url, setUrl] = useState("https://api."+window.location.hostname+"/api/allpay/ddredirect");

    async function setUpDirectDebit() {
        document.getElementById('directdebit').submit()
    }

    async function GetAllPayDDInformation(){
        if (window.location.hostname == 'localhost') {
            setUrl('https://customer-portal-api.test/api/allpay/ddredirect')
        }
        const AllPayDDInfoResponse = await postAPI('directdebit/allpay', {'account': accountreference});
        if(AllPayDDInfoResponse.response.ok){
            setAllPayDDInfo(AllPayDDInfoResponse.data);
            setUpDirectDebit();
        }
    }
    
    useEffect(() => {
        if(shouldTriggerDDSetup){
            GetAllPayDDInformation();
        }

    },[shouldTriggerDDSetup])
    return (
        <>
            <div className="h-full rounded-md text-center border flex flex-col">
                <div className="flex-grow p-6">
                    <h2 className="text-xl font-bold mb-2">Direct Debit</h2> 
                    <p className="">
                        You can set up a <strong>new</strong> direct debit by clicking the button below.
                    </p>
                </div>
                <div className="p-4 mt-auto">
                    <button 
                        className={"btn btn-natural capitalize " + (themeCSS.buttonCSS)}
                        onClick={() => { 
                            if (agreement.accounts.length > 1) {
                                modalState(true); accountModalFunction('Direct Debit');
                            } else {
                                GetAllPayDDInformation();
                            }
                        }}
                    >
                        Set up Direct Debit
                    </button>
                </div>
            </div>  
            <div>
                {allPayDDInfo && (
                    <form name="directdebit" id="directdebit" method="POST" action={allPayDDInfo.gatewayurl}>
                        <input type="hidden" name="CLIENT_CODE" id="CLIENT_CODE" value={allPayDDInfo.clientcode} />
                        <input type="hidden" name="CLIENT_REF" id="CLIENT_REF" value={accountreference} />
                        <input type="hidden" name="SURNAME" id="SURNAME" value={allPayDDInfo.surname} />
                        <input type="hidden" name="ADDRESS_LINE_1" id="ADDRESS_LINE_1" value={allPayDDInfo.address1} />
                        <input type="hidden" name="ADDRESS_LINE_3" id="ADDRESS_LINE_3" value={allPayDDInfo.address2} />
                        <input type="hidden" name="POST_CODE" id="POST_CODE" value={allPayDDInfo.postcode} />
                        <input type="hidden" name="VERSION" id="VERSION" value={allPayDDInfo.version} />
                        <input type="hidden" name="RETURNURL" id="RETURNURL" value={url} />
                        <input type="hidden" name="TIMESTAMP" id="TIMESTAMP" value={moment().format("yyyyMMDDhhmmss")}/>
                        <input type="hidden" name="APPLICATION_TOKEN" id="APPLICATION_TOKEN" value={allPayDDInfo.token} /> 
                    </form>
                )}
            </div>
        </>
        
    );
}