import {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import postAPI from '../functions/API/postAPI.js'
import getAPI from '../functions/API/getAPI.js'
import GlobalContext from "../context/GlobalContext.js";
import Select from 'react-select';
import DeleteModal from '../modals/delete.js';
import Loader from '../components/loader.js';
import { ToastContainer, toast } from 'react-toastify';

export default function PaymentSettings() {
    const [workingSettings, setWorkingSettings] = useState();
    const [savebutton, setSaveButton] = useState(false);
    const [provider, setProvider] = useState();
    const [DDprovider, setDDProvider] = useState();
    const [updatedSettings, setUpdatedSettings] = useState({});
    const [IPs, setIPs] = useState();
    const [reload, setReload] = useState(false);
    const [loadingspinner, setLoadingSpinner] = useState(false);
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)
    const [AllPayClientCodes, setAllPayClientCodes] = useState()
    const [newCode, setNewCode] = useState();
    const [newCodeModal, setNewCodeModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [IDToDelete, setIDToDelete] = useState();
    const [operators, setOperators] = useState();
    const [columns, setColumns] = useState()
    const [currentNewRule, setCurrentNewRule] = useState()
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);


    async function GetAPIData(){
        if (settings.paymentprovider == "AllPay" || settings.directdebit_provider == "AllPay") {
            const AllPayAPICodes = await getAPI('getallpayclientcodes');
            if (AllPayAPICodes.response.ok) {
                console.log(AllPayAPICodes.data)
                setAllPayClientCodes(AllPayAPICodes.data['AllPay ClientCodes'])
            }
        }
        let ColumnsResponse = await getAPI('rules/columns');
        if (ColumnsResponse.response.ok) {

            let columns = [];
            ColumnsResponse.data.forEach(element => {
                columns.push({'value': element.column_name, 'label': element.column_name + " ("+element.table_name+")", 'id': element.table_name})
            });
            setColumns(columns)
        }
        let OperatorsResponse = await getAPI('rules/operators');
        if (OperatorsResponse.response.ok) {
            let operators = [];
            OperatorsResponse.data.forEach(element => {
                operators.push({'value': element.operator, 'label': element.name})
            });
            setOperators(operators)
        }
        setIsLoaded(true);
    }
    
    async function WorkingSettings() {
        setProvider(settings.paymentprovider)
        setDDProvider(settings.directdebit_provider)
        setWorkingSettings(settings);
        setIPs(settings.proxyips)    
    }

    function handleChange(event) {
        let settingname = event.target.name;
        let value = event.target.value;
        setWorkingSettings({...workingSettings, [settingname]: value});
        setUpdatedSettings({...updatedSettings, [settingname]: value});
        setSaveButton(true)
    }

    function changeProvider(newprovider){
        setSaveButton(true)
        setWorkingSettings({...workingSettings, 'paymentprovider': newprovider});
        setUpdatedSettings({...updatedSettings, 'paymentprovider': newprovider});
        setProvider(newprovider);
    }

    function changeDDProvider(newDDprovider){
        setSaveButton(true)
        setWorkingSettings({...workingSettings, 'directdebit_provider': newDDprovider});
        setUpdatedSettings({...updatedSettings, 'directdebit_provider': newDDprovider});
        setDDProvider(newDDprovider);
    }

    function openNewClientCodeModal(type){
        setNewCodeModal(true)
        setCurrentNewRule({...currentNewRule, 'type': type});

    }

    async function CreateCode(type) { 
        let body = { ...currentNewRule, type };
        const newCodeResponse = await postAPI('createallpayclientcode', body);
        if(newCodeResponse.response.ok){
            toast.success('Client Code created successfully.');
            setNewCodeModal(false);
            setReload(!reload);
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }
    }

    function showDeleteModal(USERID){
        setIDToDelete(USERID);
        setDeleteModal(true);
    }

    async function UpdateIntegrations() {       
        if(workingSettings.allpay_dd_client_code || workingSettings.allpay_dd_css_version || workingSettings.allpay_dd_url){
            if(!workingSettings.allpay_dd_client_code || !workingSettings.allpay_dd_css_version || !workingSettings.allpay_dd_url){
                toast.warning('Please ensure the AllPay DD Client code, CSS version and URL fields have been completed');
                return;
            }
        }

        const body = updatedSettings
        const IntegrationSettingsResponse = await postAPI('settings', body);

        if(IntegrationSettingsResponse.response.ok){
            setSaveButton(false)
            toast.success('Integration Settings Updated Successfully.');
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }
    }

    function handleCurrentColumn(event){
        setCurrentNewRule({...currentNewRule, 'column_name': event.value, 'table_name': event.id});
    }

    function handleCurrentOperator(event){
        setCurrentNewRule({...currentNewRule, 'operator': event.value});
    }

    function handleCurrentValueToCompare(event){
        setCurrentNewRule({...currentNewRule, 'value_to_compare': event.target.value});
    }

    function handleCurrentCodeToUse(event){
        setCurrentNewRule({...currentNewRule, 'value': event.target.value});
    }

    useEffect(() => {
        WorkingSettings();
        GetAPIData();
    }, [reload]);
    
    return (
            <div className="">
            <AdminSidebar>
                {loading ? (
                    <Loader style='form' setLoadingState={setLoading} isLoaded={isLoaded}/>
                ) : (
                    <>
                    <div className="sm:flex-auto mb-8 border-b border-gray-900/10 pb-8">
                        <div className="space-y-6">
                            <div className="sm:flex-auto">
                                <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Card Payment Settings</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Configure card payment integrations to your payment provider.
                                </p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
                                {provider && (
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Payment Provider
                                        </label>
                                        <select
                                            id="paymentprovider"
                                            name="paymentprovider"
                                            className="mt-2 block w-full rounded border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            value={provider || "Please select"}
                                            onChange={(e) => {
                                                changeProvider(e.target.value);
                                            }}                                                >   
                                            <option value="url">URL (Web Link)</option>
                                            <option value="AllPay">AllPay</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                {provider == "url" && (  
                                    <>
                                    <div className="font-light w-full col-span-4">
                                        {provider && (  
                                            <div>
                                                <label className="block text-sm font-medium leading-6 text-gray-900">
                                                    Payment URL
                                                </label>
                                                <div className="mt-2">
                                                    <input
                                                        type="text"
                                                        color="blue"
                                                        outline={true}
                                                        placeholder="Payment URL"
                                                        value={workingSettings.payment_url}
                                                        name="payment_url"
                                                        onChange={handleChange}
                                                        className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    </>
                                )}
                                {provider == "AllPay" && (  
                                    <>
                                    <div className="font-light w-full col-span-12 mb-4">
                                        {provider && (  
                                            <>
                                                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            Payment URL
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="Payment URL"
                                                                value={workingSettings.payment_url}
                                                                name="payment_url"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Client Code (Default)
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Client Code (Default)"
                                                                value={workingSettings.allpay_clientcode}
                                                                name="allpay_clientcode"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Organisationid ID
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Organisationid ID"
                                                                value={workingSettings.allpay_organisationid}
                                                                name="allpay_organisationid"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Gateway URL
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Gateway URL"
                                                                value={workingSettings.allpay_gatewayurl}
                                                                name="allpay_gatewayurl"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Token URL
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Token URL"
                                                                value={workingSettings.allpay_tokenurl}
                                                                name="allpay_tokenurl"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Ocpapim Subscription Key (Blank for security)
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Ocpapim Subscription Key"
                                                                value={workingSettings.allpay_ocpapimsubscriptionkey}
                                                                name="allpay_ocpapimsubscriptionkey"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay Ocpapim Subscription Key (Blank for security)
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay Secret (Blank for security)"
                                                                value={workingSettings.allpay_secret}
                                                                name="allpay_secret"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    </>
                                )}
                            </div>
                            {settings && provider == "AllPay" && (
                                <div>
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Client Code Rules</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">Configure your allpay direct debit client code mappings</p>
                                    <div className="grid-cols-5 gap-x-2 grid">
                                        <Select
                                            defaultValue=""
                                            onChange={handleCurrentColumn}
                                            options={columns}
                                            placeholder="Select Column"
                                            className="dark:text-black pt-2 w-full"
                                        />
                                        <Select
                                            defaultValue=""
                                            onChange={handleCurrentOperator}
                                            options={operators}
                                            placeholder="Select condition"
                                            className="dark:text-black pt-2 w-full"
                                        />
                                        <input required placeholder="Value to match" type="text" name="value_to_match" onChange={handleCurrentValueToCompare} className="placeholder-gray-500 mt-2 border-gray-300 rounded pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                        <input required placeholder="Code to use" type="text" name="code" onChange={handleCurrentCodeToUse} className="placeholder-gray-500 mt-2 border-gray-300 rounded pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                        <div className="flex items-center w-full">
                                            <div
                                                type="button"
                                                onClick={()=>{CreateCode('payment')}}
                                                className="mt-2 w-full rounded text-slate-700 border border-gray-300 px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                            >
                                                + Add Rule
                                            </div>
                                        </div>
                                    </div>

                                    <table className="min-w-full divide-y divide-gray-300 mt-4">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Rule
                                                </th>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Code to use
                                                </th>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {AllPayClientCodes && (
                                            AllPayClientCodes.map(function(clientcode, index){
                                                return (
                                                    clientcode.type == 'payment' && (    
                                                        <tr>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                                {clientcode.operator == "==" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid is equal to <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                                {clientcode.operator == "!=" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid is NOT equal to <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                                {clientcode.operator == "contains" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid contains <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                            </th>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                                {clientcode.value}
                                                            </th>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            <button onClick={() => {showDeleteModal(clientcode.id)}} type="button" className={`rounded bg-red-600 hover:bg-red-500 px-3 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700`}>
                                                                Delete
                                                            </button>
                                                            </th>
                                                        </tr>
                                                        )
                                                    )}
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="sm:flex-auto mb-8 border-b border-gray-900/10 pb-8">
                        <div className="space-y-6">
                        <div className="sm:flex-auto">
                                <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Direct Debit Settings</h1>
                                <p className="mt-2 text-sm text-gray-700">
                                    Configure Direct Debit integrations to your payment provider.
                                </p>
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3">
                                {DDprovider && (
                                    <div>
                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                            Direct Debit Provider
                                        </label>
                                        <select
                                            id="paymentprovider"
                                            name="paymentprovider"
                                            className="mt-2 block w-full rounded border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-blue-700 sm:text-sm sm:leading-6"
                                            value={DDprovider || "Please select"}
                                            onChange={(e) => {
                                                changeDDProvider(e.target.value);
                                            }}                                                >   
                                            <option value="AllPay">AllPay</option>
                                        </select>
                                    </div>
                                )}
                            </div>
                            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-12">
                                {DDprovider == "AllPay" && (  
                                    <>
                                    <div className="font-light w-full col-span-12 mb-4">
                                        {DDprovider && (  
                                            <>
                                                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay DD Client Code (Default)
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay DD Client Code (Default)"
                                                                value={workingSettings.allpay_dd_client_code}
                                                                name="allpay_dd_client_code"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay DD App Token (Blank for security)
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay DD App Token (Blank for security)"
                                                                value={workingSettings.allpay_dd_app_token}
                                                                name="allpay_dd_app_token"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay DD CSS Version
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay DD CSS Version"
                                                                value={workingSettings.allpay_dd_css_version}
                                                                name="allpay_dd_css_version"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                    <div className="sm:col-span-2">
                                                        <label className="block text-sm font-medium leading-6 text-gray-900">
                                                            AllPay DD URL
                                                        </label>
                                                        <div className="mt-2">
                                                            <input
                                                                type="text"
                                                                color="blue"
                                                                outline={true}
                                                                placeholder="AllPay DD URL"
                                                                value={workingSettings.allpay_dd_url}
                                                                name="allpay_dd_url"
                                                                onChange={handleChange}
                                                                className="block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    </>
                                )}
                            </div>
                            <h2 className="mt-4 text-base font-semibold leading-7 text-gray-900">Return URL to provide to allpay</h2>
                            <p className="mt-1 text-sm leading-6 text-gray-600">{"https://api."+window.location.hostname+"/api/allpay/ddredirect"}</p>

                            {settings && DDprovider == "AllPay" && (
                                <div>
                                    <h2 className="text-base font-semibold leading-7 text-gray-900">Client Code Rules</h2>
                                    <p className="mt-1 text-sm leading-6 text-gray-600">Configure your allpay direct debit client code mappings</p>
                                    <div className="grid-cols-1 md:grid-cols-5 gap-x-2 grid">
                                        <Select
                                            defaultValue=""
                                            onChange={handleCurrentColumn}
                                            options={columns}
                                            placeholder="Select Column"
                                            className="dark:text-black pt-2 w-full"
                                        />
                                        <Select
                                            defaultValue=""
                                            onChange={handleCurrentOperator}
                                            options={operators}
                                            placeholder="Select condition"
                                            className="dark:text-black pt-2 w-full"
                                        />
                                        <input required placeholder="Value to match" type="text" name="value_to_match" onChange={handleCurrentValueToCompare} className="placeholder-gray-500 mt-2 border-gray-300 rounded pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                        <input required placeholder="Code to use" type="text" name="code" onChange={handleCurrentCodeToUse} className="placeholder-gray-500 mt-2 border-gray-300 rounded pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                        <div className="flex items-center w-full">
                                            <div
                                                type="button"
                                                onClick={()=>{CreateCode('directdebit')}}
                                                className="mt-2 w-full rounded text-slate-700 border border-gray-300 px-3 py-2 text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                            >
                                                + Add Rule
                                            </div>
                                        </div>
                                    </div>

                                    <table className="min-w-full divide-y divide-gray-300 mt-4">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Rule
                                                </th>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    Code to use
                                                </th>
                                                <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                                    
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {AllPayClientCodes && (
                                            AllPayClientCodes.map(function(clientcode, index){
                                                return (
                                                    clientcode.type == 'directdebit' && (    
                                                        <tr>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                                {clientcode.operator == "==" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid is equal to <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                                {clientcode.operator == "!=" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid is NOT equal to <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                                {clientcode.operator == "contains" && (
                                                                    <p>If the <strong>{clientcode.column_name}</strong> for the agreement being paid contains <strong>{clientcode.value_to_compare}</strong> then use the client code of <strong>{clientcode.value}</strong></p>
                                                                )}
                                                            </th>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                                {clientcode.value}
                                                            </th>
                                                            <th className="border-b border-gray-200 align-middle font-light text-sm whitespace-nowrap px-2 py-4 text-left">
                                                            <button onClick={() => {showDeleteModal(clientcode.id)}} type="button" className={`rounded bg-red-600 hover:bg-red-500 px-3 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700`}>
                                                                Delete
                                                            </button>
                                                            </th>
                                                        </tr>
                                                        )
                                                    )}
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>                            
                            )}
                        </div>
                    </div>
                    </>
                )}
            </AdminSidebar>
            
            {savebutton && (
            <div className="fixed bottom-5 right-5">
                <button onClick={() => {UpdateIntegrations()}} className="mb-4 rounded-md bg-blue-700 px-3 py-2 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">Save Changes</button>
            </div>
            )}

            <DeleteModal open={deleteModal} setOpen={setDeleteModal} IDToDelete={IDToDelete} refresh={GetAPIData} deleteAction="deleteClientCode" title="Delete Code Mapping" message="Are you sure you want to delete this client code? This action cannot be undone." />
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div> 
    );
}

