export const light = {
    divCSS: "bg-white dark:text-black",
    buttonCSS: "bg-slate-700 text-white capitalize",
    lightButtonCSS: "capitalize",
    inputCSS: "bg-white",
    iconHex: "#2E3A59",
    tableHeaderCSS: "bg-gray-200 text-gray-600",
    text: "text-black",
    menu: "hover:bg-gray-200 bg-white dark:text-black",
    messageItem: "hover:bg-gray-100"
};
export const dark = {
    divCSS: "bg-slate-800 text-white",
    buttonCSS: "border-white capitalize",
    lightButtonCSS: "capitalize",
    inputCSS: "bg-slate-800 text-white placeholder-white",
    iconHex: "#fff",
    tableHeaderCSS: "bg-slate-800 text-white",
    text: "text-white",
    menu: "hover:bg-slate-700 bg-slate-800 text-white",
    messageItem: "bg-slate-800 hover:bg-slate-600"
};
export const highContrast = {
    divCSS: "bg-yellow-300 text-red-500",
    buttonCSS: "bg-yellow-300 text-red-500 capitalize",
    lightButtonCSS: "bg-yellow-300 text-red-500 capitalize",
    inputCSS: "bg-yellow-300 text-red-500 placeholder-red-500",
    iconHex: "#f91216",
    tableHeaderCSS: "bg-yellow-300 text-red-500",
    text: "text-red-500",
    menu: "hover:bg-yellow-700 bg-yellow-300 text-red-500",
    messageItem: "bg-yellow-300 hover:bg-yellow-700"
};  