import {react, useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NewContactMethodForm from '../forms/newContactMethod.js';
import DeleteModal from '../modals/delete.js';
import Table from '../components/Table.js';
import getAPI from '../functions/API/getAPI.js'
import GlobalContext from "../context/GlobalContext.js";
import SlideOverRight from '../components/slideOverRight.js';
import Loader from '../components/loader.js'


export default function ContactMethodMappings() {
    const [methods, setMethods] = useState();
    const [newMethod, setNewMethod] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [IDToDelete, setIDToDelete] = useState();
    const {settings} = useContext(GlobalContext)
    const [shouldRefresh, setShouldRefresh] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData() {
        let ContactMethodMappings = await getAPI('getcontactmethodmappings');
        let tableData = {
            title: 'Contact Methods',
            subheading: 'Create new contact methods and map them to methods in your integrated system',
            buttonText: 'Add contact method',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Method Name'},
                {value: 'Mapped Method ID'},
                {value: 'Mapped Category ID'},
                {value: ''},
            ],
            rows: []
        }

        ContactMethodMappings.data.forEach(element => {
            tableData.rows.push ({data:[
                {type: 'text', value: element.name},
                {type: 'text', value: element.method_id},
                {type: 'text', value: element.category_id},
                {
                    "type": "button",
                    "value": "Delete",
                    "action": "function",
                    "buttontype": "warning",
                    "function": showDeleteModal,
                    'functionparam': element.id           
                }
            ]})
        });
        setMethods(tableData);
        setIsLoaded(true);
    }

    function showDeleteModal(methodid){
        setIDToDelete(methodid);
        setDeleteModal(true);
    }

    function setFormState(){
        setNewMethod(true);
    }

    useEffect(() => {
        GetAPIData();
    }, [shouldRefresh]);

    return (
        <>
            <>
            <AdminSidebar>
                {loading ? (
                    <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
                ) : (
                    <Table tableData={methods} />
                )}
                <SlideOverRight visible={newMethod} setVisible={setNewMethod}>
                    <NewContactMethodForm setVisible={setNewMethod} refresh={setShouldRefresh} refreshValue={shouldRefresh}/>
                </SlideOverRight>
            </AdminSidebar>
            <DeleteModal open={deleteModal} setOpen={setDeleteModal} IDToDelete={IDToDelete} refresh={GetAPIData} deleteAction="deleteContactMethod" title="Delete contact method" message="Are you sure you want to delete this contact method? This action cannot be undone." />
            </>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    );
    
}

