import {useState, useContext, useEffect, useRef} from 'react';
import Modal from 'react-modal';
import Loader from '../components/loader.js'
import Nav from "../components/Nav.js";
import HeaderBackground from '../components/headerbackground.js'
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";
import MFACode from '../components/MFACode.js';


export default function ExtendedVerification() {

    const {theme, updateTheme, themeCSS, publicSettings} = useContext(GlobalContext)
    const [loading, isLoading] = useState(true)
    const [verificationMethods, setVerificationMethods] = useState();
    const [selectedContactMethod, setSelectedContactMethod] = useState({'id':"",'value':""});
    const [verificationCode, setVerificationCode] = useState();
    const [noMethods, setNoMethods] = useState(true);
    const [stage, setStage] = useState(1);
    const [loadingspinner, setLoadingSpinner] = useState(false);
    function setParentCode(code) {
        completeVerification(code)
    }

    async function GetVerificationMethods() {
        const ExtendedVerificationResponse = await getAPI('getextendedverificationdetails');
        if (ExtendedVerificationResponse.data['contact details available']) {
            let length = ExtendedVerificationResponse.data['contact methods']['email addresses'].length;
            if (length > 0) {
                setNoMethods(false);
                setVerificationMethods(ExtendedVerificationResponse.data)
            }
        }
        isLoading(false);
    }

    async function sendCode(){
        isLoading(true);
        if (selectedContactMethod.id) {

            const body = {id: selectedContactMethod.id}
            const VerificationCodeResponse = await postAPI('getextendedverificationcode', body);
            if(VerificationCodeResponse.response.ok){       
                setStage(2)
                isLoading(false)
            }else{
                toast.error('Sorry an error occured. Try again later.');
            }
        } else {
            toast.error('Please select a contact method.');
        }
        isLoading(false);
    }

    async function completeVerification(code){
        setLoadingSpinner(true);
        const body = {code: code}
        const CompleteVerificationResponse = await postAPI('completeextendedverification', body);
        if(CompleteVerificationResponse.response.ok){       
            window.location.href = '/login' 
        }else{
            toast.error('Sorry, your code was invalid. Please try again.');
            setLoadingSpinner(false)
        }
    }

    function back(){
        setStage(1);
    }

    useEffect(() => {
        GetVerificationMethods();
      }, []);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}

            <div className={"min-h-screen py-1 " + (themeCSS.divCSS)}>
                <div className="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
                    <div className="w-full max-w-sm space-y-10 mt-10">
                        <div>
                            {publicSettings.logo_url && (
                                <img 
                                src={publicSettings.logo_url}
                                alt="logo"
                                width="180"
                                className="mx-auto pb-2"
                                />
                            )}
                            {!publicSettings.logo_url && (
                                <h1 className="pb-2 text-3xl font-semibold text-center">{publicSettings.company_name}</h1>
                            )}

                            {!!publicSettings.test_system && (
                                <div className="flex justify-center">
                                    <div className="inline-flex ml-2 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                                        Test System
                                    </div>
                                </div>
                            )}
                        
                            <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Extended Verification
                            </h2>
                            
                        </div>
                    </div>
                </div>
                <div>
                    <div>

                        {noMethods && (
                            <div>
                                <div className="flex flex-col items-center space-y-2 mx-8 md:mx-40 lg:mx-80">
                                    <div className="text-xl text-red-500 font-bold  text-center w-5/6">Please call customer services</div>
                                    <p className="text-md text-center w-5/6">It would appear that we don't hold an email address for you. Please call customer services and an advisor will be able to update these records.</p>
                                </div>
                            </div>
                        )}

                        {!noMethods && (
                            <div>
                                {stage == 1 && (
                                    <div>
                                        <div className="flex flex-col items-center space-y-2 mx-8 md:mx-40 lg:mx-80">
                                            <p className="text-md  text-center w-5/6">To complete your account setup please select a contact method below, we will send you a code to verify your identity. If you can't access the methods below you will need to call our customer services team.</p>
                                            <div className="grid grid-cols-1 w-full gap-2 justify-center">
                                            {verificationMethods && (
                                                verificationMethods['contact methods']['email addresses'].map(function(email, index) {
                                                return (
                                                    <>
                                                    <button key={index} onClick={() => {setSelectedContactMethod({...selectedContactMethod, "id": email.id, 'value': email['email address']})}} className={email.id === selectedContactMethod.id ? "p-4 w-full text-cente border-blue-500 focus:outline-none border-4 rounded-lg " : "p-4 w-full text-center focus:outline-none border-4 rounded-lg" }>
                                                        Email: {email['email address']}
                                                    </button>
                                                    </>
                                                )
                                                })
                                            )}
                                            </div>
                                        </div>
                                        <div className="flex flex-col items-end md:mx-40 mx-8 lg:mx-80 pb-20">
                                            <button  onClick={() => {sendCode()}} className="mt-8 h-8 w-20 flex content-center justify-center rounded-md bg-blue-700 px-3 py-1 text-sm font-semibold leading-6 text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                                <p className="col-span-12">Send Verification Code</p>
                                            </button>
                                        </div>
                                    </div>
                                    )}

                                    
                                {stage == 2 && (
                                    <div>
                                        <MFACode setParentCode={setParentCode} loadingSpinner={loadingspinner} setLoadingSpinner={setLoadingSpinner}/>

                                        <p className="text-center text-sm leading-6 text-gray-500 mt-4">
                                            Need to choose another method?{' '}
                                            <a href='#' onClick={()=>{back()}} className="font-semibold text-blue-700 hover:text-blue-500">
                                                Go back
                                            </a>
                                        </p>
                                        
                                    </div>
                                )}
                            </div>
                        )}
                    </div> 
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
        
    );
    
}

