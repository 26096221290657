import {useState, useEffect} from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SlideOverRight from './slideOverRight.js';
import Select from 'react-select';
import DeleteModal from '../modals/delete.js';
import Loader from '../components/loader.js'

export default function RepairGroupsTable() {

    const [deleteModal, setDeleteModal] = useState(false);
    const [IDToDelete, setIDToDelete] = useState();
    const [addGroupSlideoverState, setAddGroupSlidoverState] = useState(false);
    const [editGroupSlideoverState, setEditGroupSlidoverState] = useState(false);
    const [tableData, setTableData] = useState();
    const editedGroup = {'type': "", 'name': "", 'rules': [], 'definitions': []};
    const [editedRules, setEditedRules] = useState([])
    const [editedDefinitions, setEditedDefinitions] = useState([])
    const [editedGroupName, setEditedGroupName] = useState();
    const [editedGroupType, setEditedGroupType] = useState();
    const [editedNewRule, setEditedNewRule] = useState()
    const [groupToEdit, setGroupToEdit] = useState();
    const [definitionSelect, setDefinitionSelect] = useState();
    const [operators, setOperators] = useState();
    const [columns, setColumns] = useState();
    const [currentNewRule, setCurrentNewRule] = useState()
    const [currentDefinitions, setCurrentDefinitions] = useState();
    const [groupName, setGroupName] = useState();
    const [groupType, setGroupType] = useState();
    const [newRules, setNewRules] = useState([])
    const newGroup = {'type': "", 'name': "", 'rules': [], 'definitions': []};
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);
    let groupsForEdit = [];
    let rulesForEdit = [];
    let definitionsForEdit = [];
    let groupRuleOptions = [{'value': 'all', 'label': 'All Rules Must Match'}, {'value': 'one', 'label': 'One Rules Must Match'}]

    async function GetAPIData() {
        setIsLoaded(false);
        let GroupResponse = await getAPI('repair/groups');
        groupsForEdit = GroupResponse.data;

        let workingTableData = {
            title: 'Repair Groups',
            subheading: 'Repair groups allow you to decide what repair definitions to offer to the customer based on a set of rules.',
            buttonText: 'Add Group',
            buttonFunction: setFormState,
            tableHeadings: [
                {value: 'Group Name'},
                {value: ''},
                {value: ''}
            ],
            rows: []
        }

    
        GroupResponse.data.forEach(element => {
            workingTableData.rows.push ({data:[
                {type: 'text', value: element.name},
                {
                    "type": "button",
                    "value": "Edit",
                    "action": "function",
                    "buttontype": "info",
                    "function": editGroup,
                    'functionparam': element.id        
                },
                {
                    "type": "button",
                    "value": "Delete",
                    "action": "function",
                    "buttontype": "warning",
                    "function": setDeleteModalState,
                    'functionparam': element.id         
                }
            ]})
        });

        setTableData(workingTableData);
        setIsLoaded(true);
    }

    async function GetAdditionalGroupData() {
        let OperatorsResponse = await getAPI('rules/operators');
        if (OperatorsResponse.response.ok) {
            let operators = [];
            OperatorsResponse.data.forEach(element => {
                operators.push({'value': element.operator, 'label': element.name})
            });
            setOperators(operators)
        }

        let ColumnsResponse = await getAPI('rules/columns');
        if (ColumnsResponse.response.ok) {
            let columns = [];
            ColumnsResponse.data.forEach(element => {
                columns.push({'value': element.column_name, 'label': element.column_name + " ("+element.table_name+")", 'id': element.table_name})
            });
            setColumns(columns)
        }

        let DefinitionResponse = await getAPI('repairdefinitions?active=1');
        if(DefinitionResponse.response.ok){
            let definitions = [];
            DefinitionResponse.data.forEach(element => {
                definitions.push({'value': element.id, 'label': element.description + " (" + element.sorcode + " - " + element.contractorname + ")"})
            });
            setDefinitionSelect(definitions)
        }
    }

    function setFormState(){
        GetAdditionalGroupData();
        setAddGroupSlidoverState(true);
    }

    function setDeleteModalState(id){
        setDeleteModal(true);
        setIDToDelete(id);
    }

    async function editGroup(groupid) {
        setIsLoaded(false);
        GetAdditionalGroupData();
        let RepairGroupRulesResponse = await getAPI('repair/groups/rules');
        rulesForEdit = RepairGroupRulesResponse.data;

        let RepairGroupDefinition = await getAPI('repair/groups/definitions');
        definitionsForEdit = RepairGroupDefinition.data;
        
        setGroupToEdit(groupid);
        var group = groupsForEdit.filter(group => {return group.id == groupid})
        var filteredrules = rulesForEdit.filter(rule => {return rule.repair_group_id == groupid})
        var filteredgroupdefinitions = definitionsForEdit.filter(definition => {return definition.group_id == groupid})

        setEditedGroupName(group[0].name)
        setEditedGroupType(group[0].type)
        setEditedRules([]);
        filteredrules.forEach(rule => {
            setEditedRules(editedRules => [...editedRules, {'column_name': rule.column_name, 'table_name': rule.table_name, 'operator': rule.operator, 'value_to_compare': rule.value_to_compare}]);
        });
        let defaultDefinitions = [];
        let defintionsToIterate = [];
        let DefinitionResponse = await getAPI('repairdefinitions?active=1');

        filteredgroupdefinitions.forEach(definition => {
            DefinitionResponse.data.find((def) => {
                if(def.id == definition.definition_id){
                    defintionsToIterate.push(def);
                }
            });
        })

        if(defintionsToIterate[0] != undefined){
            defintionsToIterate.forEach(element => {
                defaultDefinitions.push({'value': element.id, 'label': element.description + " (" + element.sorcode + " - " + element.contractorname + ")"})
            });
        };
        setEditedDefinitions({...editedDefinitions, "definitions": defaultDefinitions});
        if (group[0].type == 'all') {
            setEditedGroupType({'value': 'all', 'label': 'All Rules Must Match'})
        }
        if (group[0].type == 'one') {
            setEditedGroupType({'value': 'one', 'label': 'One Rules Must Match'})
        }
        editedGroup['rules'] = editedRules;
        editedGroup['name'] = group[0].name;
        editedGroup['type'] = group[0].type ;
        editedGroup['definitions'] = editedDefinitions;
        setEditGroupSlidoverState(true);
        setIsLoaded(true);
    }

    function removeEditedRule(id){
        let amended = editedRules.filter(function(rule, index) { 
            return index !== id 
        });
        setEditedRules(amended);
    }

    function addEditedRule(){
        if (editedNewRule.operator && editedNewRule.column_name && editedNewRule.value_to_compare) {
            setEditedRules(editedRules => [...editedRules, editedNewRule]);
        } else {
            toast.error('Please complete the rule fields')
        }
    }

    function handleEditedRuleMatching(event){
        setEditedGroupType(event.value)
    }

    function handleEditedGroupNameChange (event) {
        setEditedGroupName(event.target.value)
    }

    function handleEditedDefinitionSelectChange(event){
        setEditedDefinitions({...editedDefinitions, "definitions": event});
    }

    function handleEditedCurrentColumn(event){
        setEditedNewRule({...editedNewRule, 'column_name': event.value, 'table_name': event.id});
    }

    function handleEditedCurrentOperator(event){
        setEditedNewRule({...editedNewRule, 'operator': event.value});
    }

    function handleEditedCurrentValueToCompare(event){
        setEditedNewRule({...editedNewRule, 'value_to_compare': event.target.value});
    }

    function handleDefinitionSelectChange(event){
        setCurrentDefinitions({...currentDefinitions, "definitions": event});
    }

    function handleCurrentColumn(event){
        setCurrentNewRule({...currentNewRule, 'column_name': event.value, 'table_name': event.id});
    }

    function handleCurrentOperator(event){
        setCurrentNewRule({...currentNewRule, 'operator': event.value});
    }

    function handleCurrentValueToCompare(event){
        setCurrentNewRule({...currentNewRule, 'value_to_compare': event.target.value});
    }

    function handleRuleMatching(event){
        setGroupType(event.value)
    }

    function handleGroupNameChange (event) {
        setGroupName(event.target.value)
    }

    async function createEditedGroup() {
        if(!groupToEdit) return;
        setEditGroupSlidoverState(false);
        let definitionarray = []
        editedDefinitions.definitions.forEach(element => {
            definitionarray.push(element.value)
        });
        editedGroup['name'] = editedGroupName;    
        editedGroup['type'] = editedGroupType;  
        if(editedGroupType.value){editedGroup['type'] = editedGroupType.value} else {editedGroup['type'] = editedGroupType}
        editedGroup['rules'] = editedRules;
        editedGroup['definitions'] = definitionarray;
        if (editedGroup) {
            if (editedGroup.name && editedGroup.type  && editedGroup.definitions && editedGroup.rules) {
                const editedGroupResponse = await postAPI('repair/groups/create', editedGroup);
                if(editedGroupResponse.response.ok){
                    await postAPI('repair/groups/delete', {id: groupToEdit});
                    toast.success('Group updated successfully.');
                    GetAPIData();
                    setEditedRules([]);
                }else{
                    toast.error('Sorry an error occurred. Try again later.');
                }
            } else {
                toast.error('Please complete this screen')
            }
        } else {
            toast.error('Please complete this screen')
        } 
        setEditGroupSlidoverState(false);
    }

    function addRule(){
        if (currentNewRule.operator && currentNewRule.column_name && currentNewRule.value_to_compare) {
            setNewRules(newRules => [...newRules, currentNewRule]);
        } else {
            toast.error('Please complete the rule fields')
        }
    }

    async function CreateGroup() {
        setIsLoaded(false);
        setAddGroupSlidoverState(false);
        let definitionarray = []
        currentDefinitions.definitions.forEach(element => {
            definitionarray.push(element.value)
        });
        newGroup['name'] = groupName;    
        newGroup['type'] = groupType;            
        newGroup['rules'] = newRules;
        newGroup['definitions'] = definitionarray;
        if (newGroup) {
            if (newGroup.name && newGroup.type  && newGroup.definitions && newGroup.rules) {
                const newGroupResponse = await postAPI('repair/groups/create', newGroup);
                if(newGroupResponse.response.ok){
                    toast.success('Group created successfully.');
                    GetAPIData();
                    setNewRules([]);
                    setGroupName('');
                }else{
                    toast.error('Sorry an error occurred. Try again later.');
                }
            } else {
                toast.error('Please complete this screen')
            }
        } else {
            toast.error('Please complete this screen')
        } 
        
    }


    function removeEditedRule(id){
        let amended = editedRules.filter(function(rule, index) { 
            return index !== id 
        });
        setEditedRules(amended);
    }

    function removeRule(id){
        console.log(id)
        let amended = newRules.filter(function(rule, index) { 
            return index !== id 
        });
        console.log(amended)
        setNewRules(amended);
    }

    useEffect(() => {
        GetAPIData();
    }, []);
    
    return (
        <>
            {loading ? (
                <Loader style='table' setLoadingState={setLoading} isLoaded={isLoaded}/>
            ) : (
            tableData && (
                <div>
                    <div className="sm:flex sm:items-center">
                        {tableData && (
                        <div className="sm:flex-auto">
                            <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">{tableData.title}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                            {tableData.subheading}
                            </p>
                        </div>
                        )}
                    {tableData.buttonText && tableData.buttonFunction && (
                        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
                            <button onClick={tableData.buttonFunction} type="button" className="block rounded-md bg-blue-700 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700">
                                {tableData.buttonText}
                            </button>
                        </div>
                    )}
                    </div>

                    <div className="flow-root">
                        <div className="overflow-x-auto">
                            <div className="inline-block min-w-full py-2 align-middle">
                                <table className="min-w-full divide-y divide-gray-300">
                                <thead>
                                    <tr>
                                    {tableData && (
                                        tableData.tableHeadings.map((heading) => (
                                        <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                        {heading.value}
                                        </th>
                                        ))
                                    )}
                                    </tr>
                                </thead>
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {tableData && (
                                    tableData.rows.map((row) => (
                                        <tr key={row.id}>
                                        {row.data.map((data) => (
                                            <>
                                                {data.type == 'text' && (
                                                <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                    <div className="text-gray-900">                                               
                                                        {data.value}
                                                    </div>

                                                </td>
                                                )}
                                                {data.type == 'button' && (
                                                <td className="whitespace-nowrap py-3 text-sm text-gray-500">
                                                    <button onClick={()=>{data.function(data.functionparam)}} type="button" className={`rounded ${data.buttontype === 'info' ? 'bg-gray-600 hover:bg-gray-500' : 'bg-red-600 hover:bg-red-500'} px-3 py-1 text-xs font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700`}>
                                                        {data.value}
                                                    </button>
                                                </td>
                                                )}
                                            </>
                                        ))}
                                        </tr>
                                    ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            ))}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <DeleteModal open={deleteModal} setOpen={setDeleteModal} IDToDelete={IDToDelete} refresh={GetAPIData} deleteAction="deleteRepairGroup" title="Delete Group" message="Are you sure you want to delete this group? This action cannot be undone." />

            <SlideOverRight visible={editGroupSlideoverState} setVisible={setEditGroupSlidoverState}>
                <form>
                    <div className="space-y-4">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Edit Group</h2>
                        </div>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                            <div className="sm:col-span-1">
                                <label htmlFor="groupname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Group name
                                </label>
                                <div className="">
                                    <input
                                    required
                                    type="text"
                                    value={editedGroupName}
                                    onChange={handleEditedGroupNameChange}
                                    name="groupname"
                                    id="groupname"
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-1">
                                <label htmlFor="rulematching" className="block text-sm font-medium leading-6 text-gray-900">
                                    Rule Matching
                                </label>
                                <div className="">
                                    <Select
                                        defaultValue={editedGroupType}
                                        onChange={handleEditedRuleMatching}
                                        options={groupRuleOptions}
                                        placeholder="Select the rule type"
                                        className="dark:text-black pt-2"
                                        name="rulematching"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-1">
                                <label htmlFor="definitions" className="block text-sm font-medium leading-6 text-gray-900">
                                    Definitions
                                </label>
                                <div className="">
                                    <Select
                                        defaultValue={editedDefinitions.definitions}
                                        isMulti
                                        onChange={handleEditedDefinitionSelectChange}
                                        options={definitionSelect}
                                        placeholder="Select the repair definitions you wish to make available"
                                        className="dark:text-black pt-2"
                                        name="definitions"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col items-start">
                                <label htmlFor="rules" className="pb-2 block text-sm font-medium leading-6 text-gray-900">
                                    Rules
                                </label>
                                {editedRules && (
                                    editedRules.map(function(rule, index){
                                        return (
                                            <div className="grid grid-cols-12 gap-2" key={index}>
                                                {rule.operator == "==" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "!=" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must not be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "contains" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must contain <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                <svg className="col-span-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {removeEditedRule(index)}}>
                                                    <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#2E3A59"></path>
                                                </svg>
                                            </div>
                                            )}
                                        )
                                    )}
                                    <Select
                                        defaultValue=""
                                        onChange={handleEditedCurrentColumn}
                                        options={columns}
                                        placeholder="Select Column"
                                        className="dark:text-black pt-2 w-full"
                                    />
                                    <Select
                                        defaultValue=""
                                        onChange={handleEditedCurrentOperator}
                                        options={operators}
                                        placeholder="Select condition"
                                        className="dark:text-black pt-2 w-full"
                                    />
                                    <input required placeholder="Value to match" type="text" name="name" onChange={handleEditedCurrentValueToCompare} className="placeholder-gray-500 mt-2 border-gray-300 rounded-md pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                    <div className="flex items-center w-full">
                                        <button
                                            type="button"
                                            onClick={()=>{addEditedRule()}}
                                            className="mt-1 w-full rounded-md text-slate-700 border border-gray-300 px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                        >
                                            + Add Rule
                                        </button>
                                    </div>
                            </div>  
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-start gap-x-6"> 
                        <button
                            type="button"
                            onClick={()=>{createEditedGroup()}}
                            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                            >
                            Save
                        </button>
                        <button type="button" onClick={()=>{setEditGroupSlidoverState(false)}} className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                    </div>
                </form>
            </SlideOverRight>

            <SlideOverRight visible={addGroupSlideoverState} setVisible={setAddGroupSlidoverState}>
                <form>
                    <div className="space-y-4">
                        <div>
                            <h2 className="text-base font-semibold leading-7 text-gray-900">Add Group</h2>
                        </div>
                        <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                            <div className="sm:col-span-1">
                                <label htmlFor="groupname" className="block text-sm font-medium leading-6 text-gray-900">
                                    Group name
                                </label>
                                <div className="">
                                    <input
                                    required
                                    type="text"
                                    value={groupName}
                                    onChange={handleGroupNameChange}
                                    name="groupname"
                                    id="groupname"
                                    className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-1">
                                <label htmlFor="rulematching" className="block text-sm font-medium leading-6 text-gray-900">
                                    Rule Matching
                                </label>
                                <div className="">
                                    <Select
                                        defaultValue={groupType}
                                        onChange={handleRuleMatching}
                                        options={groupRuleOptions}
                                        placeholder="Select the rule type"
                                        className="dark:text-black pt-2"
                                        name="rulematching"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-1">
                                <label htmlFor="definitions" className="block text-sm font-medium leading-6 text-gray-900">
                                    Definitions
                                </label>
                                <div className="">
                                    <Select
                                        isMulti
                                        onChange={handleDefinitionSelectChange}
                                        options={definitionSelect}
                                        placeholder="Select the definitions to make available"
                                        className="dark:text-black pt-2"
                                        name="definitions"
                                    />
                                </div>
                            </div>
                            <div className="flex flex-col items-start">
                                <label htmlFor="rules" className="pb-2 block text-sm font-medium leading-6 text-gray-900">
                                    Rules
                                </label>
                                {newRules && (
                                    newRules.map(function(rule, index){
                                        return (
                                            <div className="grid grid-cols-12 gap-2" key={index}>
                                                {rule.operator == "==" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "!=" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must not be equal to <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                {rule.operator == "contains" && (
                                                    <p className="col-span-10 text-sm italic">The <strong>{rule.column_name}</strong> must contain <strong>{rule.value_to_compare}</strong></p>
                                                )}
                                                <svg className="col-span-2" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => {removeRule(index)}}>
                                                    <path d="M11.9998 22C7.95337 22.0024 4.30431 19.5659 2.75548 15.8276C1.20665 12.0893 2.06336 7.78606 4.92581 4.92598C7.45311 2.39868 11.1367 1.41166 14.5891 2.33672C18.0414 3.26177 20.738 5.95836 21.6631 9.41072C22.5881 12.8631 21.6011 16.5467 19.0738 19.074C17.2013 20.955 14.654 22.0086 11.9998 22ZM3.99981 12.172C4.04713 16.5732 7.64092 20.1095 12.0424 20.086C16.4438 20.0622 19.9994 16.4875 19.9994 12.086C19.9994 7.68449 16.4438 4.10975 12.0424 4.08598C7.64092 4.06244 4.04713 7.59874 3.99981 12V12.172ZM9.40881 16L7.99981 14.59L10.5898 12L7.99981 9.40998L9.40981 7.99998L11.9998 10.59L14.5898 7.99998L15.9998 9.40998L13.4098 12L15.9998 14.59L14.5908 16L11.9998 13.41L9.40981 16H9.40881Z" fill="#2E3A59"></path>
                                                </svg>
                                            </div>
                                            )}
                                        )
                                    )}
                                    <Select
                                        defaultValue=""
                                        onChange={handleCurrentColumn}
                                        options={columns}
                                        placeholder="Select Column"
                                        className="dark:text-black pt-2 w-full"
                                    />
                                    <Select
                                        defaultValue=""
                                        onChange={handleCurrentOperator}
                                        options={operators}
                                        placeholder="Select condition"
                                        className="dark:text-black pt-2 w-full"
                                    />
                                    <input required placeholder="Value to match" type="text" name="name" onChange={handleCurrentValueToCompare} className="placeholder-gray-500 mt-2 border-gray-300 rounded-md pt-2 w-full h-[38px] justify-center text-gray-800"/>
                                    <div className="flex items-center w-full">
                                        <button
                                            type="button"
                                            onClick={()=>{addRule()}}
                                            className="mt-1 w-full rounded-md text-slate-700 border border-gray-300 px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                                        >
                                            + Add Rule
                                        </button>
                                    </div>
                            </div>  
                        </div>
                    </div>

                    <div className="mt-6 flex items-center justify-start gap-x-6">
                        
                        <button
                            type="button"
                            onClick={()=>{CreateGroup()}}
                            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                            >
                            Save
                        </button>
                        <button type="button" onClick={()=>{setAddGroupSlidoverState(false)}} className="text-sm font-semibold leading-6 text-gray-900">
                            Cancel
                        </button>
                    </div>
                </form>
            </SlideOverRight>
        </>
    );
    
}

