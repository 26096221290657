import { useState } from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import MessageCenterTable from '../components/messageCenterTable.js';
import NewBulkMessage from '../forms/newBulkMessage.js';
import SlideOverRight from '../components/slideOverRight.js';

export default function MessageCenter() {
    const [newMessageForm, setNewMessageForm] = useState(false);
    const [shouldRefresh, setShouldRefresh] = useState(0);
    
    return (
        <>
            <AdminSidebar>
                <MessageCenterTable setForm={setNewMessageForm} refresh={shouldRefresh}/> 
                <SlideOverRight visible={newMessageForm} setVisible={setNewMessageForm}>
                    <NewBulkMessage setVisible={setNewMessageForm} refresh={setShouldRefresh} refreshValue={shouldRefresh} />
                </SlideOverRight>
            </AdminSidebar>
        </>
    );
}

