import {useContext, Fragment} from 'react';
import GlobalContext from "../context/GlobalContext.js";
import { Combobox, Dialog, Transition } from '@headlessui/react'

export default function AgreementSwitcher({modalState, setModalState, titleText, children}) {
    const {themeCSS} = useContext(GlobalContext)

    return (
        <Transition.Root show={modalState} as={Fragment} appear>
            <Dialog as="div" className="relative z-10" onClose={()=>{setModalState(false)}}>
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-80"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity-20" />
                </Transition.Child>

                <div className="mt-8 fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Dialog.Panel className={"mx-auto max-w-xl transform divide-y divide-gray-100 overflow-hidden rounded-xl shadow-2xl ring-1 ring-black ring-opacity-5 transition-all " + (themeCSS.divCSS)}>
                        <Combobox>
                            <div className="relative text-center py-4">
                                <p>{titleText}</p>
                            </div>

                            <Combobox.Options static className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3">
                                {children}
                            </Combobox.Options>
                        </Combobox>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}