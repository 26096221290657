import {useState, useContext, useEffect, useRef} from 'react';
import { useParams } from 'react-router-dom';
import Loader from '../components/loader.js'
import HeaderBackground from '../components/headerbackground.js'
import GlobalContext from "../context/GlobalContext.js";
import Lottie from 'lottie-react';
import SuccessLottie from '../lottie/success.json';
import FailureLottie from '../lottie/failure.json';

export default function Payment() {
    const [loading, isLoading] = useState(false)
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)
    const [paymentMessage, setPaymentMessage] = useState('');
    const { accountreference, token, gatewayurl, clientcode, source } = useParams();

    async function StartPayment() {
        new AllpayGateway(gatewayurl, 'PaymentIFrame', onCompleteEvent).makePayment(token, clientcode, accountreference, 0, 'Portal Payment')
    }

    // data container for form data
    function AllpayGatewayData(token, customerReference, amount, clientCode, paymentReason) {
        this.token = token;
        this.customerReference = customerReference;
        this.amount = amount;
        this.clientCode = clientCode;
        this.paymentReason = paymentReason;
    }

    // Create an instance of this object to integrate with the Allpay Gateway.
    // The constructor takes static data that doesn't change per invocation
    function AllpayGateway(url, iframeId, event) {
        this.url = url;
        this.iframeId = iframeId;

        // Hook up the event listener
        window.addEventListener("message", event);

        // Makes a payment using the supplied information
        this.makePayment = function (token, clientCode, customerReference, amount, paymentReason) {

            var formData = new AllpayGatewayData(token, customerReference, amount, clientCode, paymentReason);
            this.makePaymentWithData(this.url, formData);
        }

        // Makes a payment using the pre-populated data
        this.makePaymentWithData = function (targetUrl, formData) {

            var form = document.createElement("form");
            form.method = "post";
            form.action = targetUrl;
            form.target = this.iframeId;

            for (var key in formData) {
                if (formData.hasOwnProperty(key)) {
                    var hiddenField = document.createElement("input");
                    hiddenField.type = "hidden";
                    hiddenField.name = key;
                    hiddenField.value = formData[key];

                    form.appendChild(hiddenField);
                }
            }

            document.body.appendChild(form);
            form.submit();
            document.body.removeChild(form);
        }
    }

    function onCompleteEvent(event) {
        if (event.data.resultCode > 0) {
            setPaymentMessage("Sorry something wen't wrong")
        } else if (event.data.resultCode < 0) {
            setPaymentMessage("The payment was cancelled")
        } else if (event.data.resultCode == 0){
            setPaymentMessage("Thanks, your payment was successful.")
        }
        isLoading(false);
    }

    function Logo() {
        if (settings.logo_url) {
            return (
                <a href='/' className="normal-case text-xl">
                    <img 
                    src={settings.logo_url}
                    alt="logo"
                    width="140"
                    className="mx-auto"
                    />
                </a>
            );
        } else {
            return (
                <a href='/' className="normal-case text-xl">{settings.company_name}</a>
            );
        }
    }

    function closeModalForReactNative(){
        window.ReactNativeWebView.postMessage("Close Modal")
    }

    useEffect(() => {
        StartPayment();
    }, []);
    
    return (
        <>
            {loading && (
                <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            )}
            <div className={"min-h-screen " + (themeCSS.divCSS)}>
        
            {/* <Nav /> */}
            {source && source == 'web' && (
                <div className={"navbar bg-base-100 " + (themeCSS.divCSS)}>
                    <div className="navbar-start">
                            <div id="nav-demo" className="dropdown">
                                <ul tabIndex="0" className={"menu text-black menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52 " + (themeCSS.divCSS)}>
                                    <li className={(themeCSS.divCSS)}><a href="/">Home</a></li>
                                </ul>
                                <label tabIndex="0" className="btn btn-ghost btn-circle">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h7" /></svg>
                                </label>
                            </div>
                
                    </div>
                    <div id="logo-demo" className={"navbar-center pb-2 text-black" + (themeCSS.divCSS)}>
                        <Logo/>
                    </div>
                    <div className="navbar-end">
                    </div>
                </div>
            )}
            {source && source == 'web' && (
                <div className="hidden lg:block">
                    <HeaderBackground />
                </div>
            )}

            {paymentMessage && (
                <>
                {paymentMessage == 'Thanks, your payment was successful.' && 
                    <div className="flex flex-col items-center">
                        <div className="mt-4 text-xl text-green-600 font-bold"> {paymentMessage}</div>
                        <Lottie animationData={SuccessLottie} loop={false} style={{width: 150, height: 150}} />
                        {source && source == 'web' && (
                            <a href="/" className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                        )}
                        {source && source == 'app' && (
                            <button onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</button>
                        )}
                        <div className={"p-2 lg:p-6 w-2/5 text-center text-md text-black" + (themeCSS.divCSS)}>
                            <strong>Please note: </strong>It can take up to one week from the date you make your payment for it to show on your account.
                        </div>
                    </div> 
                }
                {paymentMessage == 'The payment was cancelled' && 
                    <div className="flex flex-col items-center">
                        <div className="my-4 text-xl text-red-600 font-bold"> {paymentMessage}</div>
                        <Lottie animationData={FailureLottie} loop={false} style={{width: 120, height: 120}} />
                        {source && source == 'web' && (
                            <a href="/" className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</a>
                        )}
                        {source && source == 'app' && (
                            <button onClick={() => (closeModalForReactNative())} className="btn btn-natural text-white rounded-md font-semibold px-4 py-3 mt-4 w-40 capitalize">Finish</button>
                        )}
                    </div>
                }
                </>
                )}
                {!paymentMessage && (
                    <div className="">
                        <div className={"flex flex-col items-center " + (themeCSS.divCSS)}>
                            <iframe className="h-[900px] w-full" name="PaymentIFrame" id="PaymentIFrame"  title="Payment IFrame">
                            </iframe>  
                        </div>
                    </div>
                )}
            </div>
        </>
        
    );
}