import {useEffect, useState, useContext} from 'react';
import getAPI from '../functions/API/getAPI.js'
import postAPI from '../functions/API/postAPI.js'
import { toast } from 'react-toastify';
import GlobalContext from "../context/GlobalContext.js";
import Loader from '../components/loader.js'

export default function NewContactMethodForm({setVisible, refresh, refreshValue}) {
    const {settings} = useContext(GlobalContext)
    const [contactMethods, setContactMethods] = useState();
    const [contactMethodCategories, setContactMethodCategories] = useState();
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    async function GetAPIData() {
        if (settings.api_enabled == 1) {
            let contactmethodsresponse = await getAPI('getcontactmethods');
            let contactcategoriesresponse = await getAPI('getcontactmethodcategories');
            setContactMethods(contactmethodsresponse.data.contactmethods);
            setContactMethodCategories(contactcategoriesresponse.data.contactcategories);
        }
        setIsLoaded(true);
    }

    const [newContactMethod, setNewContactMethod] = useState({
        name: "",
        methodid: "",
        categoryid: "",
    })

    async function createNewContactMethod(){
        const body = {name: newContactMethod.name, methodid: newContactMethod.methodid, categoryid: newContactMethod.categoryid}
        const NewCategoryResponse = await postAPI('createcontactmethodmapping', body);
        if(NewCategoryResponse.response.ok){
            refresh(refreshValue+1);
            toast.success('Contact method created successfully.');
            setVisible(false);
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }        
    }

    function handleChange (event) {
        let statechange = event.target.name;
        let value = event.target.value;
        setNewContactMethod({...newContactMethod, [statechange]: value})
    }

    useEffect(() => {
        GetAPIData();
    }, []);

    return (
        <>
        {loading ? ( 
            <Loader style='slideout' setLoadingState={setLoading} isLoaded={isLoaded} /> 
        ) : (
        <form>
            <div className="space-y-12">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Create new contact method</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">Contact methods allow customers to set their contact methods when updating their contact information</p>
                </div>

                <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-3 md:col-span-1">
                    <div className="sm:col-span-3">
                    <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">
                        Contact Method Name
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="name"
                        id="name"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-3">
                    <label htmlFor="methodid" className="block text-sm font-medium leading-6 text-gray-900">
                        Method Type
                    </label>
                    <div className="mt-2">
                        <select
                        required
                        onChange={handleChange}
                        name="methodid"
                        id="methodid"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        >
                        <option value="">Please select a Method Type</option>
                        {contactMethods && contactMethods.map((contactmethod, index) => (
                            <option key={index} value={contactmethod.id}>{contactmethod.description}</option>
                        ))}
                        </select>
                    </div>
                </div>
                <div className="sm:col-span-3">
                    <label htmlFor="categoryid" className="block text-sm font-medium leading-6 text-gray-900">
                        Category Type
                    </label>
                    <div className="mt-2">
                        <select
                        required
                        onChange={handleChange}
                        name="categoryid"
                        id="categoryid"
                        className="pl-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        >
                        <option value="">Please select a Category Type</option>
                        {contactMethodCategories && contactMethodCategories.map((contactcategory, index) => (
                            <option key={index} value={contactcategory.id}>{contactcategory.description}</option>
                        ))}
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6">
            
            <button
            type="button"
            onClick={()=>{createNewContactMethod()}}
            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
            >
            Create
            </button>
            <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
            </button>
        </div>
        </form>
    )}
    </>
    );
}
