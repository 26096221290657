import {useContext} from 'react';
import GlobalContext from "../context/GlobalContext.js";
export default function HeaderBackground() {
    const {theme, updateTheme, themeCSS, settings} = useContext(GlobalContext)

    const headerstyle = {
        'backgroundColor': settings.primary_hex_colour,
    };
    
    return (
        theme === "highContrast" ?
            <div className={"h-16 lg:h-20 w-screen " + themeCSS.divCSS}>   
            </div>
        :
            <div className={"h-16 lg:h-20 w-screen"} style={headerstyle}>   
            </div>
        
    );
}