import Lottie from "lottie-react";
import LoaderIcon from "../lottie/loader.json"

import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { Tab } from "@headlessui/react";
import { useEffect } from "react";

export default function Loader({style, setLoadingState, isLoaded}) {

  useEffect(() => { 
    if(isLoaded){
      setTimeout(() => {
        setLoadingState(false);
      }, 500)
    }
  }, [isLoaded]);

  const TableLoader = () => {
    return (
      <div>
        <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900"><Skeleton rows={1}/></h1>
            <p className="mt-2 text-sm text-gray-700 w-40">
                <Skeleton rows={1}/>
            </p>
            </div>
        </div>
        <div className="mt-8 flow-root">
          <table className="min-w-full">
            <thead>
                <tr>
                  <th scope="col" className="py-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    <Skeleton rows={1}/>
                  </th>
                  <th scope="col" className="py-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    <Skeleton rows={1}/>
                  </th>
                  <th scope="col" className="py-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    <Skeleton rows={1}/>
                  </th>
                  <th scope="col" className="py-4 pr-3 text-left text-sm font-semibold text-gray-900">
                    <Skeleton rows={1}/>
                  </th>
                </tr>
            </thead>
            <tbody className="">
                <tr key='1'>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                </tr>
                <tr key='2'>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                </tr>
                <tr key='3'>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                </tr>
                <tr key='4'>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                    <td className="whitespace-nowrap py-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0"><Skeleton rows={1}/></td>
                </tr>
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const FormLoader = () => {
    return (
      <>
      <div className="sm:flex-auto mb-8 pb-8">
          <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3 w-3/12"><Skeleton rows={1}/></h1>
          <p className="mt-2 text-sm text-gray-700">
            <Skeleton rows={1}/>
          </p>
      </div>
                  
      <div className="dark:bg-white h-full">
          <div className="space-y-6">
              <div className="pb-8">
                  <h2 className="text-base font-semibold leading-7 text-gray-900 w-2/12"><Skeleton rows={1}/></h2>

                  <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="pb-8">
                <h2 className="text-base font-semibold leading-7 text-gray-900 w-2/12"><Skeleton rows={1}/></h2>

                  <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="pb-8">
                  <h2 className="text-base font-semibold leading-7 text-gray-900 w-2/12"><Skeleton rows={1}/></h2>

                  <div className="grid grid-cols-1 gap-x-6 sm:grid-cols-6">
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>

                      <div className="sm:col-span-3">
                          <div className="mt-2">
                            <Skeleton rows={1}/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </>
    )
  }

  const SlideoutLoader = () => {
    return (
      <>
      <div className="sm:flex-auto mb-8 pb-8">
          <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3 w-5/12"><Skeleton rows={1}/></h1>
          <div className="mt-2 text-sm text-gray-700">
            <Skeleton rows={1}/>
          </div>
          <div className="pt-4 mt-2 text-sm text-gray-700">
            <Skeleton rows={1}/>
          </div>
          <div className="mt-2 text-sm text-gray-700">
            <Skeleton rows={1}/>
          </div>
          <div className="mt-2 text-sm text-gray-700">
            <Skeleton rows={1}/>
          </div>
      </div>
      </>
    )
  }

  const ReportsLoader = () => {
    return(
      <div className="dark:bg-white">
                <div className='grid grid-cols-1'>
                    <h3 className="text-2xl text-left leading-6 text-gray-900 col-span-3"><Skeleton rows={1}/></h3>
                    <div className='grid grid-cols-1 md:grid-cols-4 gap-2 col-span-12'>
                        <div>
                            <p><br/></p>
                            <span className="isolate inline-flex rounded-md border-2-sm h-10">
                              <Skeleton rows={1}/>
                              <Skeleton rows={1}/>
                            </span>
                        </div>
                        
                        <div>
                            <Skeleton rows={1}/>
                        </div>
                        <div>
                            <Skeleton rows={1}/>
                        </div>
                        <div>
                            <Skeleton rows={1}/>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-cols-3">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <dl>
                      <div key={index} className="relative overflow-hidden rounded-lg bg-gray-50 ring-1 ring-gray-900/5 px-4 pb-5 sm:px-6 sm:pt-6">
                      <Skeleton rows={1}/>
                          <dt>
                              <p className="ml-16 truncate text-sm font-medium text-left text-gray-700"><Skeleton rows={1}/></p>
                              <p className="ml-16 truncate text-sm font-medium text-left text-gray-700"><Skeleton rows={1}/></p>
                          </dt>
                      </div>
                    </dl>
                  ))}
                </div>
            </div>
    )
  }

  const LogoLoader = () => {
    return (
      <div className="bg-gray-900 opacity-50 right-0 left-0 z-50 justify-center items-center focus:border-transparent fixed h-full">
        <div className="rounded-2xl overflow-auto p-10 mt-36">
            <div className="flex flex-col items-center space-y-2">
            <Lottie className="w-[400px]" animationData={LoaderIcon} loop={true} />
            </div>
        </div>
      </div>
    )
  }

  return(
    <>
      {style && style == 'table' && (  
        <TableLoader/>
      )}
      {style && style == 'form' && (  
        <FormLoader/>
      )}
      {style && style == 'slideout' && (  
        <SlideoutLoader/>
      )}
      {style && style == 'report' && (  
        <ReportsLoader/>
      )}
      {!style && (  
        <LogoLoader/>
      )} 
    </>
  )
}