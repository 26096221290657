import {useState, useContext, useEffect} from 'react';
import GlobalContext from "../context/GlobalContext.js";
import moment from 'moment';
import Loader from '../components/loader.js'

export default function AllPayMobileAppDirectDebit() {
    const {theme} = useContext(GlobalContext)
    const [url, setUrl] = useState("https://api."+window.location.hostname+"/api/allpay/ddredirect");
    useEffect(async () => {
        if (window.location.hostname == 'localhost') {
            setUrl('https://customer-portal-api.test/api/allpay/ddredirect')
        }
    }, []);
    
    return (
        <>
        <body>
            <Loader width="100%" height="100%" backgroundColor={(theme === "dark"? "#1e293a":"#eee")}/>
            <div>
                <form name="directdebit" id="directdebit" method="POST" action={url}>
                    <input type="hidden" name="CLIENT_CODE" id="CLIENT_CODE" value="" />
                    <input type="hidden" name="CLIENT_REF" id="CLIENT_REF" value="" />
                    <input type="hidden" name="SURNAME" id="SURNAME" value="" />
                    <input type="hidden" name="ADDRESS_LINE_1" id="ADDRESS_LINE_1" value="" />
                    <input type="hidden" name="ADDRESS_LINE_3" id="ADDRESS_LINE_3" value="" />
                    <input type="hidden" name="POST_CODE" id="POST_CODE" value="" />
                    <input type="hidden" name="VERSION" id="VERSION" value="" />
                    <input type="hidden" name="RETURNURL" id="RETURNURL" value={url} />
                    <input type="hidden" name="TIMESTAMP" id="TIMESTAMP" value={moment().format("yyyyMMDDhhmmss")}/>
                    <input type="hidden" name="APPLICATION_TOKEN" id="APPLICATION_TOKEN" value="" /> 
                </form>
            </div>
        </body>  
        </>
        
    );
}