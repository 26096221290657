import apiCall from "./apiCall";
export default async function getToken(email, password, devicename, twofacode) {
    if (twofacode){
        const URL = process.env.REACT_APP_API_URL+'/api/token';
        const BODY = {email: email, password: password, device_name: devicename, mfacode: twofacode}
        const data = await apiCall('POST', URL, BODY);
        return data;
    } else {
        const URL = process.env.REACT_APP_API_URL+'/api/token';
        const BODY = {email: email, password: password, device_name: devicename}
        const data = await apiCall('POST', URL, BODY);
        return data;
    }
  
};