import {useContext, useEffect, useState} from 'react';
import GlobalContext from "../context/GlobalContext.js";
import getAPI from '../functions/API/getAPI.js';
import moment from 'moment';

export default function HomePageAppointmentsCard(){
    const {themeCSS} = useContext(GlobalContext)
    const [appointments, setAppointments] = useState([]);
    const [numberOfAppointments, setNumberOfAppointments] = useState();
    const [nextAppointment, setNextAppointment] = useState();

    async function GetAppointments() {
        const AppoinmentReponse = await getAPI('repairsappointments')
        if (AppoinmentReponse.response.ok) {
            let filteredFutureAppointments = AppoinmentReponse.data.filter(appointment => {
                let CurrentDate = moment().format('YYYY-MM-DD');
                return moment(appointment.appointmentdate).format("YYYY-MM-DD") >= CurrentDate && !appointment.is_complete
            }).sort((a, b) => a.appointmentdate - b.appointmentdate).reverse();
    
            if (filteredFutureAppointments.length > 0) {
                setNextAppointment(filteredFutureAppointments[0])
                setAppointments(filteredFutureAppointments);
                setNumberOfAppointments(filteredFutureAppointments.length)
            }
        }
    }

    useEffect(() => {
        GetAppointments()
    }, [])
    

    return (   
        appointments && (
            appointments.length > 0 ? (
                <div className="h-full rounded-md text-center border flex flex-col">
                    <div className="flex-grow p-6">
                        <h2 className="text-xl font-bold mb-2">Appointments</h2> 
                        <p className="">
                            Your next appointment is:<br />
                            <strong>🗓️ {moment(nextAppointment.appointmentdate).format('dddd Do MMMM')}</strong>
                            <br /> ⏰ {nextAppointment.starttime} to {nextAppointment.endtime}
                        </p> 
                    </div>
                    <div className="p-4 mt-auto">
                        <button 
                            className={"btn btn-natural capitalize " + (themeCSS.buttonCSS)}
                            onClick={() => { window.location.href = "/appointments" }}>
                            View Appointments
                        </button>
                    </div>
                </div>
            ) : (
                <div className="h-full rounded-md text-center border flex flex-col">
                    <div className="flex-grow p-4">
                        <h2 className="text-xl font-bold mb-2">Appointments</h2> 
                        <p className="">
                            You don't have any upcoming appointments.<br />
                        </p> 
                    </div>
                    <div className="p-4 mt-auto">
                        <button 
                            className={"btn btn-natural capitalize " + (themeCSS.buttonCSS)}
                            onClick={() => { window.location.href = "/appointments" }}>
                            View Appointments
                        </button>
                    </div>
                </div>
            )
        )
    );
}
