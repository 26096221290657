import {useState, useEffect, useContext} from 'react';
import AdminSidebar from '../components/admin/AdminSidebar.js';
import postAPI from '../functions/API/postAPI.js'
import GlobalContext from '../context/GlobalContext.js';
import { ToastContainer, toast } from 'react-toastify';
import { FormBody, TextInput, CheckBox } from '../components/FormComponents.js';
import Loader from '../components/loader.js';

export default function AdvancedSettings() {
    const [updatedSettings, setUpdatedSettings] = useState();
    const [savebutton, setSaveButton] = useState(false);
    const {settings} = useContext(GlobalContext)
    const [runningSettings, setRunningSettings] = useState();
    const [loading, setLoading] = useState(true);
    const [isLoaded, setIsLoaded] = useState(false);

    function handleChange(event) {
        setSaveButton(true);
        let statename = event.target.name;
        let value = event.target.value;
        setUpdatedSettings({...updatedSettings, [statename]: value});
        setRunningSettings({...runningSettings, [statename]: value})
    }


    function checkChanged(event) {
        if(event.target.id === 'test_system'){
            setRunningSettings({...runningSettings, 'test_system': event.target.checked});
            setUpdatedSettings({...updatedSettings, 'test_system': event.target.checked});
        }
        setSaveButton(true);
    }

    async function UpdateSettngs() {  
        if(!runningSettings.test_system_email_whitelist && runningSettings.test_system){
            toast.error('To enable test mode you must supply an approved domain.');
            return;
        }
        if (updatedSettings) {
            const UpdateSettingsResponse = await postAPI('settings', updatedSettings);
            if(UpdateSettingsResponse.response.ok){
                toast.success('Settings saved successfully.');
                setSaveButton(false);
            }else{
                toast.error('Sorry an error occurred. Try again later.');
            }
        }  
    }

    useEffect(() => {
        if (settings) {
            setIsLoaded(true);
        }
        setRunningSettings(settings);
    }, [settings]);
    
    return (
        <AdminSidebar>
            {loading ? (    
                <Loader style='form' setLoadingState={setLoading} isLoaded={isLoaded} /> 
            ) : (
                runningSettings && (
                    <>
                    <form className="dark:bg-white h-full">
                        <div className="sm:flex-auto mb-8 border-b border-gray-900/10 pb-8">
                            <h1 className="text-2xl text-left leading-6 text-gray-900 col-span-3">Advanced Settings</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                Configure advanced settings for your system. These settings are used to control the behaviour of the system and should be used with caution.
                            </p>
                        </div>
                        <div className="grid grid-cols-2 sm:flex-auto mb-4">
                            <p className="mt-2 mb-2 text-sm text-gray-700">
                                Checking the check box below will enable test system mode. This sets a red banner on all pages for customers and administrators. It restricts email communication to domains in the approved domains list above.
                            </p>
                        </div>
                        <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                                <input
                                id="test_system"
                                name="test_system"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-600"
                                onChange={checkChanged}
                                checked={runningSettings.test_system}
                                />
                            </div>
                            <div className="text-sm leading-6">
                                <label htmlFor="transactions" className="font-medium text-gray-900">
                                Test System Mode
                                </label>
                                <p className="text-gray-500">When enabled, the system will show a test system banner and the approved domains will take effect.</p>
                            </div>
                        </div>
                        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <TextInput id="test_system_email_whitelist" name="test_system_email_whitelist" label="Approved domains" onChangeFunc={handleChange} value={runningSettings.test_system_email_whitelist} placeholder="Enter your approved domains" />
                            </div>
                        </div>
                        <div className="mt-8 flex items-center justify-left gap-x-6">
                            <button
                                type="button"
                                onClick={() => {UpdateSettngs()}}
                                className="mb-4 rounded-md bg-blue-700 px-3 py-2 font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                    </>
                )
            )}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </AdminSidebar>
    );
}

