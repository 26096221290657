'use client'
import { Fragment, useEffect, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import postAPI from '../../functions/API/postAPI.js'
import GlobalContext from "../../context/GlobalContext.js";
import {
  HomeIcon,
  UsersIcon,
  XMarkIcon,
  ChartBarSquareIcon,
  CogIcon,
  EnvelopeIcon,
  WrenchScrewdriverIcon,
  PowerIcon,
  Bars3Icon
} from '@heroicons/react/24/outline'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { Disclosure } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
export default function AdminSidebar({children}) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const {settings} = useContext(GlobalContext)

  const navigation = [
    { id: 1, name: 'Dashboard', href: '/admin', icon: HomeIcon, current: window.location.pathname == '/admin' ? true : false, breadcrumb: [{name: 'Dashboard'}] },
    { id: 2, name: 'Reports', href: '/reports', icon: ChartBarSquareIcon, current: window.location.pathname == '/reports' ? true : false, breadcrumb: [{name: 'Reports'}] },
    { id: 3, name: 'Message Center', href: '/message-center', icon: EnvelopeIcon, current: window.location.pathname == '/message-center' ? true : false, breadcrumb: [{name: 'Message Center'}]  },
    { id: 4, name: 'People', href: '#', icon: UsersIcon, current: window.location.pathname == '/customers' || window.location.pathname == '/adminusers', breadcrumb: [{name: 'People'}, {name: window.location.pathname == '/customers' && "Customers" || window.location.pathname == '/adminusers' && "Administrators"}],
      children: [
        { name: 'Customers', href: '/customers', current: window.location.pathname == '/customers' },
        { name: 'Administrators', href: '/adminusers', current: window.location.pathname == '/adminusers' }
      ], 
    },
    { id: 5, name: 'Repairs Configuration', href: '#', icon: WrenchScrewdriverIcon, current: window.location.pathname == '/categories' || window.location.pathname == '/repairdefinitions' || window.location.pathname == '/repair-groups', breadcrumb: [{name: 'Repairs Configuration'}, {name: window.location.pathname == '/categories' && "Categories" || window.location.pathname == '/repairdefinitions' && "Definitions" || window.location.pathname == '/repair-groups' && "Groups"}],
      children: [
        { name: 'Categories', href: '/categories', current: window.location.pathname == '/categories' },
        { name: 'Definitions', href: '/repairdefinitions', current: window.location.pathname == '/repairdefinitions' },
        { name: 'Groups', href: '/repair-groups', current: window.location.pathname == '/repair-groups' }
      ], 
    },
    { id: 6, name: 'Configuration', href: '#', icon: CogIcon, 
      current: 
        window.location.pathname == '/integrations' ||
        window.location.pathname == '/contactmethods' ||
        window.location.pathname == '/requesttypes' ||
        window.location.pathname == '/paymentsettings' ||
        window.location.pathname == '/settings' ||
        window.location.pathname == '/advancedsettings',
      breadcrumb: 
        [
          {name: 'Configuration'}, 
          {name: 
            window.location.pathname == '/integrations' && "Integrations" ||
            window.location.pathname == '/contactmethods' && "Contact Methods" ||
            window.location.pathname == '/requesttypes' && "Request Types" ||
            window.location.pathname == '/paymentsettings' && "Payment Settings" ||
            window.location.pathname == '/settings' && "Settings" ||
            window.location.pathname == '/advancedsettings' && "Advance Settings"}],
      children: [
        { name: 'Integrations', href: '/integrations', current: window.location.pathname == '/integrations' },
        { name: 'Contact Methods', href: '/contactmethods', current: window.location.pathname == '/contactmethods' },
        { name: 'Request Types', href: '/requesttypes', current: window.location.pathname == '/requesttypes' },
        { name: 'Payment Settings', href: '/paymentsettings', current: window.location.pathname == '/paymentsettings' },
        { name: 'Settings', href: '/settings', current: window.location.pathname == '/settings' },
        { name: 'Advanced', href: '/advancedsettings', current: window.location.pathname == '/advancedsettings' },
      ], 
    },
  ]
  
  const shortcuts = [
    { id: 1, name: 'Repairs Groups', href: '/repair-groups', initial: 'R', navId: 5, subNavId: 53 },
    { id: 2, name: 'Customers', href: '/customers', initial: 'C', navId: 3, subNavId: 31 },
    { id: 3, name: 'Settings', href: '/settings', initial: 'S', navId: 6, subNavId: 65 },
  ]

  async function logout(){
      const body = {"":""}
      let destroy = await postAPI('token/destroy', body);
      if (destroy.response.ok) {
          localStorage.clear();
          window.location.href = '/'
      }
  }

  function Logo() {
      if (settings.logo_url) {
          return (
              <img 
              src={settings.logo_url}
              alt="logo"
              width="180"
              className="mx-"
              />
          );
      } else {
          return (
              <h2 className="text-3xl font-semibold text-gray-800 dark:text-white">{settings.company_name}</h2>
          );
      }
  }
  
  const BreadCrumbs = () => {
    return(
      <nav className="flex" aria-label="Breadcrumb">
      <ol role="list" className="flex items-center space-x-4">
        <li>
          <div>
            <a href="/admin" className="text-gray-400 hover:text-gray-500">
              <HomeIcon className="h-5 w-5 flex-shrink-0" aria-hidden="true" />
              <span className="sr-only">Home</span>
            </a>
          </div>
        </li>
        {navigation.map((item) => (
            item.current && item.breadcrumb &&
              item.breadcrumb.map((crumb) => (
                <li key={crumb.name}>
                  <div className="flex items-center">
                    <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    <div
                      className="ml-4 text-sm font-medium text-gray-500"
                      aria-current={crumb.name ? 'page' : undefined}
                    >
                      {crumb.name}
                    </div>
                  </div>
                </li>
                )
              )
            )
          )}
      </ol>
    </nav>
    );
  }

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                    <div className="flex h-16 shrink-0 items-center grid mt-4">
                    <Logo/>
                    {settings && (
                      <>
                        <p className="text-xs text-gray-400 pt-2" >V{settings.version}
                        {!!settings.test_system && (
                          <span className="inline-flex items-center ml-2 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                            Test System
                          </span>
                        )}
                        </p>

                      </>
                    )}
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                            {!item.children ? (
                              <a
                                href={item.href}
                                className={classNames(
                                  item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                  'group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0 " aria-hidden="true" />
                                {item.name}
                              </a>
                            ) : (
                              item.current ? (
                                <Disclosure as="div" defaultOpen>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button
                                        className={classNames(
                                          item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                          'w-full group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                                        )}
                                      >
                                        <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                        {item.name}
                                        <ChevronRightIcon
                                          className={classNames(
                                            open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                            'ml-auto h-5 w-5 shrink-0'
                                          )}
                                          aria-hidden="true"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel as="ul" className="mt-1 px-2">
                                        {item.children.map((subItem) => (
                                          <li key={subItem.name}>
                                            {/* 44px */}
                                            <Disclosure.Button
                                              as="a"
                                              href={subItem.href}
                                              className={classNames(
                                                subItem.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                                'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                              )}
                                            >
                                              {subItem.name}
                                            </Disclosure.Button>
                                          </li>
                                        ))}
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              ):(
                              <Disclosure as="div">
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button
                                      className={classNames(
                                        item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                        'w-full group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                                      )}
                                    >
                                      <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                      {item.name}
                                      <ChevronRightIcon
                                        className={classNames(
                                          open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                          'ml-auto h-5 w-5 shrink-0'
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Disclosure.Button>
                                    <Disclosure.Panel as="ul" className="mt-1 px-2">
                                      {item.children.map((subItem) => (
                                        <li key={subItem.name}>
                                          {/* 44px */}
                                          <Disclosure.Button
                                            as="a"
                                            href={subItem.href}
                                            className={classNames(
                                              subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                              'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                            )}
                                          >
                                            {subItem.name}
                                          </Disclosure.Button>
                                        </li>
                                      ))}
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                              )
                            )}
                          </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">Quick Access</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {shortcuts.map((shortcut) => (
                              <li key={shortcut.name}>
                                <a
                                  href={shortcut.href}
                                  className={classNames(
                                    shortcut.current
                                      ? 'bg-gray-50 text-blue-600'
                                      : 'text-gray-700 hover:bg-gray-50',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      shortcut.current
                                        ? 'text-blue-600 border-blue-600'
                                        : 'text-gray-400 border-gray-200',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {shortcut.initial}
                                  </span>
                                  <span className="truncate">{shortcut.name}</span>
                                </a>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className=" mt-auto">
                          <div
                            onClick={() => {logout()}}
                            className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                          >
                            <PowerIcon className='w-4 text-gray-600'/>

                            <span aria-hidden="true">Log out</span>
                          </div>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-70 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
            <div className="flex flex-col mt-4 shrink-0 items-start mt-6">
            <Logo/>
            {settings && (
              <>
                <p className="text-xs text-gray-400 pt-2" >V{settings.version}
                {!!settings.test_system && (
                  <span className="inline-flex items-center ml-2 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                    Test System
                  </span>
                )}
                </p>

              </>
            )}
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                      {!item.children ? (
                        <a
                          href={item.href}
                          className={classNames(
                            item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                          )}
                        >
                          <item.icon className="h-6 w-6 shrink-0 " aria-hidden="true" />
                          {item.name}
                        </a>
                      ) : (
                        item.current ? (
                          <Disclosure as="div" defaultOpen>
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                    'w-full group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                                  )}
                                >
                                  <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                  {item.name}
                                  <ChevronRightIcon
                                    className={classNames(
                                      open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                      'ml-auto h-5 w-5 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                </Disclosure.Button>
                                <Disclosure.Panel as="ul" className="mt-1 px-2">
                                  {item.children.map((subItem) => (
                                    <li key={subItem.name}>
                                      {/* 44px */}
                                      <Disclosure.Button
                                        as="a"
                                        href={subItem.href}
                                        className={classNames(
                                          subItem.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                          'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                        )}
                                      >
                                        {subItem.name}
                                      </Disclosure.Button>
                                    </li>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ):(
                        <Disclosure as="div">
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current ? 'bg-gray-50 text-blue-600' : 'hover:bg-gray-50',
                                  'w-full group flex gap-x-3 rounded-md p-2 text-sm items-center leading-6 font-semibold'
                                )}
                              >
                                <item.icon className="h-6 w-6 shrink-0" aria-hidden="true" />
                                {item.name}
                                <ChevronRightIcon
                                  className={classNames(
                                    open ? 'rotate-90 text-gray-500' : 'text-gray-400',
                                    'ml-auto h-5 w-5 shrink-0'
                                  )}
                                  aria-hidden="true"
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel as="ul" className="mt-1 px-2">
                                {item.children.map((subItem) => (
                                  <li key={subItem.name}>
                                    {/* 44px */}
                                    <Disclosure.Button
                                      as="a"
                                      href={subItem.href}
                                      className={classNames(
                                        subItem.current ? 'bg-gray-50' : 'hover:bg-gray-50',
                                        'block rounded-md py-2 pr-2 pl-9 text-sm leading-6 text-gray-700'
                                      )}
                                    >
                                      {subItem.name}
                                    </Disclosure.Button>
                                  </li>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                        )

                        
                    
                      )}
                    </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Quick Access</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {shortcuts.map((shortcut) => (
                      <li key={shortcut.name}>
                        <a
                          href={shortcut.href}
                          className={classNames(
                            shortcut.current
                              ? 'bg-gray-50 text-blue-600'
                              : 'text-gray-700 hover:bg-gray-50',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                          )}
                        >
                          <span
                            className={classNames(
                              shortcut.current
                                ? 'text-blue-600 border-blue-600'
                                : 'text-gray-400 border-gray-200',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                            )}
                          >
                            {shortcut.initial}
                          </span>
                          <span className="truncate">{shortcut.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className=" mt-auto">
                  <div
                    onClick={() => {logout()}}
                    className="flex items-center gap-x-4 py-3 text-sm font-semibold leading-6 text-gray-900"
                  >
                    <PowerIcon className='w-4 text-gray-600'/>

                    <span aria-hidden="true">Log out</span>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
          <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">TenantWise</div>
          
        </div>

        <main className="lg:pl-[260px]">
            <div className="mx-4 mt-4">
              {/* Main area */}
              <div className="mb-2">
                <BreadCrumbs/>
              </div>
              <div className="pt-4">
                {children}
              </div>
            </div>
        </main>
      </div>
    </>
  )
}