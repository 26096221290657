export function FormBody({title, subheading, cancelFunction, cancelText, saveFunction, saveText, buttonPosition, children}){
    return(
        <form>
            <div className="space-y-12 sm:space-y-16 mt-6 xl:mt-6">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                        {title}
                    </h2>
                    <p className="mt-1 max-w-4xl text-sm leading-6 text-gray-600">
                        {subheading}
                    </p>
                    <div>
                        {children}
                    </div>
                </div>
            </div>


        
            <div className={"mt-6 flex items-center gap-x-6 " + (buttonPosition == 'left' ? "justify-start" : "justify-end")} >
                {cancelText && (
                    <button onClick={cancelFunction} type="button" className="text-sm font-semibold leading-6 text-gray-900">
                        {cancelText}
                    </button>
                )}
                <button type="button" onClick={saveFunction} className="inline-flex justify-center rounded-md bg-slate-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-slate-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600">
                    {saveText}
                </button>
            </div>
        </form>      
    );
};

export function SubHeading({subheading, subtext}){
    return(
        <>
            <h2 className="text-base font-semibold leading-7 text-gray-900">{subheading}</h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">{subtext}</p>
        </>
    )
}

export function TextInput({id, label, onChangeFunc, value, placeholder, name}){
    return(
        <>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="mt-2">
            <input
                type="text"
                name={name}
                id={id}
                autoComplete="given-name"
                onChange={onChangeFunc}
                value={value}
                placeholder={placeholder}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
            />
            </div>
        </>
    )
}

export function TextArea({id, name, label, value, onChangeFunc, rows, defaultValue, lowerPrompt}){
    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="mt-2">
                <textarea
                id={id}
                name={name}
                rows={rows}
                value={value}
                onChange={onChangeFunc}
                className="block w-full rounded-md border-0 px-2 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                defaultValue={defaultValue}
                />
            </div>
            <p className="mt-1 text-sm leading-6 text-gray-600">{lowerPrompt}</p>
        </>
    )
}

export function DropDown({id, label, onChangeFunc, name, options}) {
    return (
        <>
            <label htmlFor={id} className="block text-sm font-medium leading-6 text-gray-900">
                {label}
            </label>
            <div className="mt-2">
                <select
                    id={id}
                    name={name}
                    onChange={onChangeFunc}
                    // autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:max-w-xs sm:text-sm sm:leading-6"
                >
                    {options.map((option) => (
                        !option.selected ? (
                            <option selected>{option.value}</option>
                        ):(
                            <option>{option.value}</option>
                        )
                    ))}
                </select>
            </div>
        </>
    )
}

export function CheckBox({id, name, label, description, onChangeFunc, checked}){
    return (
        <div className="mt-6 space-y-6">
            <div className="relative flex gap-x-3">
                <div className="flex h-6 items-center">
                <input
                    id={id}
                    name={name}
                    type="checkbox"
                    onChange={onChangeFunc}
                    checked={checked}
                    className="h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-slate-600"
                />
                </div>
                <div className="text-sm leading-6">
                <label htmlFor={id} className="font-medium text-gray-900">
                    {label}
                </label>
                <p className="text-gray-500">{description}</p>
                </div>
            </div>
        </div>
    )
}