
import {useState} from 'react';
import postAPI from '../functions/API/postAPI.js'
import { toast } from 'react-toastify';

export default function NewAdminForm({setVisible, refresh, refreshValue}) {

    const [newUser, setNewUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
    })

    async function createNewUser(){
        const body = {firstname: newUser.firstname, lastname: newUser.lastname, email: newUser.email}
        const NewUserResponse = await postAPI('admin/createadmin', body);
        if(NewUserResponse.response.ok){
            refresh(refreshValue+1);
            toast.success('Admin invite sent via email.');
            setVisible(false);
        }else{
            toast.error('Sorry an error occurred. Try again later.');
        }        
    }

    function handleChange (event) {
        let statechange = event.target.name;
        let value = event.target.value;
        setNewUser({...newUser, [statechange]: value})
    }

    return (
        <form>
            <div className="space-y-4">
                <div>
                    <h2 className="text-base font-semibold leading-7 text-gray-900">Create new admin</h2>
                    <p className="mt-1 text-sm leading-6 text-gray-600">An administrator has full access to your portal</p>
                </div>

                <div className="grid grid-cols-1 gap-x-6 gap-y-6">
                    <div className="sm:col-span-1">
                    <label htmlFor="firstname" className="block text-sm font-medium leading-6 text-gray-900">
                        First name
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="firstname"
                        id="firstname"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-1">
                    <label htmlFor="lastname" className="block text-sm font-medium leading-6 text-gray-900">
                        Last name
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        type="text"
                        onChange={handleChange}
                        name="lastname"
                        id="lastname"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                    </div>

                    <div className="sm:col-span-1">
                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                    </label>
                    <div className="mt-2">
                        <input
                        required
                        id="email"
                        onChange={handleChange}
                        name="email"
                        type="email"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-700 sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div className="mt-6 flex items-center justify-start gap-x-6">
            
            <button
            type="button"
            onClick={()=>{createNewUser()}}
            className="rounded-md bg-blue-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-700"
            >
            Create
            </button>
            <button type="button" onClick={()=>{setVisible(false)}} className="text-sm font-semibold leading-6 text-gray-900">
            Cancel
            </button>
        </div>
        </form>
    )
}
